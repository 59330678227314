<section>
    <div class="columns height-full">
      <div class="column is-4 height-full p-0">
        <div class="form-part">
          <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
            <img src="./assets/images/logo-login.png" alt="logo-basf" width="200" height="250">
            <div class="field">
              <label class="label">Usuario</label>
              <div class="control">
                <input class="input" type="text" placeholder="Usuario" formControlName="email">
              </div>
            </div>
            <div class="field mt-4">
              <label class="label">Contraseña</label>
              <div class="control">
                <input class="input" type="password" placeholder="Contraseña" formControlName="password">
              </div>
            </div>
            <button class="button" type="submit" class="button is-primary is-fullwidth mt-5" [disabled]="!loginForm.valid">
              @if (!isLoading) {
                <span>Acceder</span>
              } @else {
                <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
              }
            </button>
          </form>
        </div>
      </div>
      <div class="column is-8 image-login height-full"></div>
    </div>
  </section>