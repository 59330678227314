import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowRotateLeft, faBan, faCircle, faFolderOpen } from '@fortawesome/pro-solid-svg-icons';
import { ContractActionsModalComponent } from '../contract-actions-modal/contract-actions-modal.component';

@Component({
  selector: 'app-clients-table',
  standalone: true,
  imports: [FontAwesomeModule, DatePipe, CommonModule, ContractActionsModalComponent],
  templateUrl: './clients-table.component.html',
  styleUrl: './clients-table.component.scss'
})
export class ClientsTableComponent {

  @Input() configTable: any;
  @Output() reopen = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() openItemEvent = new EventEmitter<any>();
  @ViewChild('modal') modal: ContractActionsModalComponent;

  showModal = false;
  isReopen = false;
  i: number;

  faCircle = faCircle;
  faBan = faBan;
  faFolderOpen = faFolderOpen;
  faArrowRotateLeft = faArrowRotateLeft;
  
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
   }

  goToDetail(id) {
    this.router.navigate(['/clients/detail/', id]);
  }

  onModalConfirm(event) {
    if(event[1] === true) {
      this.reopen.emit(event[0]);
    }else {
      this.close.emit(event[0]);
    
    }
    this.showModal = false; 
  }

  openModal(index, isReopen) {
    this.i = index;
    this.isReopen = isReopen;
    this.showModal = true;
  }

  onCloseModal() {
    this.showModal = false;
  }

  openItem(index) {
    this.openItemEvent.emit(index);
  }
}
