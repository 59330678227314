<div class="form-title-container">
    <h3>Datos generales</h3>
</div>
<div class="columns is-multiline " [formGroup]="identificationForm">

    <div class="column is-6 form-container">
        <span class="label">Código Cliente</span>
        <p-inputNumber [useGrouping]="false" class="search-input" formControlName="code"/>

    </div>
    <div class="column is-6 form-container">
        <span class="label">Grupo Cliente</span>
        <input class="search-input" type="text" pInputText formControlName="codeApplication"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Nombre / Razón social</span>
        <input class="search-input" type="text" pInputText formControlName="name"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Tipo de cliente</span>
        <p-dropdown [options]="clientConstants.CLIENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de cliente" formControlName="personType"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Tipo documento</span>
        <p-dropdown [options]="clientConstants.DOCUMENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de documento" formControlName="documentType"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Número de documento identidad</span>
        <input class="search-input" type="text" pInputText formControlName="documentNumber"/>
    </div>
    <!-- <div class="column is-6 form-container">
        <span class="label">Fecha de nacimiento / Constitución</span>
        <p-calendar [iconDisplay]="'input'"  dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="constitutionDate"/>
    </div> -->
    <div class="column is-6 form-container">
        <span class="label">Responsable Interno</span>
        <p-dropdown [options]="responsibleUsers" optionLabel="name" optionValue="id" placeholder="Seleccionar responsable" formControlName="responsible"/>
    </div>
    <!-- <div class="column is-6 form-container">
        <span class="label">Fecha de alta</span>
        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy"  dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
    </div> -->
    <!-- @if (clientId) {
        <div class="column is-6 form-container">
            <span class="label">Fecha de baja</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy"  dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
        </div>
    } -->
</div>
