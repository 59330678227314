<div [formGroup]="otherDataForm">
<!--     <div class="form-title-container">
        <h3>Otros datos</h3>
    </div>
    <div class="columns is-multiline ">
        <div class="column is-6 form-container">
            <span class="label">Nombre administración AEAT</span>
            <input class="search-input" type="text" pInputText formControlName="administrationNameAEAT"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Código administración AEAT</span>
            <input class="search-input" type="text" pInputText formControlName="administrationCodeAEAT"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Delegación AEAT</span>
            <input class="search-input" type="text" pInputText formControlName="delegationAEAT"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="intraCommunityOperatorObligation" binary="true" inputId="intraCommunityOperatorObligation"/>
            <label for="intraCommunityOperatorObligation">Operador intracomunitario</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha alta ROI</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="intraCommunityOperatorStartDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha baja ROI</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="intraCommunityOperatorEndDate"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="presentationSIIObligation" binary="true" inputId="presentationSIIObligation"/>
            <label for="presentationSIIObligation">Obligación presentación SII</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha alta SII</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="presentationSIIStartDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha baja SII</span>
            <p-calendar [iconDisplay]="'input'"  dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="presentationSIIEndDate"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="codeLEI" binary="true" inputId="codeLEI"/>
            <label for="codeLEI">Código LEI</label>
        </div>
        <div class="column is-6 form-container" >
            <span class="label">Fecha caducidad LEI</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="caducityDateLEI"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="codeEORI" binary="true" inputId="codeEORI"/>
            <label for="codeEORI">Código EORI</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="digitalCertificate" binary="true" inputId="digitalCertificate"/>
            <label for="digitalCertificate">Certificado digital</label>
        </div>
        <div class="column is-6 form-container" >
            <span class="label">Fecha caducidad Certificado Digital</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="digitalCertificateCaducityDate"/>
        </div>
    </div> -->
    <div class="form-title-container">
        <h3>Cuetas bancarias</h3>
        <a class="button add-button" (click)="openFormModal()">Añadir cuenta</a>
    </div>
    @if (configTable.data) {
        <div class="card">
            <app-clients-table [configTable]="configTable" (close)="close($event)" (reopen)="reopen($event)" (openItemEvent)="openFormModal($event)"></app-clients-table>
        </div>
    }
    
    <!-- <div [formGroup]="bankAccountForm">
        <p-accordion [multiple]="true" formArrayName="bankAccounts">
            @for (account of bankAccounts.controls; let i = $index; track account) {
                <p-accordionTab header="{{bankAccounts.at(i).value.name ? bankAccounts.at(i).value.name : 'Nuevo Banco' }}" [formGroupName]="i" class="">
                    <div class="columns is-multiline">
                        <div class="column is-6 form-container">
                            <span class="label">Banco</span>
                            <input class="search-input" type="text" pInputText formControlName="bankName"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">IBAN</span>
                            <input class="search-input" type="text" pInputText formControlName="iban"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha alta</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha baja</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                        </div>
                    </div>
                </p-accordionTab>
            }
        </p-accordion>
    </div> -->
    
</div>
<div class="modal" [class.is-active]="isModalActive">
    <div class="modal-background" (click)="isModalActive = false"></div>
    <div class="modal-card">
        <form [formGroup]="modalForm" (ngSubmit)="saveItem()" >
      <header class="modal-card-head">
        @if(isCreating) {
            <p class="modal-card-title">Nueva Cuenta Bancaria</p>
        } @else {
            <p class="modal-card-title">{{modalForm.value.iban}}</p>
        }
      </header>
      <section class="modal-card-body">
          <div class="field">
            <label class="label">Banco</label>
            <input class="input" formControlName="bankName" />
          </div>
          <div class="field">
            <label class="label">IBAN</label>
            <input class="input" formControlName="iban" />
          </div>
          <div class="field">
            <label class="label">Fecha alta</label>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
        </div>
          <div class="field">
            <label class="label">Fecha baja</label>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
        </div>
        </section>
        <footer class="modal-card-foot buttons-container">
            <button class="button cancel-button" (click)="isModalActive = false">Cancelar</button>
            <button class="button save-button" type="submit" [disabled]="!modalForm.valid">Guardar</button>
        </footer>
    </form>
    </div>
  </div>

