import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { share } from 'rxjs';

@Component({
  selector: 'app-society-data-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule],
  templateUrl: './society-data-form.component.html',
  styleUrl: './society-data-form.component.scss'
})
export class SocietyDataFormComponent {

  public clientConstants = ClientConstants;

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  societyDataForm = this.fb.group({
    id: [],
    client: [],
    companyType: [''],
    isBigCompany: [''],
    startDate: [, Validators.required],
    endDate: [],
    startDateISOObligation: [],
    socialExerciseClosingDate: [],
    fractionatedPayment: [''],
    socialDomicileStartDate: [],
    acronym: [''],
    street: [''],
    number: [],
    stairs: [],
    floor: [],
    door: [],
    town: [''],
    postalCode: [],
    province: [''],
    codeCountry: [''],
    country: [''],
    cadastralReference: [''],
    socialDomicileEndDate: []
  });

  constitutionForm = this.fb.group({
    constitutions: this.fb.array([]),
  }); 

  socialObjectForm = this.fb.group({
    socialObjects: this.fb.array([]),
  });

  socialCapitalForm = this.fb.group({
    socialCapitals: this.fb.array([]),
  });

  capitalIncreaseForm = this.fb.group({
    capitalIncreases: this.fb.array([]),
  });

  capitalReductionForm = this.fb.group({
    capitalReductions: this.fb.array([]),
  });

  buySellForm = this.fb.group({
    buySells: this.fb.array([]),
  });

  shareholdersPartnersForm = this.fb.group({
    shareholdersPartners: this.fb.array([]),
  });

  administrationForm = this.fb.group({
    administrations: this.fb.array([]),
  });

  booksForm = this.fb.group({
      id: [],
      client: [],
      actsBookOrderNumber: [''],
      actsBookDate: [],
      partnersBookOrderNumber: [''],
      partnersBookDate: [],
      invCCABookOrderNumber: [''],
      invCCABookDate: [],
      diaryBookOrderNumber: [''],
      diaryBookDate: []
  });

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService,
    ) { 
      this.societyDataForm.valueChanges.subscribe(() => {
        if (this.societyDataForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.constitutionForm.valueChanges.subscribe(() => {
        if (this.constitutionForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.socialObjectForm.valueChanges.subscribe(() => {
        if (this.socialObjectForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.socialCapitalForm.valueChanges.subscribe(() => {
        if (this.socialCapitalForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.capitalIncreaseForm.valueChanges.subscribe(() => {
        if (this.capitalIncreaseForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.capitalReductionForm.valueChanges.subscribe(() => {
        if (this.capitalReductionForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.buySellForm.valueChanges.subscribe(() => {
        if (this.buySellForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.shareholdersPartnersForm.valueChanges.subscribe(() => {
        if (this.shareholdersPartnersForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.administrationForm.valueChanges.subscribe(() => {
        if (this.administrationForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.booksForm.valueChanges.subscribe(() => {
        if (this.booksForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      
    }

  async ngOnInit() {
    await this.getData();
      
  }

  async getData() {
    if (this.clientId) {
      let data = await this.clientsService.getClientSociety(this.clientId);
      if (data) {
        if (data.clientSociety) {
          data.clientSociety.startDate = data.clientSociety.startDate ? new Date(data.clientSociety.startDate) : null;
        data.clientSociety.endDate = data.clientSociety.endDate ? new Date(data.clientSociety.endDate) : null;  
        data.clientSociety.startDateISOObligation = data.clientSociety.startDateISOObligation ? new Date(data.clientSociety.startDateISOObligation) : null;
        data.clientSociety.socialExerciseClosingDate = data.clientSociety.socialExerciseClosingDate ? new Date(data.clientSociety.socialExerciseClosingDate) : null; 
        data.clientSociety.socialDomicileStartDate = data.clientSociety.socialDomicileStartDate ? new Date(data.clientSociety.socialDomicileStartDate) : null; 
        data.clientSociety.socialDomicileEndDate = data.clientSociety.socialDomicileEndDate ? new Date(data.clientSociety.socialDomicileEndDate) : null; 
        this.societyDataForm.patchValue(data.clientSociety);
        }
        

      if (data.constitution.length > 0) {
        data.constitution.forEach((constitution) => {
          constitution.documentDate = constitution.documentDate ? new Date(constitution.documentDate) : null;
          constitution.dealDate = constitution.dealDate ? new Date(constitution.dealDate) : null;
          constitution.inscriptionDate = constitution.inscriptionDate ? new Date(constitution.inscriptionDate) : null;

          this.constitution.push(this.fb.group(constitution));
        });
      } else {
        this.addConstitution();
      }

      if (data.socialObject.length > 0) {
        data.socialObject.forEach((socialObject) => {
          socialObject.documentDate = socialObject.documentDate ? new Date(socialObject.documentDate) : null;
          socialObject.dealDate = socialObject.dealDate ? new Date(socialObject.dealDate) : null;
          socialObject.inscriptionDate = socialObject.inscriptionDate ? new Date(socialObject.inscriptionDate) : null;
          socialObject.startDate = socialObject.startDate ? new Date(socialObject.startDate) : null;
          socialObject.endDate = socialObject.endDate ? new Date(socialObject.endDate) : null;

          this.socialObject.push(this.fb.group(socialObject));
        });
      }

      if (data.socialCapital.length > 0) {
        data.socialCapital.forEach((socialCapital) => {

          this.socialCapital.push(this.fb.group(socialCapital));
        });
      }

      if (data.capitalIncrease.length > 0) {
        data.capitalIncrease.forEach((capitalIncrease) => {
          capitalIncrease.increaseDate = capitalIncrease.increaseDate ? new Date(capitalIncrease.increaseDate) : null;
          capitalIncrease.dealDate = capitalIncrease.dealDate ? new Date(capitalIncrease.dealDate) : null;
          capitalIncrease.inscriptionDate = capitalIncrease.inscriptionDate ? new Date(capitalIncrease.inscriptionDate) : null;

          this.capitalIncrease.push(this.fb.group(capitalIncrease));
        });
      }

      if (data.capitalReduction.length > 0) {
        data.capitalReduction.forEach((capitalReduction) => {
          capitalReduction.reductionDate = capitalReduction.reductionDate ? new Date(capitalReduction.reductionDate) : null;
          capitalReduction.dealDate = capitalReduction.dealDate ? new Date(capitalReduction.dealDate) : null;
          capitalReduction.inscriptionDate = capitalReduction.inscriptionDate ? new Date(capitalReduction.inscriptionDate) : null;

          this.capitalReduction.push(this.fb.group(capitalReduction));
        });
      }

      if (data.buySell.length > 0) {
        data.buySell.forEach((buySell) => {
          buySell.documentDate = buySell.documentDate ? new Date(buySell.documentDate) : null;
          buySell.dealDate = buySell.dealDate ? new Date(buySell.dealDate) : null;
          buySell.contractDate = buySell.contractDate ? new Date(buySell.contractDate) : null;

          this.buySell.push(this.fb.group(buySell));
        });
      }

      if (data.shareholderPartner.length > 0) {
        data.shareholderPartner.forEach((shareholderPartner) => {
          shareholderPartner.startDate = shareholderPartner.startDate ? new Date(shareholderPartner.startDate) : null;
          shareholderPartner.endDate = shareholderPartner.endDate ? new Date(shareholderPartner.endDate) : null;

          this.shareholdersPartners.push(this.fb.group(shareholderPartner));
        });
      }

      if (data.administration.length > 0) {
        data.administration.forEach((administration) => {
          administration.startDate = administration.startDate ? new Date(administration.startDate) : null;
          administration.endDate = administration.endDate ? new Date(administration.endDate) : null;
          administration.startDocumentDate = administration.startDocumentDate ? new Date(administration.startDocumentDate) : null;
          administration.startDealDate = administration.startDealDate ? new Date(administration.startDealDate) : null;
          administration.startInscriptionDate = administration.startInscriptionDate ? new Date(administration.startInscriptionDate) : null;
          administration.endDocumentDate = administration.endDocumentDate ? new Date(administration.endDocumentDate) : null;
          administration.endDealDate = administration.endDealDate ? new Date(administration.endDealDate) : null;
          administration.endInscriptionDate = administration.endInscriptionDate ? new Date(administration.endInscriptionDate) : null;
          administration.personalDigitalCertificateCaducityDate = administration.personalDigitalCertificateCaducityDate ? new Date(administration.personalDigitalCertificateCaducityDate) : null;
          this.administration.push(this.fb.group(administration));
        });
      }
      if (data.book.length > 0) {
        data.book[0].actsBookDate = data.book[0].actsBookDate ? new Date(data.book[0].actsBookDate) : null;
        data.book[0].partnersBookDate = data.book[0].partnersBookDate ? new Date(data.book[0].partnersBookDate) : null;
        data.book[0].invCCABookDate = data.book[0].invCCABookDate ? new Date(data.book[0].invCCABookDate) : null;
        data.book[0].diaryBookDate = data.book[0].diaryBookDate ? new Date(data.book[0].diaryBookDate) : null;
        this.booksForm.patchValue(data.book[0]);
      }
      
    }
  }
  }

  get constitution(): FormArray {
    return this.constitutionForm.get('constitutions') as FormArray;
  }

  addConstitution() {
    const constitution = this.fb.group({
      id: [],
      client: [],
      documentConcept: [''],
      notary: [''],
      protocol: [''],
      documentDate: [],
      commercialRegisterProvince: [''],
      dealDate: [],
      inscriptionDate: [],
      tome: [''],
      sheet: [''],
      leaf: [''],
      section: [''],
      book: [''],
      inscriptionNumber: [''],
    });

    this.constitution.push(constitution);
  }

  get socialObject(): FormArray {
    return this.socialObjectForm.get('socialObjects') as FormArray;
  }

  addSocialObject() {
    const socialObject = this.fb.group({
      id: [],
      client: [],
      documentConcept: [''],
      notary: [''],
      protocol: [''],
      documentDate: [],
      commercialRegisterProvince: [''],
      dealDate: [],
      inscriptionDate: [''],
      tome: [''],
      sheet: [''],
      leaf: [''],
      section: [''],
      book: [''],
      inscriptionNumber: [''],
      socialObject: [''],
      principalActivityCNAE: [''],
      activityDetail: [''],
      principalActivityEpigraphIAE: [''],
      startDate: [],
      endDate: [],
    });

    this.socialObject.push(socialObject);
  }

  get socialCapital(): FormArray {
    return this.socialCapitalForm.get('socialCapitals') as FormArray;
  }

  addSocialCapital() {
    const socialCapital = this.fb.group({
      id: [],
      client: [],
      type: [''],
      totalActionsNumber: [''],
      nominalValueByAction: [''],
      totalSocialCapital: [''],
      actionsNumeration: [''],
      bonusEmission: [''],
      observations: [''],
    });

    this.socialCapital.push(socialCapital);
  }

  get capitalIncrease(): FormArray {
    return this.capitalIncreaseForm.get('capitalIncreases') as FormArray;
  }

  addCapitalIncrease() {
    const capitalIncrease = this.fb.group({
      id: [],
      client: [],
      documentConcept: [''],
      notary: [''],
      protocol: [''],
      documentDate: [],
      commercialRegisterProvince: [''],
      dealDate: [],
      inscriptionDate: [''],
      tome: [''],
      sheet: [''],
      leaf: [''],
      section: [''],
      book: [''],
      inscriptionNumber: [''],
      increaseDate: [''],
      increaseType: [''],
      observations: [''],
      capitalIncreaseSeries: this.fb.array([]),
    });

    this.capitalIncrease.push(capitalIncrease);
  }

  get capitalIncreaseSeries(): FormArray {
    return this.capitalIncrease.get('capitalIncreaseSeries') as FormArray;
  }

  addCapitalIncreaseSeries() { 
    const capitalIncreaseSeries = this.fb.group({
      numACCPartIncreased: [''],
      totalIncreasedNominal: [''],
      numACCPartAfterIncrease: [''],
      nominalValueAccPartAfterIncrease: [''],
      totalNominalValueAfterIncrease: [''],
    });

    this.capitalIncreaseSeries.push(capitalIncreaseSeries);
  }

  get capitalReduction(): FormArray {
    return this.capitalReductionForm.get('capitalReductions') as FormArray;
  }

  addCapitalReduction() {
    const capitalReduction = this.fb.group({
      id: [],
      client: [],
      documentConcept: [''],
      notary: [''],
      protocol: [''],
      documentDate: [],
      commercialRegisterProvince: [''],
      dealDate: [],
      inscriptionDate: [''],
      tome: [''],
      sheet: [''],
      leaf: [''],
      section: [''],
      book: [''],
      inscriptionNumber: [''],
      reductionDate: [''],
      reductionType: [''],
      observations: [''],
      series: this.fb.array([]),
    });

    this.capitalReduction.push(capitalReduction);
  }

  get capitalReductionSeries(): FormArray {
    return this.capitalIncrease.get('capitalReductionSeries') as FormArray;
  }

  addCapitalReductionSeries() { 
    const capitalReductionSeries = this.fb.group({
      numACCPartReduced: [''],
      totalIncreasedNominal: [''],
      numACCPartAfterReduction: [''],
      nominalValueAccPartAfterReduction: [''],
      totalNominalValueAfterReduction: [''],
    });

    this.capitalReductionSeries.push(capitalReductionSeries);
  }

  get buySell(): FormArray {
    return this.buySellForm.get('buySells') as FormArray;
  }

  addBuySell() {
    const buySell = this.fb.group({
      id: [],
      client: [],
      documentConcept: [''],
      notary: [''],
      protocol: [''],
      documentDate: [],
      dealDate: [],
      contractDate: [],
      buyerName: [''],
      actionsNumber: [],
      totalNominal: [],
      actionsNumeration: [''],
      emissionPrime: [],
      buySellPrice: [],
      sellerName: [''],


    });

    this.buySell.push(buySell);
  }

  get shareholdersPartners(): FormArray {
    return this.shareholdersPartnersForm.get('shareholdersPartners') as FormArray;
  }

  addShareholdersPartners() {
    const shareholdersPartners = this.fb.group({
      id: [],
      client: [],
      name: [''],
      nif: [''],
      resident: [''],
      domicile: [''],
      postalCode: [''],
      town: [''],
      province: [''],
      startDate: [],
      representativeName: [''],
      representativeNif: [''],
      actionsNumber: [''],
      actionsNumeration: [''],
      percentage: [''],
      directControl: [''],
      indirectControl: [''],
      familiar: [''],
      worker: [''],
      administrationMember: [''],
      socialSecurityRegime: [''],
      framing: [''],
      autonomousInChargeOf: [''],
      iban: [''],
      monthlyNetReturn: [''],
      mutuaACC: [''],
      mutuaCC: [''],
      totalNominalValue: [''],
      generalMeetingPresident: [''],
      generalMeetingSecretary: [''],
      endDate: [],

    });

    this.shareholdersPartners.push(shareholdersPartners);
  }

  get administration(): FormArray {
    return this.administrationForm.get('administrations') as FormArray;
  }

  addAdministration() {
    const administration = this.fb.group({
      id: [],
      client: [],
      administrationSystem: [''],
      name: [''],
      nif: [''],
      address: [''],
      postalCode: [''],
      town: [''],
      province: [''],
      position: [''],
      startDate: [],
      startNotary: [''],
      startProtocol: [''],
      startDocumentDate: [],
      startMercantileRegisterProvince: [''],
      startDealDate: [],
      startInscriptionDate: [],
      startTome: [''],
      startSheet: [''],
      startLeaf: [''],
      startSection: [''],
      startBook: [''],
      startInscriptionNumber: [''],
      endDate: [],
      endNotary: [''],
      endProtocol: [''],
      endDocumentDate: [''],
      endMercantileRegisterProvince: [''],
      endDealDate: [],
      endInscriptionDate: [],
      endTome: [''],
      endSheet: [''],
      endLeaf: [''],
      endSection: [''],
      endBook: [''],
      endInscriptionNumber: [''],
      representativeName: [''],
      representativeNif: [''],
      retribution: [''],
      retributionKey: [''],
      retributionImport: [''],
      retributionInStatutes: [''],
      retributionStartRetributionExercise: [''],
      retributionContract: [''],
      retributionAct: [''],
      regime: [''],
      framing: [''],
      iban: [''],
      autonomousInChargeOf: [''],
      monthlyNetReturn: [''],
      mutuaACC: [''],
      mutuaCC: [''],
      personalDigitalCertificate: [''],
      personalDigitalCertificateCaducityDate: [],
      signsCCAA: [''],
      certifiesCCAA: [''],
      signsIS: [''],
      certifiesIS: [''],
      signsLaborContracts: [''],
      signsLaborDocuments: [''],
    });

    this.administration.push(administration);
  }

  async save() {
    this.societyDataForm.value.client = Number(this.clientId);
    if (!this.societyDataForm.value.id) {
      delete this.societyDataForm.value['id'];
    }
    await this.clientsService.saveClientSociety(this.societyDataForm.value);

      for (let i = 0; i < this.constitution.length; i++) {
        if(this.constitution.at(i).dirty) {
          if(!this.constitution.at(i).value.id) {
          this.constitution.at(i).value.client = Number(this.clientId);
          }
          if (!this.constitution.at(i).value.id) {
            delete this.constitution.value['id'];
          }
          await this.clientsService.saveClientConstitution(this.constitution.at(i).value);
        }
      }
    
      for (let i = 0; i < this.socialObject.length; i++) {
        if(this.socialObject.at(i).dirty) {
          if(!this.socialObject.at(i).value.id) {
            this.socialObject.at(i).value.client = Number(this.clientId);
          }        
          if (!this.socialObject.at(i).value.id) {
            delete this.socialObject.value['id'];
          }
          await this.clientsService.saveClientSocialObject(this.socialObject.at(i).value);
        }
      }
    
      for (let i = 0; i < this.socialCapital.length; i++) {
        if(this.socialCapital.at(i).dirty) {
          if(!this.socialCapital.at(i).value.client) {
            this.socialCapital.at(i).value.client = Number(this.clientId);
          }
          if (!this.socialCapital.at(i).value.id) {
            delete this.socialCapital.value['id'];
          }
          await this.clientsService.saveClientSocialCapital(this.socialCapital.at(i).value);
        }
      }
    
      for (let i = 0; i < this.capitalIncrease.length; i++) {
        if(this.capitalIncrease.at(i).dirty) {
          if(!this.capitalIncrease.at(i).value.client) {
            this.capitalIncrease.at(i).value.client = Number(this.clientId);
          }
          if (!this.capitalIncrease.at(i).value.id) {
            delete this.capitalIncrease.value['id'];
          }
          await this.clientsService.saveClientCapitalIncrease(this.capitalIncrease.at(i).value);
        }
      }
    
      for (let i = 0; i < this.capitalReduction.length; i++) {
        if(this.capitalReduction.at(i).dirty) {
          if(!this.capitalReduction.at(i).value.client) {
            this.capitalReduction.at(i).value.client = Number(this.clientId);
          }
          if (!this.capitalReduction.at(i).value.id) {
            delete this.capitalReduction.value['id'];
          }
          await this.clientsService.saveClientCapitalReduction(this.capitalReduction.at(i).value);
        }
      }
    
      for (let i = 0; i < this.buySell.length; i++) {
        if(this.buySell.at(i).dirty) {
          if(!this.buySell.at(i).value.client) {
            this.buySell.at(i).value.client = Number(this.clientId);
          }
          if (!this.buySell.at(i).value.id) {
            delete this.buySell.value['id'];
          }
          await this.clientsService.saveClientBuySells(this.buySell.at(i).value);
        }
      }
    
      for (let i = 0; i < this.shareholdersPartners.length; i++) {
        if(this.shareholdersPartners.at(i).dirty) {
          if(!this.shareholdersPartners.at(i).value.client) {
            this.shareholdersPartners.at(i).value.client = Number(this.clientId);
          }
          if (!this.shareholdersPartners.at(i).value.id) {
            delete this.shareholdersPartners.value['id'];
          }
          await this.clientsService.saveClientShareholders(this.shareholdersPartners.at(i).value);
        }
      }
    
      for (let i = 0; i < this.administration.length; i++) {
        if(this.administration.at(i).dirty) {
          if(!this.administration.at(i).value.client) {
            this.administration.at(i).value.client = Number(this.clientId);
          }
          if (!this.administration.at(i).value.id) {
            delete this.administration.value['id'];
          }
          await this.clientsService.saveClientAdministrators(this.administration.at(i).value);
        }
      }
      if(this.booksForm.dirty) {
        this.booksForm.value.client = Number(this.clientId);
        if(!this.booksForm.value.id) {
          delete this.booksForm.value['id'];
        }
        await this.clientsService.saveClientBooks(this.booksForm.value);
      }
    await this.getData();
  }
}
