import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { DeclarationsService } from '../../../core/services/declarations/declarations.service';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { LogTableComponent } from '../../../components/log-table/log-table.component';

@Component({
  selector: 'app-declaration-log',
  standalone: true,
  imports: [FontAwesomeModule, RouterModule, LogTableComponent, PaginationComponent],
  templateUrl: './declaration-log.component.html',
  styleUrl: './declaration-log.component.scss'
})
export class DeclarationLogComponent {
  faChevronLeft = faChevronLeft;
  declaration: any;

    headers = [{
      name: 'Fecha',
      key: 'date'
    },
    {
      name: 'Fase',
      key: 'phase'
    },
    {
      name: 'Tarea',
      key: 'task'
    },
    {
      name: 'Estado',
      key: 'state'
    },
    {
      name: 'Ejecutor',
      key: 'executor'
    },
  ];

  configLogTable: any = {
    headers: this.headers,
    data: undefined,
  };

  currentPage = 0;
  itemsForPage = 10;
  maxPages = undefined;

  constructor(
    private route: ActivatedRoute,
    private _declarationService: DeclarationsService
  ) { }

  async ngOnInit() {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    this.declaration = await this._declarationService.getDeclarationsById(id);
    await this.getData();
  }

  async getData() {
    const data = await this._declarationService.getActivityByDeclaration(this.currentPage, this.itemsForPage, this.declaration.id);
    this.maxPages = data.totalPages;
    const tableData = data.activities.map((activity: any) => {
        return {
          date: activity.created_At,
          phase: activity.statusTaskDeclaration ? activity.statusTaskDeclaration.task.phase : '-',
          task: activity.statusTaskDeclaration ? activity.statusTaskDeclaration.task.order +'. '+activity.statusTaskDeclaration.task.name : activity.type,
          type: activity.type,
          state: activity.change.new ? activity.change.new : false,
          executor: activity.executor.name
        }
      })
    this.configLogTable = {...this.configLogTable, headers: this.headers, data: tableData};
  }
}
