<div class="container-fluid header-container">
    <div class="header">
        <div [routerLink] = "['/clients']">
            <h1 class="title"> <fa-icon class="back-icon"  [icon]="faChevronLeft"></fa-icon>
                {{client ? client.name : 'Nuevo Cliente'}}
            </h1>
        </div>
    </div>
    <div class="tabs is-fullwidth is-primary">
        <ul>
            @for (tab of tabs; track tab.title) {
            <li (click)="enabledTabs ? changeTab(tab) : null" [ngClass]="{'is-active' : tab.isActive, 'is-disabled' : !enabledTabs && !tab.isActive}"><a>{{tab.title}}</a></li>
            }
        </ul>
    </div>
</div>

<div class="container-fluid content-page">
    @if(!isLoading) {
        @switch (getActiveTab().key) {
            @case("identification") {
                <app-identification-form #activeForm [clientId]="clientId" (createEvent)="onClientCreated($event)" (formIsDirty)="showButton = true"></app-identification-form>
            }
            @case("contactData") {
                <app-contacts-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-contacts-form>
            }
            @case("addresses") {
                <app-addresses-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-addresses-form>
            }
            @case("activities") {
                <app-activities-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-activities-form>
            }
            @case("laborData") {
                <app-labor-data-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-labor-data-form>
            }
            @case("societyData") {
                <app-society-data-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-society-data-form>    
            }
            @case("invoicing") {
                <app-invoicing-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-invoicing-form>
            }
            @case("otherData") {
                <app-other-data-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-other-data-form>
            }
            @case("declarations") {
                <app-declarations-form #activeForm [clientId]="clientId" (formIsDirty)="showButton = true"></app-declarations-form>
            }
        }
    } @else {
        <div class="column is-12" style="display: flex; justify-content: center; margin-top: 100px;">
            <p-progressSpinner ariaLabel="loading"
            styleClass="custom-spinner"/>        </div>
    }
</div>
@if(showButton) {
    <div class="save-button-container">
        <button class="button save-button" (click)="saveTab()"><fa-icon class="save-icon" [icon]="faFloppyDisk"></fa-icon> Guardar</button>
    </div>

}
@if (isSaved) {
    <div class="notification is-success">
        <!-- <button class="delete" (click)="isSaved = false"></button> -->
        <div class="notification-text">
            Guardado correctamente.
        </div>
        <div class="progress-bar-container">

        <progress class="progress is-small" min="0" max="100" #progressBar></progress>
        </div>
    </div>
}
