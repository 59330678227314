import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';

@Component({
  selector: 'app-intern-data',
  standalone: true,
  imports: [CommonModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, FormsModule, ReactiveFormsModule, InputNumberModule, AccordionModule],
  templateUrl: './intern-data.component.html',
  styleUrl: './intern-data.component.scss'
})
export class InternDataComponent {
  @Input() data: any;
  public clientConstants = ClientConstants;

  invoicingForm = this.fb.group({
    paymentType: [''],
    paymentDays: [''],
    invoicingStartDate: [''],
    expiration: [''],
    billFormat: [''],
    exemptionIVA: [''],
    exemptionTypeIVA: [''],
    includeReceipts: [''],
    iban1: [''],
    bank1: [''],
    iban2: [''],
    bank2: [''],
    sepa: [''],
    totalImport: [''],
    periodicity: [''],
    nextEmissionDate: [''],
    expedient: [''],
    emittingCompany: [''],
    receiptFormat: [''],
    remittanceDomiciliation: [''],
    accountingSeries: [''],
    independentBilling: [''],
    feeProposalAccepted: [''],
    acceptationDate: [''],
    total: [''],
    fiscal: [''],
    accounting: [''],
    labor: [''],
    laborBase: [''],
    laborWorker: [''],
    laborProfessional: [''],
    notifications: [''],
    irpf: [''],
    societary: [''],
    laborContractsAndAnnexes: [''],
    socialSecurity: [''],
    laborRequirements: [''],
    laborResources: [''],
    fiscalRequirements: [''],
    fiscalResources: [''],
    sacichDomiciliation: [''],
    anualAccounts: [''],
    id: [],
    client: []
  });

  otherForm = this.fb.group({
    bankAccounts: this.fb.array([]),
});

  constructor(
    private fb: FormBuilder,
  ) { }

  async ngOnInit() {
    if (this.data) {
      this.data.invoicing.paymentDays =  this.data.invoicing.paymentDays ? new Date(this.data.invoicing.paymentDays) : null;
      this.data.invoicing.expiration = this.data.invoicing.expiration ? new Date(this.data.invoicing.expiration) : null;
      this.data.invoicing.invoicingStartDate = this.data.invoicing.invoicingStartDate ? new Date(this.data.invoicing.invoicingStartDate) : null;
      this.data.invoicing.nextEmissionDate = this.data.invoicing.nextEmissionDate ? new Date(this.data.invoicing.nextEmissionDate) : null;
      this.data.invoicing.acceptationDate = this.data.invoicing.acceptationDate ? new Date(this.data.invoicing.acceptationDate) : null;
      
      this.invoicingForm.patchValue(this.data.invoicing);
      let bankAccountsArray = this.otherForm.get('bankAccounts') as FormArray;
      this.data.bankAccounts.forEach(bankAccount => {
        bankAccount.startDate = bankAccount.startDate ? new Date(bankAccount.startDate) : null;
        bankAccount.endDate = bankAccount.endDate ? new Date(bankAccount.endDate) : null;
        bankAccountsArray.push(this.fb.group(bankAccount));
      });
    }
  }

  get bankAccounts(): FormArray {
    return this.otherForm.get('bankAccounts') as FormArray;
  }
}
