import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  constructor(
    private http: HttpClient,
  ) { }

  async createModel(modelForm: any) {

    const tasks = [];
    modelForm.phases.array.forEach(phase => {
      phase.tasks.array.forEach(task => {
        tasks.push(task);
      });
    });

    const model = {
      name: modelForm.name,
      department: modelForm.department,
      periodicity: modelForm.periodicity,
      startMonth: modelForm.startMonth,
      isDaysTime: modelForm.isDaysTime,
      numberOfTime: modelForm.numberOfTime,
      availablePresentations: modelForm.availablePresentations,
      tasks: tasks
    };

    //return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/models`, model));
  }

  async getModelsGroupedByDepartment() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/models/grouped-by-department`));
  }
}
