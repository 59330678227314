<div class="container-fluid title">
    <div class="columns is-multiline">
        <div class="column is-10">
            <h1 class="title">Gestor Clientes</h1>
        </div>
        <!-- <div class="column is-2">
            <button class="button create-client-button" (click)="createClient()">
                <fa-icon class="create-client-icon" [icon]="faUserPlus"></fa-icon>
                Nuevo Cliente
            </button>
        </div> -->

    </div>
</div>
<div class="container-fluid content-page background">
    @if (!isLoading) {
        <app-clients-table [configTable]="configTable"></app-clients-table>
    <div class="pagination-container">
        <app-pagination
            [currentPage]="currentPage" 
            [totalPages]= "maxPages"
            (pageChange)="currentPage = $event; getData()">
        </app-pagination>
    </div>
    }@else {
        <div class="column is-12" style="display: flex; justify-content: center; margin-top: 100px;">
            <p-progressSpinner ariaLabel="loading"
            styleClass="custom-spinner"/>        </div>
    }
    
</div>