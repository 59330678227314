export enum TypeChangeEnum {
    Responsible = 'RESPONSIBLE',
    Presentation = 'PRESENTATION',
    Format = 'FORMAT',
    Account = 'ACCOUNT',
    Status = 'STATUS',

    Executor = 'EXECUTOR',
    StatusTask = 'STATUS_TASK',
    DisableTask = 'DISABLE_TASK',
}