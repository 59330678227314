import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import e from 'express';

@Injectable({
  providedIn: 'root'
})
export class DeclarationsService {

  constructor(
    private http: HttpClient,
  ) { }

  async getDeclarations(department?: any, currentPage?: number, itemsForPage?: number, clients?: any[], fase?: any, models?: any, presentations?: any, only?: any, isHistoric?: boolean, withComments?: boolean, years?: any) {
    let path = "";
    if(department && department !== 'GENERAL'){
      path += `?department=${department}`;
      if(itemsForPage){
        path += `&page=${currentPage}`;
        path += `&items=${itemsForPage}`;
      }
    }else {
      if(itemsForPage){
        path += `?page=${currentPage}`;
        path += `&items=${itemsForPage}`;
      }
    }
    if (clients){
      if(clients.length > 0){
        const clientsArray = clients.map((client: any) => client.id).join(',');
        path += `&clients=${clientsArray}`;
      }
    }
    if (fase && !isHistoric){
      const fasesArray = fase.id;
      path += `&phases=${fasesArray}`;
    }
    if(models){
      if (models.length > 0){
        const modelsArray = models.join(',');
        path += `&models=${modelsArray}`;
      }
    }
    if (only && only !== 'all'){
      path += `&only=${only}`;
    }

    if (withComments){
      path += `&hasComments=${withComments}`;
    }
    if(presentations){
      if (presentations.length > 0){
        path += `&presentations=${presentations}`;
      }
    }
    
    if (isHistoric){
      path += `&isHistoric=${isHistoric}`;
      if (years){
        path += `&years=${years}`;
      }

    }
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/declarations` + path)); 
  }

  async getDeclarationsById(id: number) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/declarations/detail/${id}`));

  }

  async getObservationsByDeclaration(id: number, currentPage: number, itemsForPage: number) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/observations/by-declaration/${id}?page=${currentPage}&items=${itemsForPage}`));
  }

  async getDeclarationsInMass(ids: number[]) {
    const idsString = ids.map(id => 'ids=' + id).join('&');
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/declarations/detail-in-mass?${idsString}`));

  }

  async editDeclaration(id: number, body: any) {
    return await lastValueFrom<any>(this.http.put(`${environment.apiUrl}/declarations/edit/${id}`, body));
  }

  async taskStatusUpdate(task: any) {
    const body = {
        type: "STATUS_TASK",
        change: task.change
    };
    return await lastValueFrom<any>(this.http.put(`${environment.apiUrl}/status-tasks/edit/${task.id}`, body));
  }

  async taskExecutorUpdate(taskId: any, executorId: any) {
    const body = {
        type: "EXECUTOR",
        change: executorId
    };
    return await lastValueFrom<any>(this.http.put(`${environment.apiUrl}/status-tasks/edit/${taskId}`, body));
  }

  async massTaskStatusUpdate(changedTasks: any[]) {
    return await lastValueFrom<any>(this.http.put(`${environment.apiUrl}/status-tasks/update-mass`, changedTasks));
  }

  async createObservation(observation: any) {

    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/observations`, observation));
  }

  async createObservationInMass(observations: any) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/observations/create-mass`, observations));
  }

  async getActivityByDeclaration(currentPage: number, itemsForPage: number, id: number) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/activities/get-by-declaration/${id}?page=${currentPage}&items=${itemsForPage}`));
  }

  async createAsociedDeclaration(id: any, type: any) {
    const body = {
      declarationId: id,
      type: type
    }
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/declarations`, body));
  }

  async changeStatusDeclarationInMass(ids: any, status: any) { 
    let body = ids.map((id: any) => {
      return {
        id: id,
        type: "STATUS",
        change: status
      }
     });
    return await lastValueFrom<any>(this.http.put(`${environment.apiUrl}/declarations//update-mass`, body));
  }
}
