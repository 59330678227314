import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { FileUploadModule } from 'primeng/fileupload';
import { faFilePlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

interface UploadEvent {
  originalEvent: Event;
  files: File[];
}

@Component({
  selector: 'app-labor-data-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule, CommonModule, FileUploadModule, FontAwesomeModule],
  templateUrl: './labor-data-form.component.html',
  styleUrl: './labor-data-form.component.scss'
})


export class LaborDataFormComponent {

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();
  public clientConstants = ClientConstants;

  faFilePlus = faFilePlus;
  
  laborDataForm = this.fb.group({
    id: [],
    socialSecurityCode: [''],
    companyNumberTGSS: [],
    workCentersNumber: [''],
    mutualInsuranceCompany: [''],
    collectiveAgreement: [''],
    sprl: [''],
    agreementInsurance: [''],
    representation: [false],
    personnelDelegates: [false],
    worksCouncil: [false],
    safetyHealthCommittee: [false],
    equalityPlan: [false],
    obligationsLISMI: [false],
    protocolLGTBI: [false],
    reportingChannel: [false],
    sexualHarassmentProtocol: [false],
    systemAuthorizationNumberRED: [''],
    socialSecurityRegime: [''],
    companyLogoUrl: [''],
    companySignatureUrl: [''],
    totalCompanyWorkers: [],
    client: [],
  });

  workCentersForm = this.fb.group({
    workCenters: this.fb.array([])
  });

  uploadedLogo: File = null;
  uploadedLogoUrl: string;

  uploadedSignature: File = null;
  uploadedSignatureUrl: string;

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService
    ) {
      this.laborDataForm.valueChanges.subscribe(() => {
        if (this.laborDataForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.workCentersForm.valueChanges.subscribe(() => {
        if (this.workCentersForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
     }

  async ngOnInit() {
    await this.getData();
  }

  async getData() {
    if (this.clientId) {
      let data = await this.clientsService.getClientLabor(this.clientId);
      this.laborDataForm.patchValue(data);
      this.uploadedLogoUrl = data.companyLogoUrl;
      this.uploadedSignatureUrl = data.companySignatureUrl
      let workCenters = await this.clientsService.getClientWorkCenters(this.clientId);
      let workCentersArray = this.workCentersForm.get('workCenters') as FormArray;
      workCenters.forEach(center => {
        center.startDate = center.startDate ? new Date(center.startDate) : null;
        center.endDate = center.endDate ? new Date(center.endDate) : null;
        workCentersArray.push(this.fb.group(center));
      });
    }
  }

  get workCenters(): FormArray {
    return this.workCentersForm.get('workCenters') as FormArray;
  }

  addWorkCenter() {
    const workCenter = this.fb.group({
      startDate: [],
      principal: [''],
      workCenterQuoteAccountCode: [''],
      unpaidInternshipQuoteAccountCode: [''],
      laborAuthorityRegistrationNumber: [''],
      mutualInsuranceCompany: [''],
      collectiveAgreement: [''],
      sprl: [''],
      agreementInsurance: [''],
      representation: [''],
      personnelDelegates: [''],
      worksCouncil: [''],
      bankAccount: [''],
      payroll: [''],
      socialInsurance: [''],
      irpf: [''],
      acronym: [''],
      street: [''],
      number: [''],
      stairs: [''],
      floor: [''],
      door: [''],
      town: [''],
      postalCode: [''],
      province: [''],
      codeCountry: [''],
      country: [''],
      cadastralReference: [''],
      personnel: [''],
      codeCNAE: [''],
      codeIAE: [''],
      activityType: [''],
      activity: [''],
      endDate: [],
    });

    this.workCenters.push(workCenter);
  }

  onUploadLogo(event) {
      this.uploadedLogo = event.currentFiles[0];
  }

  onUploadSignature(event) {
      this.uploadedSignature = event.currentFiles[0];
  }

  async save() {
    
    if (!this.laborDataForm.value.client) {
      this.laborDataForm.value.client = Number(this.clientId);
    }
    await this.clientsService.saveClientLabor(this.laborDataForm.value);

    for (let i = 0; i < this.workCenters.length; i++) {
      if (this.workCenters.at(i).dirty) {
        if(!this.workCenters.at(i).value.client) {
          this.workCenters.at(i).value.client = Number(this.clientId);
  
        }
        if (!this.workCenters.at(i).value.id) {
          delete this.workCentersForm.value['id'];
        }
        await this.clientsService.saveClientWorkCenter(this.workCenters.at(i).value);
      }
    }

    let labor = await this.clientsService.getClientLabor(this.clientId);
    if (this.uploadedLogo) {
      await this.clientsService.uploadCompanyLogo(this.uploadedLogo, labor.id);
    }
    if (this.uploadedSignature) {
      await this.clientsService.uploadCompanySignature(this.uploadedSignature, labor.id);
    }
    this.workCenters.clear();
    await this.getData();
  }

  choose(event, callback) {
    callback();
}

}
