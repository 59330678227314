<div [formGroup]="activitiesForm">
    <div class="form-title-container">
        <h3>Actividades</h3>
        <a class="button add-button" (click)="addActivity()">Añadir actividad</a>
    </div>
    <p-accordion [multiple]="true" formArrayName="activities">
        @for (activity of activities.controls; let i = $index; track activity) {
            <p-accordionTab header="{{activities.at(i).value.acronym ? activities.at(i).value.acronym : 'Nueva actividad' }}" [formGroupName]="i">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <p-checkbox formControlName="principal" binary="true" inputId="principal" />
                        <label for="principal">Actividad principal</label>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de alta</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Siglas</span>
                        <input class="search-input" type="text" pInputText formControlName="acronym"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Vía pública</span>
                        <input class="search-input" type="text"  pInputText formControlName="street"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Número de vía</span>
                        <input class="search-input" type="text" pInputText formControlName="number"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Escalera</span>
                        <input class="search-input" type="text" pInputText formControlName="stairs"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Piso</span>
                        <input class="search-input" type="text" pInputText formControlName="floor"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Puerta</span>
                        <input class="search-input" type="text" pInputText formControlName="door"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Municipio</span>
                        <input class="search-input" type="text" pInputText formControlName="town"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">CP</span>
                        <input class="search-input" type="text" pInputText formControlName="postalCode"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Provincia</span>
                        <input class="search-input" type="text" pInputText formControlName="province"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código país</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCountry"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">País</span>
                        <input class="search-input" type="text" pInputText formControlName="country"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Referencia catastral</span>
                        <input class="search-input" type="text" pInputText formControlName="cadastralReference"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código CNAE</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCNAE"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código IAE</span>
                        <input class="search-input" type="text" pInputText formControlName="codeIAE"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Tipo de actividad</span>
                        <p-dropdown [options]=" clientConstants.ACTIVITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actividad" formControlName="type"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Centro de trabajo</span>
                        <p-dropdown [options]="" optionLabel="label" placeholder="Seleccionar centro de trabajo" formControlName="workCenter"/>
                    </div>
                    <div class="column is-12">
                        <h4>Parámetros IAE</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="exemptionIAE" binary="true" inputId="exemptionIAE" />
                                <label for="exemptionIAE">Exención IAE</label>
                            </div>
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="startUpExemption" binary="true" inputId="startUpExemption" />
                                <label for="startUpExemption">Exención por inicio</label>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha inicio exención</span>
                                <p-calendar [iconDisplay]="'input'"  dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startUpExemptionStartDate"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha baja exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startUpExemptionEndDate"/>
                            </div>
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="turnoverExemption" binary="true" inputId="turnoverExemption" />
                                <label for="turnoverExemption">Exención por cifra negocios</label>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha inicio exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="turnoverExemptionStartDate"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha baja exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="turnoverExemptionEndDate"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Clase cuota</span>
                                <p-dropdown [options]=" clientConstants.QUOTE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actividad" formControlName="quoteType"/>
                            </div>
                        </div>
                        
                    <div class="column is-12">
                        <h4>Elementos</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <span class="label">Nº Obreros</span>
                                <input class="search-input" type="text" pInputText formControlName="workers"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Potencia (KW)</span>
                                <input class="search-input" type="text" pInputText formControlName="power"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Nº Vehículos</span>
                                <input class="search-input" type="text" pInputText formControlName="vehicles"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Nº Máquinas</span>
                                <input class="search-input" type="text" pInputText formControlName="machines"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Tipo de maquinaria</span>
                                <input class="search-input" type="text" pInputText formControlName="machinesType"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Escaparates</span>
                                <input class="search-input" type="text" pInputText formControlName="showCases"/>
                            </div>
                        </div>
                        
                    </div>
                    <div class="column is-12">
                        <h4>Superfície</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <span class="label">M2 totales construidos</span>
                                <input class="search-input" type="text" pInputText formControlName="totalBuilt"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 totales útiles</span>
                                <input class="search-input" type="text" pInputText formControlName="totalUseful"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 sin reducción</span>
                                <input class="search-input" type="text" pInputText formControlName="m2WithoutReduction"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 descubiertos</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Uncovered"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 enseñanza</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Teaching"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 almacén</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Store"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 aparcamiento cubierto</span>
                                <input class="search-input" type="text" pInputText formControlName="m2CoveredParking"/>
                            </div>
                        </div>
                        
                    </div>
                    <div class="columns">
                        <div class="column is-6 form-container">
                            <span class="label">Fecha variación actividad</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="activityVariationDate"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Motivo variación </span>
                            <input class="search-input" type="text" pInputText formControlName="activityVariationReason"/>
                        </div>

                    </div>

                </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de baja</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                    </div>
                </div>

            </p-accordionTab>
        }
    </p-accordion>
</div>