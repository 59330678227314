
    <div>
        <h3>Declaraciones contratadas</h3>
        <div class="form-container">
            <span class="label">Departamento:</span>
            <p-dropdown
            [options]="clientConstants.DEPARTMENTS_TYPES"
            optionLabel="label"
            optionValue="value"
            [(ngModel)]="department"
            placeholder="Seleccionar tipo de formato" 
            (onChange)="departmentSelected($event)"
            class="search-input"
            />
            
        </div>
    </div>
@if (configTable.data.length > 0) {
    <div class="card">
        <app-clients-table [configTable]="configTable"></app-clients-table>
    </div>
}@else {
    <div [ngStyle]="{'margin':'20px'}">
        <span> No hay declaraciones contratadas.</span>
    </div>
}