<aside class="menu" [ngClass]="{'is-active': isMenuOpen}">
    @for (menuItem of itemsMenu; track menuItem.name) {
      <div class="section-links">
        <div class="section-container">
          @for (box of menuItem.boxes; track box.name) {
            <div>
              <div class="option-boxes">
                <div class="option-box" [routerLink]="box.routerLink">
                  <fa-duotone-icon class="box-icon" [icon]="box.icon"></fa-duotone-icon>
                  <span class="box-title" [ngClass]="{'is-active-link': isActiveLink(box)}">{{ box.nameLong ? box.nameLong : box.name }}</span>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    }
</aside>
  