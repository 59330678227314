import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-contract-actions-modal',
  standalone: true,
  imports: [],
  templateUrl: './contract-actions-modal.component.html',
  styleUrl: './contract-actions-modal.component.scss'
})
export class ContractActionsModalComponent {
  @Input() i: number;
  @Input() isReopen: boolean;
  @Output() cancelEvent = new EventEmitter<void>();
  @Output() confirmEvent = new EventEmitter<any>();

  constructor() {
    
   }

  ngOnInit() {

  }

  onCloseModal() {
    this.cancelEvent.emit();
  }

  onConfirm() {
    this.confirmEvent.emit([this.i, this.isReopen]);
  }
}
