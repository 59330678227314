export enum PresentationTypeEnum {
  DomiciledPDF = 'Domiciliada - PDF',
  InformativePDF = 'Informativa - PDF',
  SacichNRCPDF = 'Sacich NRC - PDF',
  ClientPresentationPDF = 'Presentación cliente - PDF',
  ClientPresentationDAT = 'Presentación cliente - DAT',
  ToCompensatePDF = 'A compensar - PDF',
  RefundPDF = 'Devolución - PDF',
  FullDeferralPDF = 'Aplazamiento total - PDF',
  PartialDeferralPDF = 'Aplazamiento parcial - PDF'
}