
    <div class="header-container">
        <div class="header">
            <div class="columns is-multiline">
                <div class="column is-12">
                    <h1 class="title">
                        Maestro de Clientes
                    </h1>
                </div>
                <div class="form-container column">
                    <span class="label">Selecionar cliente:</span>
                    <p-dropdown
                    class="search-input"
                    [options]="clients" 
                    [(ngModel)]="selectedClient" 
                    optionLabel="name"
                    [filter]="true" 
                    filterBy="name" 
                    placeholder="Seleccionar cliente"
                    (onChange)="onClientSelected($event)"/>
            
                </div>
                <div class="form-container column">
                    <span class="label">Seleccionar fecha:</span>
                    <p-calendar 
                    [(ngModel)]="selectedDate" 
                    [iconDisplay]="'input'" 
                    dateFormat="dd/mm/yy"  
                    dataType="date" 
                    [showIcon]="true" 
                    inputId="icondisplay"
                    showClear="true"
                    class="search-input"
                    (onSelect)="onDateSelected($event)"
                    (onClear)="onDateSelected($event)"/>
                </div>
            </div>
            
        </div>
        
        
        @if(this.selectedClient) {
            <div class="tabs is-fullwidth is-primary">
                <ul>
                    @for (tab of tabs; track tab.title) {
                    <li (click)="changeTab(tab)" class="is-active" [ngClass]="{'is-active' : tab.isActive}"><a>{{tab.title}}</a></li>
                    }
                </ul>
            </div>
        }
        
    </div>
    @if(selectedClient) {
        @if(!isLoading) {
            <div class="content-page">
                @if(tabs[0].isActive) {
                    <app-general-data [data]="clientData"></app-general-data>
                } @else if (tabs[1].isActive) {
                    <app-fiscal-data [data]="clientData"></app-fiscal-data>
                } @else if (tabs[2].isActive) {
                    <app-labor-data [data]="clientData"></app-labor-data>
                } @else if (tabs[3].isActive) {
                    <app-mercantil-data [data]="clientData"></app-mercantil-data>
                } @else if (tabs[4].isActive) {
                    <app-intern-data [data]="clientData"></app-intern-data>
                } @else if (tabs[5].isActive) {
                    <app-declarations-data [data]="clientData"></app-declarations-data>
                }
            </div>
        }@else {
            <div class="skeleton-block" [ngStyle]="{'margin':'30px'}">
            </div>
            <div class="skeleton-block" [ngStyle]="{'margin':'30px'}">
            </div>
            <div class="skeleton-block" [ngStyle]="{'margin':'30px'}">
            </div>
            <div class="skeleton-block" [ngStyle]="{'margin':'30px'}">
            </div>
            <div class="skeleton-block" [ngStyle]="{'margin':'30px'}">
            </div>
        }
}
