import { Component } from '@angular/core';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { ClientsTableComponent } from '../../../components/clients-table/clients-table.component';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-clients-list',
  standalone: true,
  imports: [ClientsTableComponent, FontAwesomeModule, PaginationComponent, ProgressSpinnerModule],
  templateUrl: './clients-list.component.html',
  styleUrl: './clients-list.component.scss'
})
export class ClientsListComponent {

  faUserPlus = faUserPlus;

  headers = [ 
    {
      name: 'Código Cliente',
      key: 'code'
    },
    {
      name: 'Grupo Cliente',
      key: 'codeApplication'
    },
    {
      name: 'Cliente',
      key: 'name'
    },
    // {
    //   name: 'Area',
    //   key: 'area'
    // },
    {
      name: 'Responsable interno',
      key: 'responsible'
    },
    // {
    //   name: 'Persona de contacto',
    //   key: 'contact'
    // },
    // {
    //   name: 'Facturación',
    //   key: 'billing'
    // },
    {
      name: 'Estado',
      key: 'state'
    }];

    configTable = {
      headers: this.headers,
      data: undefined
    }

    currentPage = 0;
    itemsForPage = 10;
    maxPages = undefined;
  isLoading: boolean;

    constructor (
      private clientsService: ClientsService,
      private router: Router
    ) { }

    async ngOnInit() { 
      await this.loadPage();
    }
    
    async loadPage() {
      await this.getData();
    }

    async getData() {
      this.isLoading = true;
      let data =  await this.clientsService.getClients(this.currentPage, this.itemsForPage);
      this.maxPages = data.totalPages+1;
      data.clients.forEach(client => {
        client.state = client.endDate ? 'INACTIVE' : 'ACTIVE';
        client.responsible = client.responsible ? client.responsible.name : 'No asignado';
      });
      this.configTable = {...this.configTable, headers: this.headers, data: data.clients};
      this.isLoading = false;
    }

    createClient() {
      this.router.navigate(['/clients/detail/new']);
    }

}
