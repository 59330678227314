import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { StatusDeclarationEnum } from '../../core/enums/status-declaration.enum';
import { PresentationTypeEnum } from '../../core/enums/presentation-type.enum';
import { FiltersService } from '../../core/services/declarations/filters.service';

@Component({
  selector: 'app-log-table',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule],
  templateUrl: './log-table.component.html',
  styleUrl: './log-table.component.scss'
})
export class LogTableComponent {

  @Input() configTable: any;
  users: any;

  faCircle = faCircle;

  PresentationTypeEnum = PresentationTypeEnum;

  constructor(
    private filtersService: FiltersService
  ) { }

  async ngOnInit() { 
    this.users = await this.filtersService.getUsers();
  }

  ngOnChanges() {
  }

  getUserName(userId) {
    return this.users.find(user => user.id === userId).name;
  }

}
