import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from "@angular/common/http";
import { catchError, tap, throwError } from "rxjs";
import { inject } from "@angular/core";
import { AuthService } from "../auth.service";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const _authService = inject(AuthService);

    if (!req.url.includes('auth')) {
        const userData: any = localStorage.getItem('user');
        const accessToken = JSON.parse(userData)?.accessToken;

        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`
            }
        })
    }

    return next(req).pipe(catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          _authService.logout();
        }

        return throwError(() => error);
    }));
}
