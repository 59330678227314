<div class="form-title-container">
    <h3 class="subtitle">Datos generales</h3>
</div>
<div class="columns is-multiline " [formGroup]="identificationForm">

    <div class="column is-6 form-container">
        <span class="label">Código Cliente</span>
        <p-inputNumber [readonly]="true" [useGrouping]="false" class="search-input" formControlName="code"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Grupo Cliente</span>
        <input class="search-input" type="text" pInputText formControlName="group" [readonly]="true"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Nombre / Razón social</span>
        <input class="search-input" type="text" pInputText formControlName="name" [readonly]="true"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Tipo de cliente</span>
        <p-dropdown [readonly]="true" [options]="clientConstants.CLIENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de cliente" formControlName="type"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Tipo documento</span>
        <p-dropdown [readonly]="true" [options]="clientConstants.DOCUMENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de documento" formControlName="documentType"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Número de documento identidad</span>
        <input class="search-input" type="text" pInputText formControlName="documentNumber" [readonly]="true"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Fecha de nacimiento / Constitución</span>
        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="constitutionDate" [readonlyInput]="true" showOnFocus="false"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Responsable Interno</span>
        <p-dropdown [options]="responsibleUsers" optionLabel="name" optionValue="id" placeholder="Seleccionar responsable" formControlName="responsible"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Fecha de alta</span>
        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate" [readonlyInput]="true" showOnFocus="false"/>
    </div>
    <div class="column is-6 form-container">
        <span class="label">Fecha de baja</span>
        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate" [readonlyInput]="true" showOnFocus="false"/>
    </div>
</div>

<div [formGroup]="contactsForm">
    <div class="form-title-container">
        <h3 class="subtitle">Datos de contacto</h3>
    </div>
    <p-accordion [multiple]="true" formArrayName="contacts">
        @for (contact of contacts.controls; let i = $index; track contact) {
            <p-accordionTab header="{{contacts.at(i).value.name ? contacts.at(i).value.name : 'Nuevo contacto' }}" [formGroupName]="i" class="">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <span class="label">DNI</span>
                        <input class="search-input" type="text" pInputText formControlName="dni" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Cargo en la empresa</span>
                        <input class="search-input" type="text" pInputText formControlName="position" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Departamento</span>
                        <input class="search-input" type="text" pInputText formControlName="department" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Mail 1</span>
                        <input class="search-input" type="text" pInputText formControlName="mail1" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Mail 2</span>
                        <input class="search-input" type="text" pInputText formControlName="mail2" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Idioma de contacto</span>
                        <input class="search-input" type="text" pInputText formControlName="language" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Teléfono principal</span>
                        <input class="search-input" type="text" pInputText formControlName="phone" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Teléfono móvil</span>
                        <input class="search-input" type="text" pInputText formControlName="mobilePhone" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <p-checkbox [readonly]="true" formControlName="accessUser" binary="true" inputId="accessUser" />
                        <label for="accessUser">Usuario con acceso</label>
                    </div>
                </div>
            </p-accordionTab>
        } @empty {
            <span>No hay personas de contacto</span>
        }
    </p-accordion>
</div>

<div [formGroup]="addressesForm">
    <div class="form-title-container">
        <h3 class="subtitle">Domicilios</h3>
    </div>
    <p-accordion [multiple]="true" formArrayName="addresses">
        @for (address of addresses.controls; let i = $index; track address) {
            <p-accordionTab header="{{addresses.at(i).value.street ? addresses.at(i).value.street + ', ' + addresses.at(i).value.number : 'Nuevo domicilio ' }}" [formGroupName]="i">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <span class="label">Tipo de domicilio</span>
                        <input class="search-input" type="text" pInputText formControlName="type" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de alta</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate" [readonlyInput]="true" showOnFocus="false"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Siglas</span>
                        <input class="search-input" type="text" pInputText formControlName="acronym" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Vía pública</span>
                        <input class="search-input" type="text" pInputText formControlName="street" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Número de vía</span>
                        <input class="search-input" type="text" pInputText formControlName="number" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Escalera</span>
                        <input class="search-input" type="text" pInputText formControlName="stairs" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Piso</span>
                        <input class="search-input" type="text" pInputText formControlName="floor" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Puerta</span>
                        <input class="search-input" type="text" pInputText formControlName="door" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Municipio</span>
                        <input class="search-input" type="text" pInputText formControlName="town" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">CP</span>
                        <input class="search-input" type="text" pInputText formControlName="postalCode" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Provincia</span>
                        <input class="search-input" type="text" pInputText formControlName="province" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código país</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCountry" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">País</span>
                        <input class="search-input" type="text" pInputText formControlName="country" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Referencia catastral</span>
                        <input class="search-input" type="text" pInputText formControlName="cadastralReference" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de baja</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate" [readonlyInput]="true" showOnFocus="false"/>
                    </div>
                </div>
            </p-accordionTab>
        } @empty {
            <span>No hay domicilios</span>
        }
    </p-accordion>
</div>
<div [formGroup]="activitiesForm">
    <div class="form-title-container">
        <h3 class="subtitle">Actividades</h3>
    </div>
    <p-accordion [multiple]="true" formArrayName="activities">
        @for (activity of activities.controls; let i = $index; track activity) {
            <p-accordionTab header="{{activities.at(i).value.acronym ? activities.at(i).value.acronym : 'Nueva actividad' }}" [formGroupName]="i">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <p-checkbox formControlName="principal" binary="true" inputId="principal" [readonly]="true" />
                        <label for="principal">Actividad principal</label>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de alta</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate" [readonlyInput]="true" showOnFocus="false"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Siglas</span>
                        <input class="search-input" type="text" pInputText formControlName="acronym" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Vía pública</span>
                        <input class="search-input" type="text"  pInputText formControlName="street" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Número de vía</span>
                        <input class="search-input" type="text" pInputText formControlName="number" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Escalera</span>
                        <input class="search-input" type="text" pInputText formControlName="stairs" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Piso</span>
                        <input class="search-input" type="text" pInputText formControlName="floor" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Puerta</span>
                        <input class="search-input" type="text" pInputText formControlName="door" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Municipio</span>
                        <input class="search-input" type="text" pInputText formControlName="town" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">CP</span>
                        <input class="search-input" type="text" pInputText formControlName="postalCode" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Provincia</span>
                        <input class="search-input" type="text" pInputText formControlName="province" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código país</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCountry" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">País</span>
                        <input class="search-input" type="text" pInputText formControlName="country" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Referencia catastral</span>
                        <input class="search-input" type="text" pInputText formControlName="cadastralReference" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código CNAE</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCNAE" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código IAE</span>
                        <input class="search-input" type="text" pInputText formControlName="codeIAE" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Tipo de actividad</span>
                        <p-dropdown [options]=" clientConstants.ACTIVITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actividad" formControlName="type" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Centro de trabajo</span>
                        <p-dropdown [options]="" optionLabel="label" placeholder="Seleccionar centro de trabajo" formControlName="workCenter" [readonly]="true"/>
                    </div>
                    <div class="column is-12">
                        <h4>Parámetros IAE</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="exemptionIAE" binary="true" inputId="exemptionIAE" [readonly]="true" />
                                <label for="exemptionIAE">Exención IAE</label>
                            </div>
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="startUpExemption" binary="true" inputId="startUpExemption" [readonly]="true"/>
                                <label for="startUpExemption">Exención por inicio</label>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha inicio exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startUpExemptionStartDate" [readonlyInput]="true" showOnFocus="false"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha baja exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startUpExemptionEndDate" [readonlyInput]="true" showOnFocus="false"/>
                            </div>
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="turnoverExemption" binary="true" inputId="turnoverExemption" [readonly]="true"/>
                                <label for="turnoverExemption">Exención por cifra negocios</label>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha inicio exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="turnoverExemptionStartDate" [readonlyInput]="true" showOnFocus="false" />
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha baja exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="turnoverExemptionEndDate" [readonlyInput]="true" showOnFocus="false"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Clase cuota</span>
                                <p-dropdown [options]="clientConstants.QUOTE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actividad" formControlName="quoteType" [readonly]="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <h4>Elementos</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <span class="label">Nº Obreros</span>
                                <input class="search-input" type="text" pInputText formControlName="workers" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Potencia (KW)</span>
                                <input class="search-input" type="text" pInputText formControlName="power" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Nº Vehículos</span>
                                <input class="search-input" type="text" pInputText formControlName="vehicles" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Nº Máquinas</span>
                                <input class="search-input" type="text" pInputText formControlName="machines" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Tipo de maquinaria</span>
                                <input class="search-input" type="text" pInputText formControlName="machinesType" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Escaparates</span>
                                <input class="search-input" type="text" pInputText formControlName="showCases" [readonly]="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <h4>Superfície</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <span class="label">M2 totales construidos</span>
                                <input class="search-input" type="text" pInputText formControlName="totalBuilt" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 totales útiles</span>
                                <input class="search-input" type="text" pInputText formControlName="totalUseful" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 sin reducción</span>
                                <input class="search-input" type="text" pInputText formControlName="m2WithoutReduction" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 descubiertos</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Uncovered" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 enseñanza</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Teaching" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 almacén</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Store" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 aparcamiento cubierto</span>
                                <input class="search-input" type="text" pInputText formControlName="m2CoveredParking" [readonly]="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6 form-container">
                            <span class="label">Fecha variación actividad</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="activityVariationDate" [readonlyInput]="true" showOnFocus="false"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Motivo variación </span>
                            <input class="search-input" type="text" pInputText formControlName="activityVariationReason" [readonly]="true"/>
                        </div>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de baja</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate" [readonlyInput]="true" showOnFocus="false"/>
                    </div>
                </div>
            </p-accordionTab>
        } @empty {
            <span>No hay actividades</span>
        }
    </p-accordion>
</div>
