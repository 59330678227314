import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsTableComponent } from '../../clients-table/clients-table.component';
import { ClientsService } from '../../../core/services/clients/clients.service';

@Component({
  selector: 'app-declarations-data',
  standalone: true,
  imports: [CommonModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, FormsModule, ReactiveFormsModule, InputNumberModule, AccordionModule, ClientsTableComponent],
  templateUrl: './declarations-data.component.html',
  styleUrl: './declarations-data.component.scss'
})
export class DeclarationsDataComponent {

  @Input() data: any;
  public clientConstants = ClientConstants;

  department = 'ALL';

  headers = [ 
    {
      name: 'Modelo',
      key: 'model'
    },
    {
      name: 'Departamento',
      key: 'department'
    },
    {
      name: 'Fecha alta',
      key: 'startDate'
    },
    {
      name: 'Fecha baja',
      key: 'endDate'
    }];

  configTable = {
    headers: this.headers,
    data: undefined
  }

  constructor(
  ) { }
  
  async ngOnInit() {
      await this.getData(this.department);
  }

  async getData(department) {
    let declarations = []
    this.data.declarations.filter(declaration => {
      if (department !== 'ALL') {
        if (declaration.model.department === department) {
          declarations.push(declaration);
        }
      }
      else {
        declarations.push(declaration);
      }
    });
    this.configTable.data = declarations;
  }

  departmentSelected(event) {
    this.department = event.value;
    this.getData(this.department);
    }
}
