<header class="header">
      <div [ngStyle]="{'padding-right': '0'}">
        <a class="navbar-item" (click)="toggleMenu()">
          <fa-icon class="menu-icon" [icon]="faBars"></fa-icon>
        </a>
      </div>
      <div class="image-container" [routerLink]="['/home']" >
          <img src="./assets/images/logosacich.png" alt="Sacich Logo" class="logo">        
      </div>
      <div class="title-container">
        <h2 class="title">
          {{ headerTitle }}
        </h2>
      </div>
    <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item user-name class">
            <p-menu class="logout-menu" #menu [model]="items" appendTo="body" [popup]="true" />
            <div class="columns is-multiline user-text" (click)="menu.toggle($event)">
                <span class="column is-12 user-text">{{ authService.getUser()?.user.name }}</span>
                <span class="column is-12 dept-text">{{ department }}</span>

            </div>
          </div>
        </div>
      </div>
</header>
