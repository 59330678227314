<div class="form-title-container">
    <h3>Datos societarios</h3>
</div>
<div [formGroup]="societyDataForm">
    <div>
        <h4>Identificación</h4>
    </div>
    <div class="columns is-multiline">
        <div class="column is-6 form-container">
            <span class="label">Tipo empresa</span>
            <p-dropdown [options]="clientConstants.COMPANY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de empresa" formControlName="companyType"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="isBigCompany" binary="true" inputId="isBigCompany" />
            <label for="isBigCompany">Gran empresa</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha alta</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha baja</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha alta obligación IS</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDateISOObligation"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha cierre ejercicio social</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="socialExerciseClosingDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Opción pago fraccionado</span>
            <p-dropdown [options]=" clientConstants.FRACTIONATED_PAYMENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de cliente" formControlName="fractionatedPayment"/>
        </div>
    </div>
    <div>
        <h4>Domicilio social</h4>
    </div>
    <div class="columns is-multiline ">
        <div class="column is-6 form-container">
            <span class="label">Fecha alta</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="socialDomicileStartDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Siglas</span>
            <input class="search-input" type="text" pInputText formControlName="acronym"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Vía pública</span>
            <input class="search-input" type="text" pInputText formControlName="street"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número de vía</span>
            <input class="search-input" type="text" pInputText formControlName="number"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Escalera</span>
            <input class="search-input" type="text" pInputText formControlName="stairs"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Piso</span>
            <input class="search-input" type="text" pInputText formControlName="floor"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Puerta</span>
            <input class="search-input" type="text" pInputText formControlName="door"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Municipio</span>
            <input class="search-input" type="text" pInputText formControlName="town"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">CP</span>
            <input class="search-input" type="text" pInputText formControlName="postalCode"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Provincia</span>
            <input class="search-input" type="text" pInputText formControlName="province"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Código país</span>
            <input class="search-input" type="text" pInputText formControlName="codeCountry"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">País</span>
            <input class="search-input" type="text" pInputText formControlName="country"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Referencia catastral</span>
            <input class="search-input" type="text" pInputText formControlName="cadastralReference"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha baja</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="socialDomicileEndDate"/>
        </div>
    </div>
</div>
    <div>
        <h4 class="operation-title">Operaciones</h4>
    </div>
    <div>
        <p-accordion [multiple]="true" >
                <p-accordionTab header="Constitución" [formGroup]="constitutionForm" >
                    <div formArrayName="constitutions">
                        @for (c of constitution.controls; track c; let i = $index) {
                            <div [formGroupName]="i">
                                <div>
                                    <h4>Constitución</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="documentDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Objeto social" [formGroup]="socialObjectForm">
                    <div formArrayName="socialObjects">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addSocialObject()">Añadir</a>
                        </div>
                        @for (object of socialObject.controls; track object; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Objeto social {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="documentDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Copia objeto social</span>
                                        <input class="search-input" type="text" pInputText formControlName="socialObject"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">CNAE Actividad Principal</span>
                                        <input class="search-input" type="text" pInputText formControlName="principalActivityCNAE"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Detalle actividad</span>
                                        <input class="search-input" type="text" pInputText formControlName="activityDetail"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Epígrafe IAE actividad principal</span>
                                        <input class="search-input" type="text" pInputText formControlName="principalActivityEpigraphIAE"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha alta actividad</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha baja actividad</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Capital social" [formGroup]="socialCapitalForm">
                    <div formArrayName="socialCapitals">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addSocialCapital()">Añadir</a>
                        </div>
                        @for (capital of socialCapital.controls; track capital; let i = $index) {
                            <div [formGroupName]="i" >
                                <div class="operation-title">
                                    <h4>Capital social {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Tipo / Serie</span>
                                        <input class="search-input" type="text" pInputText formControlName="type"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Número de acciones total</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalActionsNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Valor nominal por acción</span>
                                        <input class="search-input" type="text" pInputText formControlName="nominalValueByAction"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Capital social total</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalSocialCapital"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Numeración acciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumeration"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Prima emisión</span>
                                        <input class="search-input" type="text" pInputText formControlName="bonusEmission"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Observaciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="observations"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Ampliación capital" [formGroup]="capitalIncreaseForm">
                    <div formArrayName="capitalIncreases">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addCapitalIncrease()">Añadir</a>
                        </div>
                        @for (increase of capitalIncrease.controls; track increase; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Ampliación capital {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha ampliación</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="increaseDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tipo de ampliación</span>
                                        <input class="search-input" type="text" pInputText formControlName="increaseType"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Observaciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="observations"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Reducción capital" [formGroup]="capitalReductionForm" >
                    <div formArrayName="capitalReductions">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addCapitalReduction()">Añadir</a>
                        </div>
                        @for (reduction of capitalReduction.controls; track reduction; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Reducción capital {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha reducción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="reductionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tipo de reducción</span>
                                        <input class="search-input" type="text" pInputText formControlName="reductionType"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Observaciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="observations"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Compra-Venta" [formGroup]="buySellForm">
                    <div formArrayName="buySells">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addBuySell()">Añadir</a>
                        </div>
                        @for (bs of buySell.controls; track bs; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Compra-Venta {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha Escritura</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="documentDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha Acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha Contrato</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="contractDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre Apellidos / Razón social comprador</span>
                                        <input class="search-input" type="text" pInputText formControlName="buyerName"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Número Acc/Part adquiridas</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nominal total adquirido</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalNominal"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Numeración acciones adquiridas</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumeration"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Prima emisión</span>
                                        <input class="search-input" type="text" pInputText formControlName="emissionPrime"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Precio de la compraventa</span>
                                        <input class="search-input" type="text" pInputText formControlName="buySellPrice"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre Apellidos / Razón social vendedor</span>
                                        <input class="search-input" type="text" pInputText formControlName="sellerName"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Accionistas / socios" [formGroup]="shareholdersPartnersForm">
                    <div  formArrayName="shareholdersPartners">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addShareholdersPartners()">Añadir</a>
                        </div>
                        @for (bs of shareholdersPartners.controls; track bs; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Accionista / socio {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre</span>
                                        <input class="search-input" type="text" pInputText formControlName="name"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF</span>
                                        <input class="search-input" type="text" pInputText formControlName="nif"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="resident" binary="true" inputId="resident" />
                                        <label for="resident">Residente</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Domicilio</span>
                                        <input class="search-input" type="text" pInputText formControlName="domicile"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Código Postal</span>
                                        <input class="search-input" type="text" pInputText formControlName="postalCode"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Municipio</span>
                                        <input class="search-input" type="text" pInputText formControlName="town"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia</span>
                                        <input class="search-input" type="text" pInputText formControlName="province"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha alta</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeName"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeNif"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Número acciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Numeración acciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumeration"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Porcentaje participación</span>
                                        <input class="search-input" type="text" pInputText formControlName="percentage"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Control directo</span>
                                        <input class="search-input" type="text" pInputText formControlName="directControl"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Control indirecto</span>
                                        <input class="search-input" type="text" pInputText formControlName="indirectControl"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="familiar" binary="true" inputId="familiar" />
                                        <label for="familiar">Socio familiar (Conviviente)</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="worker" binary="true" inputId="worker" />
                                        <label for="worker">Socio trabajador</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="administrationMember" binary="true" inputId="administrationMember" />
                                        <label for="administrationMember">Miembro órgano administración</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Régimen de la seguridad social</span>
                                        <input class="search-input" type="text" pInputText formControlName="socialSecurityRegime"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Encuadramiento</span>
                                        <p-dropdown [options]="clientConstants.FRAMING_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="framing"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Autónomos a cargo de</span>
                                        <p-dropdown [options]="clientConstants.AUTONOMOUS_IN_CHARGE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="autonomousInChargeOf"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">IBAN (En seguridad social)</span>
                                        <input class="search-input" type="text" pInputText formControlName="iban"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Rendimiento neto mensual</span>
                                        <input class="search-input" type="text" pInputText formControlName="monthlyNetReturn"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Mutua ACC. Trabajo / EP</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaACC"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Mutua ACC. / IT</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaCC"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Valor nominal total</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalNominalValue"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="generalMeetingPresident" binary="true" inputId="generalMeetingPresident" />
                                        <label for="generalMeetingPresident">Presidente junta general</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="generalMeetingSecretary" binary="true" inputId="generalMeetingSecretary" />
                                        <label for="generalMeetingSecretary">Secretario junta general</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha baja</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                                    </div>
                                </div>    
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Órgano administración" [formGroup]="administrationForm" >
                    <div formArrayName="administrations">
                        <div class="form-title-container">
                            <a class="button add-button" (click)="addAdministration()">Añadir</a>
                        </div>
                        @for (bs of administration.controls; track bs; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Órgano administración {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Sistema de administración</span>
                                        <p-dropdown [options]="clientConstants.ADMINISTRATION_SYSTEMS_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="administrationSystem"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre</span>
                                        <input class="search-input" type="text" pInputText formControlName="name"/>
                                    </div>
    
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF</span>
                                        <input class="search-input" type="text" pInputText formControlName="nif"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Domicilio</span>
                                        <input class="search-input" type="text" pInputText formControlName="address"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Código Postal</span>
                                        <input class="search-input" type="text" pInputText formControlName="postalCode"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Municipio</span>
                                        <input class="search-input" type="text" pInputText formControlName="town"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia</span>
                                        <input class="search-input" type="text" pInputText formControlName="province"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Cargo</span>
                                        <input class="search-input" type="text" pInputText formControlName="position"/>
                                    </div>
                                    <div class="column is-12">
                                        <h4>Alta cargo</h4>
                                    </div>                                    
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha alta</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="startNotary"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="startProtocol"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDocumentDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="startMercantileRegisterProvince"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDealDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startInscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="startTome"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="startSheet"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="startLeaf"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Sección</span>
                                        <input class="search-input" type="text" pInputText formControlName="startSection"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="startBook"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="startInscriptionNumber"/>
                                    </div>
                                    <div class="column is-12">
                                        <h4>Fin cargo</h4>
                                    </div>  
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha fin</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="endNotary"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="endProtocol"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDocumentDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="endMercantileRegisterProvince"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date"[showIcon]="true" inputId="icondisplay" formControlName="endDealDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endInscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="endTome"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="endSheet"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="endLeaf"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Sección</span>
                                        <input class="search-input" type="text" pInputText formControlName="endSection"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="endBook"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="endInscriptionNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeName"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeNif"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retribution" binary="true" inputId="retribution" />
                                        <label for="retribution">Retribución</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Clave retribución</span>
                                        <p-dropdown [options]="clientConstants.RETRIBUTION_KEYS" optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="retributionKey"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Importe retribución</span>
                                        <input class="search-input" type="text" pInputText formControlName="retributionImport"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retributionInStatutes" binary="true" inputId="retributionInStatutes" />
                                        <label for="retributionInStatutes">Consta en estatutos</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Ejercicio inicio retribución</span>
                                        <input class="search-input" type="text" pInputText formControlName="retributionStartRetributionExercise"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retributionContract" binary="true" inputId="retributionContract" />
                                        <label for="retributionContract">Contrato</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retributionAct" binary="true" inputId="retributionAct" />
                                        <label for="retributionAct">Acta</label>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Régimen de la seguridad social</span>
                                        <input class="search-input" type="text" pInputText formControlName="regime"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Encuadramiento</span>
                                        <p-dropdown [options]="clientConstants.FRAMING_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="framing"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Autónomos a cargo de</span>
                                        <p-dropdown [options]="clientConstants.AUTONOMOUS_IN_CHARGE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="autonomousInChargeOf"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">IBAN (En seguridad social)</span>
                                        <input class="search-input" type="text" pInputText formControlName="iban"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Rendimiento neto mensual</span>
                                        <input class="search-input" type="text" pInputText formControlName="monthlyNetReturn"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Mutua ACC. Trabajo / EP</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaACC"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Mutua ACC. / IT</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaCC"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="personalDigitalCertificate" binary="true" inputId="personalDigitalCertificate" />
                                        <label for="personalDigitalCertificate">Certificado digital personal</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha caducidad certificado</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="personalDigitalCertificateCaducityDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsCCAA" binary="true" inputId="signsCCAA" />
                                        <label for="signsCCAA">Firma las CCAA</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="certifiesCCAA" binary="true" inputId="certifiesCCAA" />
                                        <label for="certifiesCCAA">Certifica las CCAA</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsIS" binary="true" inputId="signsIS" />
                                        <label for="signsIS">Firma el IS</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="certifiesIS" binary="true" inputId="certifiesIS" />
                                        <label for="certifiesIS">Certifica el IS</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsLaborContracts" binary="true" inputId="signsLaborContracts" />
                                        <label for="signsLaborContracts">Firma contratos laborales</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsLaborDocuments" binary="true" inputId="signsLaborDocuments" />
                                        <label for="signsLaborDocuments">Firma documentos laborales</label>
                                    </div>
    
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Libros" [formGroup]="booksForm">
                    <div class="columns is-multiline" >
                        <div class="column is-12">
                            <h5>Libro Actas</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="actsBookOrderNumber"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="actsBookDate"/>
                        </div>
                        <div class="column is-12">
                            <h5>Libro Socios</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="partnersBookOrderNumber"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="partnersBookDate"/>
                        </div>
                        <div class="column is-12">
                            <h5>Libro INV/CCAA</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="invCCABookOrderNumber"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="invCCABookDate"/>
                        </div>
                        <div class="column is-12">
                            <h5>Libro Diario</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="diaryBookOrderNumber"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="diaryBookDate"/>
                        </div>
                    </div>
                </p-accordionTab>
        </p-accordion>
    </div>


