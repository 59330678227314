<div class="container-fluid header-container">
    <div class="header">
            <h1 class="title" [routerLink] = "['/declarations']"><fa-icon class="back-icon"  [icon]="faChevronLeft"></fa-icon>Gestión de tareas múltiple</h1>
            <button class="ban-button button">                                
                <fa-icon class="ban-icon" [icon]="faBan"></fa-icon>
                No procede
            </button>
    </div>
</div>
<div class="content-page">
    <div class="container-fluid">
        <div class="text-container">
            <span class="subtitle-text">Has seleccionado las siguientes declaraciones:</span>
        </div>
        @if(data) {
            <div class="card">
                <app-table [configTable]="decsTable"></app-table>
            </div>
        }
        <!-- <div class="columns is-12">
            <div [ngStyle]="{'width': '250px'}">
                <p-dropdown
                    [options]="responsibles" 
                    [(ngModel)]="declaration.responsible.id"
                    optionValue="id"
                    optionLabel="name" 
                    placeholder="Seleccionar responsable" 
                    (onChange)="setResponsible($event)"            
                    />
            </div>
        </div> -->
    </div>
    <div class="columns is-multiline text-container">
        <div class="column is-11">
            <span class="subtitle-text">Selecciona tarea a ejecutar:</span>
        </div>
        <div class="showAll column is-1">
            <button class="subrayado is-pulled-right" (click)="showAllFases()">                                
                {{ allExpanded ? 'Contraer todo' : 'Expandir todo' }}
            </button>

        </div>
    </div> 
    @if (data && data.phases.length > 0){
        <app-declaration-phases [phases]="data.phases" [isMultiple]="data.declarations.length" (commentEvent)="commentAdd($event)" (changesEvent)="handleChanges($event)"></app-declaration-phases>
    }
    @if(data) {
        <div>
            <h2 class="subtitle">Comentarios</h2>
            <form [formGroup]="commentForm" (ngSubmit)="commentAdd()">
                <div class="card new-comment">
                    <span class="user-name">{{ user.name }}</span>
                    <textarea class="textarea" formControlName="comment" placeholder="Escribe aqui tu comentario"></textarea>
                    <hr>
                    <button class="comment-button button is-pulled-right" type="submit" [disabled]="!commentForm.valid">                                
                        Comentar
                    </button>
                </div>
            </form>
        </div> 

    } @else {
        <div class="skeleton-block">

        </div>
    }
</div>

<div class="fab-buttons">
    <button class="button fab-exit" [routerLink]="['/declarations']">
        Salir
    </button>
    @if (data && data.phases && allPhasesSelected() && data.declarations[0].status === "PROGRESS") {
        <button class="button fab-confirm" (click)="finishDeclarations()">
            Finalizar
        </button>
    } @else {
        <button class="button fab-confirm" (click)="sendChanges()">
            Confirmar
        </button>
    }
</div>


