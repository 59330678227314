import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-comment-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './comment-modal.component.html',
  styleUrl: './comment-modal.component.scss'
})
export class CommentModalComponent {
  @Input() isStatus;
  @Input() isReactivate;
  @Input () tasks;
  @Output() closeEvent = new EventEmitter<void>();
  @Output() commentEvent = new EventEmitter<string>();
  user = undefined;
  phases = [];
  isOpen = false;

  commentForm = new FormGroup({
    comment: new FormControl('', Validators.required),
  });
  constructor() {
    
   }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')).user;
    if(!this.isStatus) {
    this.phases = Object.entries(this.groupTasksByPhase(this.tasks));
    }
  }
  onCloseModal() {
    this.closeEvent.emit();
  }

  addComment(): void {
    this.commentEvent.emit(this.commentForm.value.comment);
  }

  groupTasksByPhase(tasks) {
    return tasks.reduce((groupedTasks, task) => {
      const key = task.phase;
      if (!groupedTasks[key]) {
        groupedTasks[key] = [];
      }
      groupedTasks[key].push(task);
      return groupedTasks;
    }, {});
  }
}
