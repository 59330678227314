<div class="container-fluid title-container">
    <div class="columns is-multiline">
        <div class="column is-12">
            <h1 class="title">Modificar archivo</h1>
        </div>
    </div>
</div>
<div class="content-page backgrond">
    <div class="card">
          <p-fileUpload #form
            name="xmlFiles[]" 
            mode="advanced"
            customUpload="true"
            [auto]="false"
            [multiple]="false" 
            (onSelect)="onSelectFile($event)"
            (onUpload)="files = []"
            (onClear)="files = []"
            accept=".xml" 
            maxFileSize="1000000"
            [customUpload]="true"
            >
            <ng-template pTemplate="header" let-files let-chooseCallback="chooseCallback" let-uploadCallback="uploadCallback" >
              <div class="choose-file-container">
                <p-button [ngStyle]="{'margin-right':'10px'}" class="choose-file"(onClick)="choose($event, chooseCallback)" [rounded]="true" [outlined]="true" ><fa-icon [icon]="faFilePlus" class="add-icon"></fa-icon></p-button>
                <p-button class="choose-file"(onClick)="onUploadFile(uploadCallback, form)" [rounded]="true" [disabled]="files.length === 0" [outlined]="true" ><fa-icon [icon]="faFileImport" class="add-icon"></fa-icon></p-button>
                
              </div>
            </ng-template>
            <ng-template pTemplate="empty">
                <div class="upload-area" >
                  <fa-icon class="upload-icon" [icon]="faCloudUpload"></fa-icon>
                  <p>Arrastra y suelta archivos aquí para subirlos</p>
                </div>
              </ng-template>
          </p-fileUpload>
      </div>
      
</div>

