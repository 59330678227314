<div class="form-title-container">
    <h3>Declaraciones contratadas</h3>
    <a class="button add-button" (click)="openFormModal()">Añadir contrato</a>
</div>
@if (configTable.data) {
    <div class="card">
        <app-clients-table [configTable]="configTable" (close)="close($event)" (reopen)="reopen($event)" (openItemEvent)="openFormModal($event)"></app-clients-table>
    </div>
}

<!-- <div [formGroup]="declarationsForm" class="declarations">
    <div class="form-title-container">
        <h3>Contratar nueva declaración</h3>
        <a class="button add-button" (click)="addDeclaration()">Añadir declaración</a>
    </div>
    <p-accordion [multiple]="true" formArrayName="declarations">
        @for (declaration of declarations.controls; let i = $index; track declaration) {
            @if(i >= declarationsNumber) {
                <p-accordionTab header="Nueva Declaración {{i + 1}}" [formGroupName]="i" class="">
                    <div class="columns is-multiline">
                        <div class="column is-6 form-container">
                            <span class="label">Model</span>
                            <p-dropdown
                            [options]="models"                  
                            [group]="true"
                            optionLabel="name"
                            optionValue="id"
                            optionGroupLabel="department"
                            optionGroupChildren="models" 
                            placeholder="Seleccionar modelo" 
                            formControlName="model"
                            (onChange)="availablePresentations($event)"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Responsable modelo</span>
                            <p-dropdown [options]="" optionLabel="label" placeholder="Seleccionar responsable" formControlName="responsible"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Ámbito</span>
                            <p-dropdown [options]="clientConstants.AMBITS" optionLabel="label" placeholder="Seleccionar ámbito" formControlName="ambit"/>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="specialRegime" binary="true" inputId="specialRegime" />
                            <label for="specialRegime">Régimen especial aplicable</label>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Descripción régimen especial</span>
                            <input class="search-input" type="text" pInputText formControlName="specialRegimeDescription"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Periodicidad modelo</span>
                            <p-dropdown [options]="clientConstants.MODEL_PERIODICITY_TYPES" optionLabel="label" placeholder="Seleccionar" formControlName="periodicity"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Tipo presentación</span>
                            <p-dropdown [options]="presentations"  optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="presentationType"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Cuenta bancaria</span>
                            <p-dropdown [options]="bankAccounts" optionLabel="iban" optionValue="id" placeholder="Seleccionar cuenta bancaria" formControlName="bankAccount"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Formato fichero</span>
                            <p-dropdown [options]="clientConstants.FORMAT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="format"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha inicio</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha baja</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                        </div>
                        
                        <div class="column is-6 form-container">
                            <span class="label">Contacto origen</span>
                            <input class="search-input" type="text" pInputText formControlName="contact"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Programas internos donde darlo de alta</span>
                            <p-dropdown [options]="clientConstants.INTERN_PROGRAMS" optionLabel="label" placeholder="Seleccionar" formControlName="internPrograms"/>
                        </div>
                    </div>
                </p-accordionTab>
            }
            
        }
    </p-accordion>
</div> -->
<div class="modal" [class.is-active]="isDecModalActive">
    <div class="modal-background" (click)="isDecModalActive = false"></div>
    <div class="modal-card">
        <form [formGroup]="modalForm" (ngSubmit)="save()" >
      <header class="modal-card-head">
        @if(isCreating) {
            <p class="modal-card-title">Nuevo Contrato</p>
        } @else {
            <p class="modal-card-title">{{modalForm.value.model.name}}</p>
        }
      </header>
      <section class="modal-card-body">
        @if(isCreating) {
            <div class="field">
                <span class="label">Modelo</span>
                <p-dropdown
                [options]="models"                  
                [group]="true"
                optionLabel="name"
                optionGroupLabel="department"
                optionGroupChildren="models" 
                placeholder="Seleccionar modelo" 
                formControlName="model"
                (onChange)="availablePresentations($event)"/>
            </div>
        }
        <div class="field">
            <span class="label">Tipo presentación - fichero</span>
            <p-dropdown [options]="availablePresentationsTypes" placeholder="Seleccionar" formControlName="presentationType"/>
        </div>
        <div class="field">
            <span class="label">Cuenta bancaria</span>
            <p-dropdown [options]="bankAccounts" optionLabel="iban" optionValue="id" placeholder="Seleccionar cuenta bancaria" formControlName="bankAccount"/>
        </div>
          <div class="field">
            <label class="label">Fecha alta</label>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
        </div>
          <div class="field">
            <label class="label">Fecha baja</label>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
        </div>
        </section>
        <footer class="modal-card-foot buttons-container">
            <button class="button cancel-button" (click)="isDecModalActive = false">Cancelar</button>
            <button class="button save-button" type="submit" [disabled]="!modalForm.valid">Guardar</button>
        </footer>
    </form>
    </div>
  </div>
