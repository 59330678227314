<div [formGroup]="addressesForm">
    <div class="form-title-container">
        <h3>Domicilios</h3>
        <a class="button add-button" (click)="addAddress()">Añadir domicilio</a>
    </div>
    <p-accordion [multiple]="true"  formArrayName="addresses">
        @for (address of addresses.controls; let i = $index; track address) {
            <p-accordionTab header="{{addresses.at(i).value.street ? addresses.at(i).value.street + ', ' + addresses.at(i).value.number : 'Nuevo domicilio ' }}" [formGroupName]="i">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <span class="label">Tipo de domicilio</span>
                        <p-dropdown [options]=" clientConstants.DOMICILES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de domicilio" formControlName="type"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de alta</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy"  dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Siglas</span>
                        <input class="search-input" type="text" pInputText formControlName="acronym"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Vía pública</span>
                        <input class="search-input" type="text" pInputText formControlName="street"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Número de vía</span>
                        <input class="search-input" type="text" pInputText formControlName="number"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Escalera</span>
                        <input class="search-input" type="text" pInputText formControlName="stairs"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Piso</span>
                        <input class="search-input" type="text" pInputText formControlName="floor"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Puerta</span>
                        <input class="search-input" type="text" pInputText formControlName="door"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Municipio</span>
                        <input class="search-input" type="text" pInputText formControlName="town"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">CP</span>
                        <input class="search-input" type="text" pInputText formControlName="postalCode"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Provincia</span>
                        <input class="search-input" type="text" pInputText formControlName="province"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código país</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCountry"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">País</span>
                        <input class="search-input" type="text" pInputText formControlName="country"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Referencia catastral</span>
                        <input class="search-input" type="text" pInputText formControlName="cadastralReference"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de baja</span>
                        <p-calendar [iconDisplay]="'input'"  dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                    </div>
                </div>
            </p-accordionTab>
        }
    </p-accordion>
</div>
