import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http: HttpClient,
  ) { }

  async getClients(page, items) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients?page=${page}&items=${items}`));
  }

  async getClientsNames() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients/list-names`));
  }

  async getClientById(id){
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients/${id}`));
  }

  async getClientIdentification(id) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients/${id}`));
  }

  async saveClientIdentification(client) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients`, client));
  }

  async getClientContacts(id) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-contacts/${id}`));
  }

  async saveClientContact(contact) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-contacts`, contact));
  }

  async getClientAddresses(id, date?) {
    const dateString = date ? `?date=${date}` : '';
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-addresses/${id}${dateString}`));
  }

  async saveClientAddress(address) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-addresses`, address));
  }

  async getClientActivities(id, date?) {
    const dateString = date ? `?date=${date}` : '';
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-activities/${id}${dateString}`));
  }

  async saveClientActivity(activity) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-activities`, activity));
  }

  async getClientLabor(id) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-labors/${id}`));
  }

  async saveClientLabor(labor) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-labors`, labor));
  }

  async getClientSociety(id, date?) {
    const dateString = date ? `?date=${date}` : '';
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-societies/${id}${dateString}`));
  }

  async saveClientSociety(society) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-societies`, society));
  }

  async getClientInvoicing(id) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-invoicings/${id}`));
  }

  async saveClientInvoicing(invoicing) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-invoicings`, invoicing));
  }

  async getClientOtherData(id) {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-other/${id}`));
  }

  async saveClientOtherData(otherData) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-other`, otherData));
  }

  async getClientBankAccounts(id, date?) {
    const dateString = date ? `?date=${date}` : '';
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-bank-accounts/${id}${dateString}`));
  }

  async saveClientBankAccount(bankAccount) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-bank-accounts`, bankAccount));
  }

  async getClientWorkCenters(id, date?) {
    const dateString = date ? `?date=${date}` : '';
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients-work-centers/${id}${dateString}`));
  }

  async saveClientWorkCenter(workCenter) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-work-centers`, workCenter));
  }
  
  async uploadCompanyLogo(file, id) {
    const formData = new FormData();
    formData.append('file', file);
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-labors/${id}/company-logo`, formData));
  }

  async uploadCompanySignature(file, id) {
    const formData = new FormData();
    formData.append('file', file);
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-labors/${id}/company-signature`, formData));
  }

  async saveClientConstitution(constitution) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-constitutions`, constitution));
  }

  async saveClientSocialObject(socialObject) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-social-objects`, socialObject));
  }

  async saveClientSocialCapital(capital) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-social-capitals`, capital));
  }

  async saveClientCapitalIncrease(capitalIncrease) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-capital-increases`, capitalIncrease));
  }

  async saveClientCapitalReduction(capitalReduction) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-capital-reductions`, capitalReduction));
  }

  async saveClientBuySells(buySells) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-buy-sells`, buySells));
  }

  async saveClientShareholders(shareholders) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-shareholder-partners`, shareholders));
  }

  async saveClientAdministrators(administrators) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-administrations`, administrators));
  }

  async getContractsByClient(id, date?) {
    const dateString = date ? `?date=${date}` : '';
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/contracts/${id}${dateString}`));
  }

  async saveClientContract(contract) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/contracts`, contract));
  }

  async saveClientBooks(books) {
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/clients-books`, books));
  }
}
