
export const CLIENT_TYPES: any = [
    {
        label: 'Persona física',
        value: 'PHYSICAL_PERSON'
    },
    {
        label: 'Persona jurídica',
        value: 'JURIDIC_PERSON'
    },
    {
        label: 'Sin personalidad',
        value: 'WITHOUT_PERSONALITY'
    }
];

export const DOCUMENT_TYPES: any = [
    {
        label: 'NIF',
        value: 'NIF'
    },
    {
        label: 'CIF',
        value: 'CIF'
    },
    {
        label: 'Pasaporte',
        value: 'PASSPORT'
    },
    {
        label: 'Documento oficial de identificación',
        value: 'OFFICIAL_DOCUMENT'
    },
    {
        label: 'Certificado de residencia',
        value: 'RESIDENCE_CERTIFICATE'
    },
    {
        label: 'Otro documento probatorio',
        value: 'OTHER'
    },
    {
        label: 'No censado',
        value: 'NO_CENSED'
    }
];

export const DEPARTMENTS: any = [
    {
        label: 'Laboral',
        value: 'LABOR'
    },
    {
        label: 'Contable',
        value: 'ACCOUNTING'
    },
    {
        label: 'Fiscal',
        value: 'FISCAL'
    }
];

export const LABORAL_DEPARTMENTS: any = [
    {
        label: 'Contratación',
        value: 'HIRING'
    },
    {
        label: 'Nóminas',
        value: 'PAYROLLS'
    },
    {
        label: 'Declaraciones',
        value: 'DECLARATIONS'
    }
];

export const DOMICILES: any = [
    {
        label: 'Fiscal',
        value: 'FISCAL'
    },
    {
        label: 'Social',
        value: 'SOCIAL'
    },
    {
        label: 'Notificaciones',
        value: 'NOTIFICATIONS'
    },
    {
        label: 'Actividad',
        value: 'ACTIVITY'
    }
];

export const QUOTE_TYPES: any = [
    {
        label: 'Municipal',
        value: 'MUNICIPAL'
    },
    {
        label: 'Provincial',
        value: 'PROVINCIAL'
    },
    {
        label: 'Nacional',
        value: 'NATIONAL'
    }
];

export const ACTIVITY_TYPES: any = [
    {
        label: 'Empresarial',
        value: 'EMPRESARIAL'
    },
    {
        label: 'Profesional',
        value: 'PROFESSIONAL'
    },
    {
        label: 'Artística',
        value: 'ARTISTIC'
    }
];

export const COMPANY_TYPES: any = [
    {
        label: 'S.A.',
        value: 'SA'
    },
    {
        label: 'S.A.U.',
        value: 'SAU'
    },
    {
        label: 'S.L.',
        value: 'SL'
    },
    {
        label: 'S.L.U.',
        value: 'SLU'
    },
    {
        label: 'S.L.P.',
        value: 'SLP'
    },
    {
        label: 'S.C.P.',
        value: 'SCP'
    },
    {
        label: 'C.B.',
        value: 'CB'
    },
    {
        label: 'Asociación',
        value: 'ASSOCIATION'
    },
    {
        label: 'Fundación',
        value: 'FOUNDATION'
    },
    {
        label: 'UTE',
        value: 'UTE'
    },
    {
        label: 'Extranjera',
        value: 'FOREIGN'
    }
];

export const FRACTIONATED_PAYMENT_TYPES: any = [
    {
        label: '40.3 LIS',
        value: '40_3_LIS'
    },
    {
        label: '40.2 LIS',
        value: '40_2_LIS'
    }
];

export const INCREASE_TYPES: any = [
    {
        label: 'Dineraria',
        value: 'MONETARY'
    },
    {
        label: 'No dineraria',
        value: 'NO_MONETARY'
    }
];

export const DECREASE_TYPES: any = [
    {
        label: 'Disminución VN',
        value: 'DECREASE_VN'
    },
    {
        label: 'Amortización acciones',
        value: 'AMORTIZATION_ACTIONS'
    },
    {
        label: 'Agrupación para canje',
        value: 'GROUPING_EXCHANGE'
    }
];

export const FRAMING_TYPES: any = [
    {
        label: 'Régimen General',
        value: 'GENERAL'
    },
    {
        label: 'Régimen Especial Autónomos',
        value: 'SPECIAL'
    }
];

export const AUTONOMOUS_IN_CHARGE_TYPES: any = [
    {
        label: 'Empresa',
        value: 'COMPANY'
    },
    {
        label: 'Persona',
        value: 'PERSON'
    }
];

export const ADMINISTRATION_SYSTEMS_TYPES: any = [
    {
        label: 'Administrador único',
        value: 'SOLE_ADMINISTRATOR'
    },
    {
        label: 'Administradores solidarios',
        value: 'JOINTLY_ADMINISTRATORS'
    },
    {
        label: 'Administradores mancomunados',
        value: 'JOINT_ADMINISTRATORS'
    },
    {
        label: 'Consejo de administración',
        value: 'BOARD_OF_DIRECTORS'
    },
    {
        label: 'Patronato',
        value: 'BOARD'
    },
    {
        label: 'Asamblea',
        value: 'ASSEMBLY'
    },
    {
        label: 'Otros',
        value: 'OTHERS'
    }
];

export const RETRIBUTION_KEYS: any = [
    {
        label: 'A',
        value: 'A'
    },
    {
        label: 'E',
        value: 'E'
    },
    {
        label: 'G',
        value: 'G'
    }
];

export const PAYMENT_TYPES: any = [
    {
        label: 'Contado',
        value: 'CASH'
    },
    {
        label: 'Domiciliado',
        value: 'DOMICILED'
    },
    {
        label: 'Transferencia',
        value: 'TRANSFER'
    }
];

export const BILL_FORMATS: any = [
    {
        label: '50 Factura Sacich Transferencia',
        value: '50_FACTURA_SACICH_TRANSFER'
    },
    {
        label: '51 Factura Sacich Domiciliada',
        value: '51_FACTURA_SACICH_DOMICILED'
    },
    {
        label: '58 Factura Sacich Transferencia',
        value: '58_FACTURA_SACICH_TRANSFER_ENGLISH'
    },
    {
        label: '59 Factura Sacich Domiciliada',
        value: '59_FACTURA_SACICH_DOMICILED_ENGLISH'
    }
];

export const IVA_EXEMPTION_TYPES: any = [
    {
        label: 'Exenta Art. 20',
        value: 'ART_20'
    },
    {
        label: 'Exenta Art. 21',
        value: 'ART_21'
    },
    {
        label: 'Exenta Art. 22',
        value: 'ART_22'
    },
    {
        label: 'Exenta Art. 23 y 24',
        value: 'ART_23_24'
    },
    {
        label: 'Exenta Art. 25',
        value: 'ART_25'
    },
    {
        label: 'Exenta Otros',
        value: 'OTHERS'
    },
    {
        label: 'No sujeto Art 17, 14, otros',
        value: 'NOT_SUBJECT_ART'
    },
    {
        label: 'No sujeto por reglas de localización',
        value: 'NOT_SUBJECT_LOCATION'
    }
];

export const PERIODICITY_TYPES: any = [
    {
        label: 'Mensual',
        value: 'MONTHLY'
    },
    {
        label: 'Bimensual',
        value: 'BI-MONTHLY'
    },
    {
        label: 'Trimestral',
        value: 'QUARTERLY'
    },
    {
        label: 'Cuatrimestral',
        value: 'FOUR-MONTHLY'
    },
    {
        label: 'Semestral',
        value: 'HALF-YEARLY'
    },
    {
        label: 'Anual',
        value: 'ANNUAL'
    }
];

export const EXPEDIENT_TYPES: any = [
    {
        label: 'CUOTMV',
        value: 'CUOTMV'
    },
    {
        label: 'CUOTTV',
        value: 'CUOTTV'
    },
    {
        label: 'CUOTAT',
        value: 'CUOTAT'
    },
    {
        label: 'CUOTAM',
        value: 'CUOTAM'
    },
    {
        label: 'CUOTAÑO',
        value: 'CUOTAÑO'
    }
];

export const AMBITS: any = [
    {
        label: 'Nacional',
        value: 'NATIONAL'
    },
    {
        label: 'Foral-Alava',
        value: 'FORAL_ALAVA'
    },
    {
        label: 'Foral-Gipuzkoa',
        value: 'FORAL_GIPUZKOA'
    },
    {
        label: 'Foral-Bizkaia',
        value: 'FORAL_BIZKAIA'
    },
    {
        label: 'Foral-Navarra',
        value: 'FORAL_NAVARRA'
    },
    {
        label: 'Canarias',
        value: 'CANARIAS'
    },
    {
        label: 'Ceuta',
        value: 'CEUTA'
    },
];

export const MODEL_PERIODICITY_TYPES: any = [
    {
        label: 'Mensual',
        value: 'MONTHLY'
    },
    {
        label: 'Trimestral',
        value: 'QUARTERLY'
    },
    {
        label: 'Anual',
        value: 'ANNUAL'
    }
];

export const PRESENTATION_TYPES: any = [
    {
        label: 'Dom/Neg/Info',
        value: 'DOMICILED'
    },
    {
        label: 'Sacich NRC',
        value: 'SACICH'
    },
    {
        label: 'Presentación cliente',
        value: 'CLIENT'
    }
];

export const FORMAT_TYPES: any = [
    {
        label: 'PDF',
        value: 'PDF'
    },
    {
        label: 'DAT',
        value: 'DAT'
    }
];

export const INTERN_PROGRAMS: any = [
    {
        label: 'A3 SOC',
        value: 'A3_SOC'
    },
    {
        label: 'A3 NOM',
        value: 'A3_NOM'
    },
    {
        label: 'A3 ECO',
        value: 'A3_ECO'
    },
    {
        label: 'A3 REN',
        value: 'A3_REN'
    },
    {
        label: 'A3_GES',
        value: 'A3_GES'
    },
    {
        label: 'A3 DOC',
        value: 'A3_DOC'
    }
];

export const DEPARTMENTS_TYPES: any = [
    {
        label: 'Todos',
        value: 'ALL'
    },
    {
        label: 'Laboral',
        value: 'LABOR'
    },
    {
        label: 'Contable',
        value: 'ACCOUNTING'
    },
    {
        label: 'Fiscal',
        value: 'FISCAL'
    },
    {
        label: 'Facturación',
        value: 'BILLING'
    },
    {
        label: 'General',
        value: 'GENERAL'
    }
];
