import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { AuthService } from 'src/app/core/services/auth.service';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../core/services/auth.service';

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FontAwesomeModule
];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [...modules]
})
export class LoginComponent {

  isLoading = false;
  hasErrorLogin = false;
  faSpinner = faSpinner;
  
  loginForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required]],
    password: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) { }

  async onLogin() {
    if (this.loginForm.valid) {
      this.isLoading = true;

      const res = await this.authService.login({ ...this.loginForm.value }).catch(() => {
        this.isLoading = false;
        this.hasErrorLogin = true;
      });
      localStorage.setItem('user', JSON.stringify({user: res.user, accessToken: res.accessToken}));
      this.router.navigate(['/home']);
      this.isLoading = false;
    }
  }
}
