<div class="modal is-active">
    <div class="modal-background" (click)="onCloseModal()"></div>
        <div class="modal-content">
            <div class="box">
                <h1 class="title">Crear declaración asociada</h1>
                <div class="select-container columns">
                    <div class="column is-12">
                        <span class="label">Tipo de declaración</span>
                        <p-dropdown 
                        [options]="declarationTypes"
                        [(ngModel)]="decType"
                        placeholder="Seleccionar tipo de declaración"
                        class="dropdown"

                        ></p-dropdown>
                    </div>
                    <div class="buttons-container">
                        <button class="button is-outlined cancel-button" (click)="onCloseModal()">Cancelar</button>
                        <button class="comment-button button is-pulled-right" (click)="onCreate()" [disabled]="!decType">Crear</button>      
                    </div>
                </div>             
            </div>
        </div>
    </div>