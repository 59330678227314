import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { TypeDeclarationEnum } from '../../core/enums/type-declaration.enum';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-related-declaration-modal',
  standalone: true,
  imports: [DropdownModule, CommonModule, FormsModule],
  templateUrl: './related-declaration-modal.component.html',
  styleUrl: './related-declaration-modal.component.scss'
})
export class RelatedDeclarationModalComponent {
  @Input () tasks;
  @Output() cancelEvent = new EventEmitter<void>();
  @Output() createEvent = new EventEmitter<string>();
  user = undefined;
  phases = [];
  isOpen = false;

  decType: any = undefined;
  declarationTypes = Object.values(TypeDeclarationEnum)

  constructor() {
    
   }

  ngOnInit() {
  }
  onCloseModal() {
    this.cancelEvent.emit();
  }

  setDecType(event: any) {
    this.decType = event;
  }

  onCreate() {
    this.createEvent.emit(this.decType);
  }
}
