<div  [formGroup]="laborDataForm">

    <div class="form-title-container">
        <h3>Datos Laborales</h3>
    </div>
    <div class="columns is-multiline ">

        <div class="column is-6 form-container">
            <span class="label">Código cuenta cotización pral en seguridad social</span>
            <input class="search-input" type="text" pInputText formControlName="socialSecurityCode"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número empresa TGSS (NET)</span>
            <input class="search-input" type="text" pInputText formControlName="companyNumberTGSS"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Nº de centros de trabajo</span>
            <input class="search-input" type="text" pInputText formControlName="workCentersNumber"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Mútua de accidentes/enfermedades profesionales</span>
            <input class="search-input" type="text" pInputText formControlName="mutualInsuranceCompany"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Convenio colectivo aplicable</span>
            <input class="search-input" type="text" pInputText formControlName="collectiveAgreement"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">SPRL (Servicio prevencion riesgos laborales)</span>
            <input lass="search-input" type="text" pInputText formControlName="sprl"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Seguro de convenio</span>
            <input class="search-input" type="text" pInputText formControlName="agreementInsurance"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="representation" binary="true" inputId="representation"/>
            <label for="representation">Representación legal de los trabajadores</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="personnelDelegates" binary="true" inputId="personnelDelegates" />
            <label for="personnelDelegates">Delegados de personal</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="worksCouncil" binary="true" inputId="worksCouncil" />
            <label for="worksCouncil">Comité de empresa</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="safetyHealthCommittee" binary="true" inputId="safetyHealthCommittee" />
            <label for="safetyHealthCommittee">Comité de seguridad y salud</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="equalityPlan" binary="true" inputId="equalityPlan" />
            <label for="equalityPlan">Plan de igualdad</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="obligationsLISMI" binary="true" inputId="obligationsLISMI" />
            <label for="obligationsLISMI">Obligaciones LISMI</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="protocolLGTBI" binary="true" inputId="protocolLGTBI" />
            <label for="protocolLGTBI">Protocolo prevención acoso personas LGTBI</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="reportingChannel" binary="true" inputId="reportingChannel" />
            <label for="reportingChannel">Canal denuncias empresa</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="sexualHarassmentProtocol" binary="true" inputId="sexualHarassmentProtocol" />
            <label for="sexualHarassmentProtocol">Protocolo prevención acoso sexual y laboral</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número autorización sistema RED</span>
            <input class="search-input" type="text" pInputText formControlName="systemAuthorizationNumberRED"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Régimen Seguridad Social</span>
            <input class="search-input" type="text" pInputText formControlName="socialSecurityRegime"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Logotipo empresa</span>
            <p-fileUpload 
                name="logo[]"
                chooseLabel="Añadir" 
                customUpload="true" 
                (onSelect)="onUploadLogo($event)" 
                (onRemove)="uploadedLogo = null"
                [multiple]="false" 
                auto="true"
                accept="image/*" 
                maxFileSize="1000000">
                <ng-template pTemplate="header" let-files  let-uploadedFiles="uploadedLogo" let-chooseCallback="chooseCallback" let-removeFileCallback="removeFileCallback">
                    <div class="choose-file-container">
                          <p-button class="choose-file"(onClick)="choose($event, chooseCallback)" [rounded]="true" [outlined]="true" ><fa-icon [icon]="faFilePlus" class="add-icon"></fa-icon></p-button>
                  </div>
                  </ng-template>
                <ng-template pTemplate="content">
                    @if (uploadedLogoUrl){
                        <img [src]="uploadedLogoUrl" alt="Uploaded Logo">
                    }
                </ng-template>
            </p-fileUpload>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Firma empresa</span>
            <p-fileUpload
                name="signature[]"
                chooseLabel="Añadir" 
                customUpload="true" 
                (onSelect)="onUploadSignature($event)" 
                (onRemove)="uploadedSignature = null"
                [multiple]="false" 
                auto="true"
                accept="image/*" 
                maxFileSize="1000000">
                <ng-template pTemplate="header" let-files  let-uploadedFiles="uploadedSignature" let-chooseCallback="chooseCallback" let-removeFileCallback="removeFileCallback">
                    <div class="choose-file-container">
                          <p-button class="choose-file"(onClick)="choose($event, chooseCallback)" [rounded]="true" [outlined]="true" ><fa-icon [icon]="faFilePlus" class="add-icon"></fa-icon></p-button>
                  </div>
                  </ng-template>
                <ng-template pTemplate="content">
                    @if (uploadedSignatureUrl){
                        <img [src]="uploadedSignatureUrl" alt="Uploaded Signature">
                    }

                </ng-template>
            </p-fileUpload>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número total trabajadores de la empresa</span>
            <input class="search-input" type="text" pInputText formControlName="totalCompanyWorkers"/>
        </div>
    </div>
    <div class="form-title-container">
        <h3>Centros de trabajo</h3>
        <a class="button add-button" (click)="addWorkCenter()">Añadir centro de trabajo</a>
    </div>
    <div [formGroup]="workCentersForm">
        <p-accordion [multiple]="true" formArrayName="workCenters">
            @for (workCenter of workCenters.controls; let i = $index; track workCenter) {
                <p-accordionTab header="{{workCenters.at(i).value.street ? workCenters.at(i).value.street + ', ' + workCenters.at(i).value.number : 'Nuevo centro de trabajo' }}" [formGroupName]="i" >
                    <div class="columns is-multiline">
                        <div class="column is-6 form-container">
                            <span class="label">Fecha de alta</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="principal" binary="true" inputId="principal" />
                            <label for="principal">Actividad principal</label>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código cuenta cotización del centro de trabajo</span>
                            <input class="search-input" type="text" pInputText formControlName="workCenterQuoteAccountCode"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código cuenta cotización becarios no remunerados</span>
                            <input class="search-input" type="text" pInputText formControlName="unpaidInternshipQuoteAccountCode"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº registro centro de trabajo autoridad laboral</span>
                            <input class="search-input" type="text" pInputText formControlName="laborAuthorityRegistrationNumber"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Mutua de accidentes / enfermedades profesionales</span>
                            <input class="search-input" type="text" pInputText formControlName="mutualInsuranceCompany"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Mutua de accidentes / enfermedades profesionales</span>
                            <input class="search-input" type="text" pInputText formControlName="collectiveAgreement"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">SPRL (Servicio prevencion riesgos laborales)</span>
                            <input class="search-input" type="text" pInputText formControlName="sprl"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Seguro de convenio</span>
                            <input class="search-input" type="text" pInputText formControlName="agreementInsurance"/>
                        </div>
                        <div class="column is-12" >
                            <div class="columns is-multiline"  >
                                <div class="column is-6 form-container">
                                    <p-checkbox formControlName="representation" binary="true" inputId="representation" />
                                    <label for="representation">Representación legal de los trabajadores</label>
                                </div>
                                <div class="column is-6 form-container">
                                    <p-checkbox formControlName="personnelDelegates" binary="true" inputId="personnelDelegates" />
                                    <label for="personnelDelegates">Delegados de personal</label>
                                </div>
                                <div class="column is-6 form-container">
                                    <p-checkbox formControlName="worksCouncil" binary="true" inputId="worksCouncil"/>
                                    <label for="worksCouncil">Comité de empresa</label>
                                </div>
                            </div>
                            
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Datos bancarios</span>
                            <input class="search-input" type="text" pInputText formControlName="bankAccount"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Dom. Nóminas</span>
                            <input class="search-input" type="text" pInputText formControlName="payroll"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Seguros sociales</span>
                            <input class="search-input" type="text" pInputText formControlName="socialInsurance"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">IRPF</span>
                            <input class="search-input" type="text" pInputText formControlName="irpf"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Siglas</span>
                            <input class="search-input" type="text" pInputText formControlName="acronym"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Vía pública</span>
                            <input class="search-input" type="text" pInputText formControlName="street"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Número de vía</span>
                            <input class="search-input" type="text" pInputText formControlName="number"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Escalera</span>
                            <input class="search-input" type="text" pInputText formControlName="stairs"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Piso</span>
                            <input class="search-input" type="text" pInputText formControlName="floor"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Puerta</span>
                            <input class="search-input" type="text" pInputText formControlName="door"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Municipio</span>
                            <input class="search-input" type="text" pInputText formControlName="town"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">CP</span>
                            <input class="search-input" type="text" pInputText formControlName="postalCode"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Provincia</span>
                            <input class="search-input" type="text" pInputText formControlName="province"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código país</span>
                            <input class="search-input" type="text" pInputText formControlName="codeCountry"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">País</span>
                            <input class="search-input" type="text" pInputText formControlName="country"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Referencia catastral</span>
                            <input class="search-input" type="text" pInputText formControlName="cadastralReference"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Personal del centro</span>
                            <input class="search-input" type="text" pInputText formControlName="personnel"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código CNAE</span>
                            <input class="search-input" type="text" pInputText formControlName="codeCNAE"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código IAE</span>
                            <input class="search-input" type="text" pInputText formControlName="codeIAE"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Tipo de actividad</span>
                            <p-dropdown [options]="clientConstants.ACTIVITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actvidad" formControlName="activityType"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Actividad del centro de trabajo</span>
                            <p-dropdown [options]="" optionLabel="label" optionValue="value"  placeholder="Seleccionar tipo de cliente" formControlName="activity"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha baja actividad</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy"  dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                        </div>
                    </div>
                </p-accordionTab>
            }
        </p-accordion>
    </div>
    

</div>
