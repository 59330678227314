import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';
import { ActivitiesFormComponent } from '../../../components/forms/activities-form/activities-form.component';
import { AddressesFormComponent } from '../../../components/forms/addresses-form/addresses-form.component';
import { ContactsFormComponent } from '../../../components/forms/contacts-form/contacts-form.component';
import { DeclarationsFormComponent } from '../../../components/forms/declarations-form/declarations-form.component';
import { IdentificationFormComponent } from '../../../components/forms/identification-form/identification-form.component';
import { InvoicingFormComponent } from '../../../components/forms/invoicing-form/invoicing-form.component';
import { LaborDataFormComponent } from '../../../components/forms/labor-data-form/labor-data-form.component';
import { OtherDataFormComponent } from '../../../components/forms/other-data-form/other-data-form.component';
import { SocietyDataFormComponent } from '../../../components/forms/society-data-form/society-data-form.component';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@Component({
  selector: 'app-client-detail',
  standalone: true,
  imports: [RouterModule, CommonModule, FontAwesomeModule, ActivitiesFormComponent, AddressesFormComponent, ContactsFormComponent, DeclarationsFormComponent, IdentificationFormComponent, InvoicingFormComponent, LaborDataFormComponent, OtherDataFormComponent, SocietyDataFormComponent, ProgressSpinnerModule],
  templateUrl: './client-detail.component.html',
  styleUrl: './client-detail.component.scss'
})
export class ClientDetailComponent {
  @ViewChild('activeForm') activeForm: Partial<{save: Function}>;
  @ViewChild('progressBar') progressBar: ElementRef;

  faChevronLeft = faChevronLeft;
  faFloppyDisk = faFloppyDisk;

  isSaved = false;
  showButton = false;
  clientTypes = [
    { label: 'Cliente', value: 'cliente' },
    { label: 'Proveedor', value: 'proveedor' },
    { label: 'Cliente/Proveedor', value: 'cliente-proveedor' },
  ];

  tabs = [
    { title: 'Identificación', key:'identification', isActive: true  },
/*     { title: 'Datos contacto', key:'contactData', isActive: false },
    { title: 'Domicilios', key:'addresses', isActive: false},
    { title: 'Actividades', key:'activities', isActive: false},
    { title: 'Datos laborales', key:'laborData', isActive: false},
    { title: 'Datos societarios', key:'societyData', isActive: false},
    { title: 'Facturación', key:'invoicing', isActive: false}, */
    { title: 'Cuentas bancarias', key:'otherData', isActive: false},
    { title: 'Declaraciones', key:'declarations', isActive: false },
  ];

  clientId: any;
  client: any;

  enabledTabs = true;
  isLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    private clientsService: ClientsService,
    private renderer: Renderer2
    ) {}

  async ngOnInit() { 
    this.isLoading = true;
    const id = this.route.snapshot.paramMap.get('id');
    if(id === 'new') {
      this.enabledTabs = false;
    } 
    if (id !== 'new') {
      this.clientId = Number(id);
      this.client = await this.clientsService.getClientById(this.clientId);
    }
    this.isLoading = false;
  }


  async changeTab(tab: any) {
    this.tabs = this.tabs.map((t: any) => {
      t.isActive = t.title === tab.title;
      return t;
    });
    this.showButton = false;

  }

  getActiveTab() {
    return this.tabs.find(tab => tab.isActive);
  }

  async saveTab() {
    await this.activeForm.save().then(() => {
      this.isSaved = true;
      let width = 0;
      this.showButton = false;
      const interval = setInterval(() => {
        if (width >= 100) {
          clearInterval(interval);
          this.isSaved = false;
        } else {
          width++;
          this.renderer.setProperty(this.progressBar.nativeElement, 'value', width);
        }
      }, 30); 
    }).catch((e) => {
    });
  }

  onClientCreated(event) { 
    this.clientId = event;
    this.enabledTabs = true;
  }
  
}
