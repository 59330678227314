import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { ClientsTableComponent } from '../../clients-table/clients-table.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-other-data-form',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule, ClientsTableComponent],
  templateUrl: './other-data-form.component.html',
  styleUrl: './other-data-form.component.scss'
})
export class OtherDataFormComponent {

  public clientConstants = ClientConstants;

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  otherDataForm = this.fb.group({
    id: [],
    client: [],
    administrationNameAEAT: [''],
    administrationCodeAEAT: [''],
    delegationAEAT: [''],
    intraCommunityOperatorObligation: [''],
    intraCommunityOperatorStartDate: [],
    intraCommunityOperatorEndDate: [],
    presentationSIIObligation: [''],
    presentationSIIStartDate: [],
    presentationSIIEndDate: [],
    codeLEI: [''],
    caducityDateLEI: [],
    codeEORI: [''],
    digitalCertificate: [''],
    digitalCertificateCaducityDate: [],
  });

  bankAccountForm = this.fb.group({
    bankAccounts: this.fb.array([]),
  });

  selectedBankAccount: FormGroup | null = null;


  headers = [ 
    {
      name: 'Banco',
      key: 'bankName'
    },
    {
      name: 'IBAN',
      key: 'iban'
    },
    {
      name: 'Fecha alta',
      key: 'startDate'
    },
    {
      name: 'Fecha baja',
      key: 'endDate'
    },
    {
      name: 'Acciones',
      key: 'actions'
    }];

  configTable = {
    headers: this.headers,
    data: undefined
  }

  modalForm: FormGroup = this.fb.group({
    id: [],
    bankName: [''],
    iban: [''],
    startDate: [],
    endDate: [],
    client:[]

  });

  isModalActive: boolean = false;
  isCreating: boolean = false;

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService,
    ) {
      this.otherDataForm.valueChanges.subscribe(() => {
        if (this.otherDataForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
      this.bankAccountForm.valueChanges.subscribe(() => {
        if (this.bankAccountForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
     }

  async ngOnInit() {
    await this.getData();
  }

  async getData() {
    if (this.clientId) {
      let data = await this.clientsService.getClientOtherData(this.clientId);
      if (data?.intraCommunityOperatorStartDate) {
        data.intraCommunityOperatorStartDate = new Date(data.intraCommunityOperatorStartDate);
      }
      if (data?.intraCommunityOperatorEndDate) {
        data.intraCommunityOperatorEndDate = new Date(data.intraCommunityOperatorEndDate);
      }
      if (data?.presentationSIIStartDate) {
        data.presentationSIIStartDate = new Date(data.presentationSIIStartDate);
      }
      if (data?.presentationSIIEndDate) {
        data.presentationSIIEndDate = new Date(data.presentationSIIEndDate);
      }
      if (data?.digitalCertificateCaducityDate) {
        data.digitalCertificateCaducityDate = new Date(data.digitalCertificateCaducityDate);
      }
      if (data?.caducityDateLEI) {
        data.caducityDateLEI = new Date(data.caducityDateLEI);
      }

      let bankAccounts = await this.clientsService.getClientBankAccounts(this.clientId);

      if (bankAccounts.length > 0) {
        bankAccounts.forEach(account => {
          let bankAccount = this.fb.group({
            id: account.id,
            bankName: account.bankName,
            iban: account.iban,
            client: account.client,
            startDate: account.startDate ? new Date(account.startDate) : null,
            endDate: account.endDate ? new Date(account.endDate) : null,
          });

          this.bankAccounts.push(bankAccount);
        });
      }
      
      this.otherDataForm.patchValue(data);
      this.configTable.data = bankAccounts;

    }
  }

  get bankAccounts(): FormArray {
    return this.bankAccountForm.get('bankAccounts') as FormArray;
  }

  addBankAccount() {
    const account = this.fb.group({
      id: [],
      bankName: [''],
      iban: [''],
      startDate: [],
      endDate: [],
      client:[]
    });

    this.bankAccounts.push(account);
  }

  async save() {
    // this.otherDataForm.value.client = Number(this.clientId);
    // if (!this.otherDataForm.value.id) {
    //   delete this.otherDataForm.value['id'];
    // }
    // await this.clientsService.saveClientOtherData(this.otherDataForm.value);

    for (let i = 0; i < this.bankAccounts.length; i++) {
      if(this.bankAccounts.dirty){
        if(!this.bankAccounts.at(i).value.client) {
          this.bankAccounts.at(i).value.client = Number(this.clientId);
        }
        if (!this.bankAccounts.at(i).value.id) {
          delete this.bankAccounts.value['id'];
        }
        await this.clientsService.saveClientBankAccount(this.bankAccounts.at(i).value);
      }
    }
    this.bankAccounts.clear();
    await this.getData();
  }

  async reopen(event) {    
    this.configTable.data[event].endDate = null;  
    let declarationsArray = this.bankAccountForm.get('bankAccounts') as FormArray;
    declarationsArray.at(event).get('endDate').setValue(null);
    await this.clientsService.saveClientBankAccount(declarationsArray.at(event).value);
  }

  async close(event) {
    this.configTable.data[event].endDate = new Date();
    let declarationsArray = this.bankAccountForm.get('bankAccounts') as FormArray;
    declarationsArray.at(event).get('endDate').setValue(new Date());
    await this.clientsService.saveClientBankAccount(declarationsArray.at(event).value);
  }

  openFormModal(index: number | null = null) {
    this.isModalActive = true;

    if (index === null) {
      this.isCreating = true;
      this.modalForm.reset();
    } else {
      this.isCreating = false;
      const account = this.bankAccounts.at(index).value;
      this.modalForm.patchValue(account);
    }
  }
  
  async saveItem() {
    const index = this.bankAccounts.controls.findIndex(
      (control) => control.value.id === this.modalForm.value.id
    );
    if (index > -1) {
      this.bankAccounts.at(index).patchValue(this.modalForm.value);
      this.bankAccounts.at(index).value.client = Number(this.clientId);
      await this.clientsService.saveClientBankAccount(this.bankAccounts.at(index).value);
    } else {
      this.modalForm.value.client = Number(this.clientId);
      this.bankAccounts.push(this.fb.group(this.modalForm.value));
      await this.clientsService.saveClientBankAccount(this.bankAccounts.at(this.bankAccounts.length - 1).value);
    }
    await this.getData();
    this.isModalActive = false;
  }
}
