import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';

@Component({
  selector: 'app-labor-data',
  standalone: true,
  imports: [CommonModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, FormsModule, ReactiveFormsModule, InputNumberModule, AccordionModule],
  templateUrl: './labor-data.component.html',
  styleUrl: './labor-data.component.scss'
})
export class LaborDataComponent {
  @Input() data: any;
  public clientConstants = ClientConstants;

  laborForm = this.fb.group({
    socialSecurityCode: [''],
    companyNumberTGSS: [],
    workCentersNumber: [''],
    mutualInsuranceCompany: [''],
    collectiveAgreement: [''],
    sprl: [''],
    agreementInsurance: [''],
    representation: [false],
    personnelDelegates: [false],
    worksCouncil: [false],
    safetyHealthCommittee: [false],
    equalityPlan: [false],
    obligationsLISMI: [false],
    protocolLGTBI: [false],
    reportingChannel: [false],
    sexualHarassmentProtocol: [false],
    systemAuthorizationNumberRED: [''],
    socialSecurityRegime: [''],
    companyLogoUrl: [''],
    companySignatureUrl: [''],
    totalCompanyWorkers: [],
  });

  workCentersForm = this.fb.group({
    workCenters: this.fb.array([])
  });


  constructor(
    private fb: FormBuilder,
  ) { }

  async ngOnInit() {
    if (this.data) {
      this.laborForm.patchValue(this.data.labor);

      let workCentersArray = this.workCentersForm.get('workCenters') as FormArray;
      this.data.workCenters.forEach(workCenter => {
        workCenter.startDate = workCenter.startDate ? new Date(workCenter.startDate) : null;
        workCenter.endDate = workCenter.endDate ? new Date(workCenter.endDate) : null;
        workCentersArray.push(this.fb.group(workCenter));
      });
    }
  }

  get workCenters(): FormArray {
    return this.workCentersForm.get('workCenters') as FormArray;
  }
  
}
