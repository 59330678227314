<div class="modal is-active">
    <div class="modal-background" (click)="onCloseModal()"></div>
    <div class="modal-content">
      <div class="box">
        @if (isStatus === true) {
          @if(isReactivate === true) {
            <h1 class="title">Reactivar declaración</h1>
          <h3 class="subtitle">¿Desea confirmar la reactivación de la declaración?</h3>
          }
          @else {
            <h1 class="title">Cambiar estado de la declaración: No procede</h1>
          <h3 class="subtitle">¿Quieres confirmar el cambio de estado de la declaración a "No procede"?</h3>
          }
          
        } 
        
        @else {
          <h1 class="title">Reiniciar declaración</h1>
          <h3 class="subtitle">¿Quieres deshacer las siguientes tareas?</h3>
          <div class="columns fases-container">
            @for (phase of phases; track phase){
              <div class="column">
                <p class="phase-title">Fase {{ phase[0] }}</p>
              @for (task of phase[1]; track task){
                  <p class="tasks-text">{{ task.task }}</p>
    
              }
            </div>
          }
        </div>
        }
        <form [formGroup]="commentForm" (ngSubmit)="addComment()">
          <div class="card new-comment">
              <span class="user-name">{{ user.name }}</span>
              <textarea class="textarea" formControlName="comment" placeholder="Escribe aqui tu comentario"></textarea>
          </div>
      </form>
      <div class="buttons-container">
        <button class="button is-outlined cancel-button" (click)="onCloseModal()">Cancelar</button>
        <button class="comment-button button is-pulled-right" (click)="addComment()" type="submit" [disabled]="!commentForm.valid">Comentar</button>      
      </div>
    </div>
    </div>
  </div>