import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ClientsService } from '../../../core/services/clients/clients.service';

@Component({
  selector: 'app-contacts-form',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule],
  templateUrl: './contacts-form.component.html',
  styleUrl: './contacts-form.component.scss'
})
export class ContactsFormComponent {

  contactsForm = this.fb.group({
    contacts: this.fb.array([]),
    usersWithAccess: this.fb.array([]),
  });

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService
    ) { 
      this.contactsForm.valueChanges.subscribe(() => {
        if (this.contactsForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
    }

  async ngOnInit() {
    await this.getData();
  }

  async getData() {
    if (this.clientId) {
      let data = await this.clientsService.getClientContacts(this.clientId);
      let contactsArray = this.contactsForm.get('contacts') as FormArray;
      data.forEach(contact => {
        contactsArray.push(this.fb.group(contact));
      });
    }
  }


  get contacts(): FormArray {
    return this.contactsForm.get('contacts') as FormArray;
  }

  addContactPerson() {
    const contact = this.fb.group({
      name: ['', Validators.required],
      dni: [''],
      position: [''],
      department: [''],
      mail1: ['',Validators.email],
      mail2: ['',Validators.email],
      language: [''],
      phone: [],
      mobilePhone: [],
      accessUser: [''],
      client: [],
      id: []
    });

    this.contacts.push(contact);
  }

  get usersWithAccess(): FormArray {
    return this.contactsForm.get('usersWithAccess') as FormArray;
  }

  addUserWithAccess() {
    const user = this.fb.group({
      username: [''],
      password: [''],
      email: [''],
      accessTo: this.fb.group({
        laborTaxes: [''],
        fiscalTaxes: [''],
        bills: [''],
        contracts: [''],
        payrolls: [''],
        socialInsurances: [''],
        laborCertificates: [''],
        retentionCertificates: [''],
      }),
      newsletters: this.fb.group({
        generals: [''],
        clientsQuotes: [''],
        clientsRent: [''],
      }),
    });

    this.usersWithAccess.push(user);
  }

  async save() {
    for (let i = 0; i < this.contacts.length; i++) {
      if (this.contacts.at(i).dirty) {
        if (!this.contacts.at(i).value.client) {
        this.contacts.at(i).value.client = Number(this.clientId);
        }
        if (!this.contacts.at(i).value.id) {
          delete this.contactsForm.value['id'];
        }
        await this.clientsService.saveClientContact(this.contacts.at(i).value);
      }
    }
    this.contacts.clear();
    await this.getData();
  }
}
