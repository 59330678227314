@if(isLoading) {
    <div class="column is-12" style="display: flex; justify-content: center; margin-top: 100px;">
        <p-progressSpinner ariaLabel="loading"
        styleClass="custom-spinner"/>    </div>
}@else {
    <div class="container-fluid header-container">

        <div class="header">
            <div style="display:flex;">
                <h1  [routerLink] = "['/declarations']" class="title"> <fa-icon class="back-icon"  [icon]="faChevronLeft"></fa-icon>
                    @if(declaration) {
                        {{ declaration.model.name }} {{ declaration.createdAt | date: 'MM'}} {{ declaration.createdAt | date: 'yyyy '}} - {{ declaration.client.name }}

                        
                    }
                </h1>
                <h1 class="title-tag">
                    @if(declaration && declaration.status === "FINISHED") {
                        <span class="tag finalized"><fa-icon [icon]="faCircle"></fa-icon>Realizada</span>
                      } @else if(declaration.status === "PROGRESS") {
                        <span class="tag pending"><fa-icon [icon]="faCircle"></fa-icon>En progreso</span>
                      }
                      @else if (declaration.status === "NOT_APPLICABLE") {
                        <span class="tag not-applicable"><fa-icon [icon]="faCircle"></fa-icon>No procede</span>
                      }
                </h1>
                @if(declaration.status === "NOT_APPLICABLE" && isOnPeriod()) {
                    <button style="margin:30px" class="button reopen-button"  (click)="openReactivateModal()">
                        <fa-icon style="margin-right: 10px;" [icon]="faRotateLeft"></fa-icon>
                        Reactivar
                    </button>
                }
            </div>
           
            <div class="header-actions">
                <div>
                    @if(declaration && declaration.status === "FINISHED" && isOnPeriod()) {
                        <button class="reopen-button button is-pulled-right" (click)="openReactivateModal()">                                
                            Reabrir declaración
                        </button>
                    }
                    @if(declaration && declaration.dateLimit) {
                        <span class="date">Fecha límite: {{declaration.dateLimit | date:'dd/MM/yyyy'}}</span>
                    }
                </div>
                <div [ngStyle]="{'width': '250px'}">
                    <p-dropdown
                        [options]="responsibles" 
                        [(ngModel)]="declaration.responsible.id"
                        optionValue="id"
                        optionLabel="name" 
                        placeholder="Seleccionar responsable" 
                        (onChange)="setResponsible($event)"
                        [readonly]="isBlocked"
                        
                        />
                </div>
                <div>
                    @if (!isBlocked) {
                        <button class="ban-button button is-pulled-right" [disabled]="isBlocked" (click)="openCommentModal()">                                
                            <fa-icon class="ban-icon" [icon]="faBan"></fa-icon>
                            No procede
                        </button>
                    }
                </div>
                
                <!-- <div class="column is-1">
                    <button class="subrayado is-pulled-right" (click)="showAllFases()">                                
                        {{ allExpanded ? 'Contraer todo' : 'Expandir todo' }}
                    </button>
        
                </div> -->
            </div>
    
            <!-- <div>
                @if(declaration && declaration.status === "FINISHED" && isOnPeriod()) {
                    <button class="reopen-button button is-pulled-right" (click)="setToProgress()">                                
                        Reabrir declaración
                    </button>
                }
                @if(declaration && declaration.dateLimit) {
                    <span class="date">Fecha límite: {{declaration.dateLimit | date:'dd/MM/yyyy'}}</span>
                }
            </div> -->
        </div>
    </div>
    <div class="container-fluid filters">
    @if(declaration){
        <div class="select-container">
            <div class="form-container presentation-type-input " [ngStyle]="{'max-width': '200px'}">
                <span class="label">Tipo de presentación</span>
                <p-dropdown
                [options]="declaration.model.availableTypePresentations" 
                [(ngModel)]="declaration.presentationType" 
                placeholder="Seleccionar tipo de presentación" 
                (onChange)="setPresentation($event)"
                class="dropdown"
                [variant]="isPresentationChanged ? 'filled' : 'outlined'"
                [readonly]="isBlocked"
                />
            </div>
            <div class="form-container">
                <span class="label">Cuenta bancaria</span>
                <input 
                class="search-input" 
                type="text" 
                pInputText 
                [(ngModel)]="declaration.bankAccount" 
                (change)="setAccount($event)" 
                placeholder="Cuenta bancaria"
                [disabled]="isAccountDisabled"
                [variant]="isAccountChanged ? 'filled' : 'outlined'"
                [readonly]="isBlocked"
                />
            </div>
        </div>
        <app-declaration-phases [isBlocked]="isBlocked" [phases]="declaration.phases" [isMultiple]="1" [format]="declaration.format" (closeEvent)="getData(declaration.id)" (commentEvent)="commentAdd($event)"></app-declaration-phases>
            <div>
                <h2 class="subtitle">Comentarios</h2>
                @if(!isBlocked){
                    <form [formGroup]="commentForm" (ngSubmit)="commentAdd()">
                        <div class="card new-comment">
                            <span class="user-name">{{ user.name }}</span>
                            <textarea class="textarea" formControlName="comment" placeholder="Escribe aqui tu comentario"></textarea>
                            <hr>
                            <button class="comment-button button is-pulled-right" type="submit" [disabled]="!commentForm.valid" >                                
                                Comentar
                            </button>
                        </div>
                    </form>
                }
                @if (this.observations.length > 0) {
                    <div>
                        @for(obs of observations; track obs.id){
                            <div class="comment-container">
                                <div>
                                    <span class="user-name">{{obs.executor.name}}</span>
                                </div>
                                <span class="date-text">{{obs.created_at | date:'dd/MM/yyyy'}}</span>
                                <p class="comment">{{obs.comment}}</p>
                            </div>
                        }
                    </div>
                    <div
                    class="search-results"
                    infiniteScroll
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScroll()"
                  ></div>
                }      
            </div>
        } @else {
            <div class="skeleton-block">
    
            </div>
            <div class="skeleton-lines">
                <div></div>
                <div></div>
                <div></div>
            </div>
        }
    </div>
    @if (declaration) {
        @if (allPhasesSelected() && !isBlocked && declaration.status !== "NOT_APPLICABLE" && declaration.status !== "FINISHED"){
            <div class="fab-buttons">
                <button class="button fab-exit" [routerLink]="['/declarations']">                                
                    Salir
                </button>
                <button class="button fab-confirm" (click)="setToFinished()">                                
                    Finalizar
                </button>
            </div>
        }
    }
}

@if (showCommentModal){
    <app-comment-modal [isStatus]="true" (closeEvent)="closeCommentModal()" (commentEvent)="setToNoProceed($event)" #commentModal></app-comment-modal>                                          
}

@if (showReactivateModal){
    <app-comment-modal [isReactivate]="true" [isStatus]="true" (closeEvent)="closeReactivateModal()" (commentEvent)="setToProgress($event)" #commentModal></app-comment-modal>                                          
}