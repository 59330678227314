<div class="form-title-container">
    <h3>Facturación</h3>
</div>
<div [formGroup]="invoicingForm">
    <h4>Parámetros de facturación</h4>
    <div class="columns is-multiline">
        <div class="column is-6 form-container">
            <span class="label">Forma de pago</span>
            <p-dropdown  [readonly]="true" [options]="clientConstants.PAYMENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="paymentType"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Días de pago</span>
            <p-calendar  [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="paymentDays"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha inicio de facturación</span>
            <p-calendar [readonlyInput]="true" showOnFocus="false"  [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="invoicingStartDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Vencimiento</span>
            <p-calendar [readonlyInput]="true" showOnFocus="false"  [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="expiration"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Formato facturas</span>
            <p-dropdown [readonly]="true"  [options]="clientConstants.BILL_FORMATS" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de documento" formControlName="billFormat"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox [readonly]="true"  formControlName="exemptionIVA" binary="true" inputId="exemptionIVA" />
            <label for="exemptionIVA">Factura con IVA exento / no sujeto</label>
        </div>
        
        <div class="column is-6 form-container">
            <span class="label">Tipo de exención</span>
            <p-dropdown [readonly]="true"  [options]="clientConstants.IVA_EXEMPTION_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar responsable" formControlName="exemptionTypeIVA"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox [readonly]="true"  formControlName="includeReceipts" binary="true" inputId="includeReceipts" />
            <label for="includeReceipts">Incluir recibos en remesas bancarias</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">IBAN 1</span>
            <input class="search-input" type="text" pInputText formControlName="iban1" [readonly]="true" />
        </div>
        <div class="column is-6 form-container">
            <span class="label">Banco 1</span>
            <input class="search-input" type="text" pInputText formControlName="bank1" [readonly]="true" />
        </div>
        <div class="column is-6 form-container">
            <span class="label">IBAN 2</span>
            <input class="search-input" type="text" pInputText formControlName="iban2" [readonly]="true" />
        </div>
        <div class="column is-6 form-container">
            <span class="label">Banco 2</span>
            <input class="search-input" type="text" pInputText formControlName="bank2" [readonly]="true" />
        </div>
    </div>
    <h4>Parámetros cuota</h4>
    <div class="columns is-multiline ">
        <div class="column is-6 form-container">
            <span class="label">Importe total cuota</span>
            <input class="search-input" type="text" pInputText formControlName="totalImport" [readonly]="true" />
        </div>
        <div class="column is-6 form-container">
            <span class="label">Periodicidad</span>
            <p-dropdown [readonly]="true"  [options]="clientConstants.PERIODICITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar periodicidad" formControlName="periodicity"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha próxima emisión</span>
            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="nextEmissionDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Expediente</span>
            <p-dropdown [readonly]="true"  [options]="clientConstants.EXPEDIENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar expediente" formControlName="expedient"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Empresa emisora</span>
            <input class="search-input" type="text" pInputText formControlName="emittingCompany" [readonly]="true"  />
        </div>
        <div class="column is-6 form-container">
            <span class="label">Formato de recibo</span>
            <input class="search-input" type="text" pInputText formControlName="receiptFormat" [readonly]="true" />
        </div>
        <div class="column is-6 form-container">
            <span class="label">Domicilio remesa</span>
            <p-dropdown [readonly]="true"  [options]="clientConstants.PERIODICITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="remittanceDomiciliation"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Serie contable</span>
            <input class="search-input" type="text" pInputText formControlName="accountingSeries" [readonly]="true" />
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="independentBilling" binary="true" inputId="independentBilling" [readonly]="true"  />
            <label for="independentBilling">Emitir en factura independiente</label>
        </div>
        
    </div>
    <h4>Desglose quota</h4>
    <div class="columns is-multiline ">
        <div class="column is-6 form-container">
            <p-checkbox formControlName="feeProposalAccepted" binary="true" inputId="feeProposalAccepted" [readonly]="true" />
            <label for="feeProposalAccepted">Propuesta honorarios aceptada</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha aceptación</span>
            <p-calendar  [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="acceptationDate"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Total cuota</span>
            <input class="search-input" type="text" pInputText formControlName="total" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota fiscal</span>
            <input class="search-input" type="text" pInputText formControlName="fiscal" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota contable</span>
            <input class="search-input" type="text" pInputText formControlName="accounting" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota laboral</span>
            <input class="search-input" type="text" pInputText formControlName="labor" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota laboral base</span>
            <input class="search-input" type="text" pInputText formControlName="laborBase" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota laboral por trabajador</span>
            <input class="search-input" type="text" pInputText formControlName="laborWorker" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota laboral por profesional</span>
            <input class="search-input" type="text" pInputText formControlName="laborProfessional" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Cuota notificaciones</span>
            <input class="search-input" type="text" pInputText formControlName="notifications" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">IRPF</span>
            <input class="search-input" type="text" pInputText formControlName="irpf" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Societario</span>
            <input class="search-input" type="text" pInputText formControlName="societary" [readonly]="true"/>
        </div>
        <div class="column is-12">
            <h5 class="label">Otros conceptos facturables</h5>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="laborContractsAndAnnexes" binary="true" inputId="laborContractsAndAnnexes" [readonly]="true"/>
            <label for="laborContractsAndAnnexes">Contratos laborales y anexos en cuota</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="socialSecurity" binary="true" inputId="socialSecurity" [readonly]="true"/>
            <label for="socialSecurity">Seguros sociales en cuota</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="laborRequirements" binary="true" inputId="laborRequirements" [readonly]="true"/>
            <label for="laborRequirements">Requerimientos laborales en cuota</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="laborResources" binary="true" inputId="laborResources" [readonly]="true"/>
            <label for="laborResources">Recursos laborales en cuota</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="sacichDomiciliation" binary="true" inputId="sacichDomiciliation" [readonly]="true" />
            <label for="sacichDomiciliation">Por domiciliación en sacich</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="anualAccounts" binary="true" inputId="anualAccounts" [readonly]="true"/>
            <label for="anualAccounts">Cuotas anuales</label>
        </div>
    </div>
</div>
<div>
    <div class="form-title-container">
        <h3 class="subtitle">Cuetas bancarias</h3>
    </div>
    <div [formGroup]="otherForm">
        <p-accordion [multiple]="true" formArrayName="bankAccounts">
            @for (account of bankAccounts.controls; let i = $index; track account) {
                <p-accordionTab header="{{bankAccounts.at(i).value.name ? bankAccounts.at(i).value.name : 'Nuevo Banco' }}" [formGroupName]="i" class="">
                    <div class="columns is-multiline">
                        <div class="column is-6 form-container">
                            <span class="label">Banco</span>
                            <input class="search-input" type="text" pInputText formControlName="bankName" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">IBAN</span>
                            <input class="search-input" type="text" pInputText formControlName="iban" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha alta</span>
                            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha baja</span>
                            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                        </div>
                    </div>
                </p-accordionTab>
            }@empty {
                <span>No hay cuentas bancarias</span>
            }
        </p-accordion>
    </div>
    
</div>
