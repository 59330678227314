import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(
    private http: HttpClient,
  ) { }

  async getUsers() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/users/list-names`));
  }

  async getClients() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/clients/list-names`));
  }

  async getMaxPhases() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/tasks/max-number-phases`));
  }

  async getModels() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/models/grouped-by-department`));
  }

  async getMinimumYear() {
    return await lastValueFrom<any>(this.http.get(`${environment.apiUrl}/declarations/minium-year`));
  }

}
