import { Component, Input } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-fiscal-data',
  standalone: true,
  imports: [InputTextModule, DropdownModule, FormsModule, ReactiveFormsModule, CalendarModule, AccordionModule, CalendarModule, CheckboxModule, CommonModule],
  templateUrl: './fiscal-data.component.html',
  styleUrl: './fiscal-data.component.scss'
})


export class FiscalDataComponent {
  @Input() data: any;
  clientConstants = ClientConstants;

  activitiesForm = this.fb.group({
    activities: this.fb.array([]),
  });

  societyForm = this.fb.group({
    isBigCompany: [],
    startDateISOObligation: [],
    fractionatedPayment: [],
  });

  otherForm = this.fb.group({
    id: [],
    client: [],
    administrationNameAEAT: [''],
    administrationCodeAEAT: [''],
    delegationAEAT: [''],
    intraCommunityOperatorObligation: [''],
    intraCommunityOperatorStartDate: [''],
    intraCommunityOperatorEndDate: [''],
    presentationSIIObligation: [''],
    presentationSIIStartDate: [''],
    presentationSIIEndDate: [''],
    codeLEI: [''],
    caducityDateLEI: [''],
    codeEORI: [''],
    digitalCertificate: [''],
    digitalCertificateCaducityDate: [''],
});

  constructor(
    private fb: FormBuilder,
  ) { }

  async ngOnInit() {
    if (this.data) {
      let activitiesArray = this.activitiesForm.get('activities') as FormArray;
      this.data.activities.forEach(activity => {
        activity.startDate = activity.startDate ? new Date(activity.startDate) : null;
        activity.endDate = activity.endDate ? new Date(activity.endDate) : null;
        activity.startUpExemptionStartDate = activity.startUpExemptionStartDate ? new Date(activity.startUpExemptionStartDate) : null;
        activity.startUpExemptionEndDate = activity.startUpExemptionEndDate ? new Date(activity.startUpExemptionEndDate) : null;
        activity.turnoverExemptionStartDate = activity.turnoverExemptionStartDate ? new Date(activity.turnoverExemptionStartDate) : null;
        activity.turnoverExemptionEndDate = activity.turnoverExemptionEndDate ? new Date(activity.turnoverExemptionEndDate) : null;
        activity.activityVariationDate = activity.activityVariationDate ? new Date(activity.activityVariationDate) : null;
        activitiesArray.push(this.fb.group(activity));
      });

      this.data.society.startDateISOObligation = this.data.society.startDateISOObligation ? new Date(this.data.society.startDateISOObligation) : null;
      this.societyForm.patchValue(this.data.society);

      this.data.other.intraCommunityOperatorStartDate = this.data.other.intraCommunityOperatorStartDate ? new Date(this.data.other.intraCommunityOperatorStartDate) : null;
      this.data.other.intraCommunityOperatorEndDate = this.data.other.intraCommunityOperatorEndDate ? new Date(this.data.other.intraCommunityOperatorEndDate) : null;
      this.data.other.presentationSIIStartDate = this.data.other.presentationSIIStartDate ? new Date(this.data.other.presentationSIIStartDate) : null;
      this.data.other.presentationSIIEndDate = this.data.other.presentationSIIEndDate ? new Date(this.data.other.presentationSIIEndDate) : null;
      this.data.other.digitalCertificateCaducityDate = this.data.other.digitalCertificateCaducityDate ? new Date(this.data.other.digitalCertificateCaducityDate) : null;
      this.otherForm.patchValue(this.data.other);
    }
  }

  get activities(): FormArray {
    return this.activitiesForm.get('activities') as FormArray;
  }
}
