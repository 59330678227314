import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';

@Component({
  selector: 'app-mercantil-data',
  standalone: true,
  imports: [CommonModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, FormsModule, ReactiveFormsModule, InputNumberModule, AccordionModule],
  templateUrl: './mercantil-data.component.html',
  styleUrl: './mercantil-data.component.scss'
})
export class MercantilDataComponent {
  @Input() data: any;
  public clientConstants = ClientConstants;
  
  societyDataForm = this.fb.group({
    socialExerciseClosingDate: [''],
  });

  constitutionForm = this.fb.group({
    constitutions: this.fb.array([]),
  }); 

  socialObjectForm = this.fb.group({
    socialObjects: this.fb.array([]),
  });

  socialCapitalForm = this.fb.group({
    socialCapitals: this.fb.array([]),
  });

  capitalIncreaseForm = this.fb.group({
    capitalIncreases: this.fb.array([]),
  });

  capitalReductionForm = this.fb.group({
    capitalReductions: this.fb.array([]),
  });

  buySellForm = this.fb.group({
    buySells: this.fb.array([]),
  });

  shareholdersPartnersForm = this.fb.group({
    shareholdersPartners: this.fb.array([]),
  });

  administrationForm = this.fb.group({
    administrations: this.fb.array([]),
  });

  booksForm = this.fb.group({
    id: [],
    client: [],
    actsBookOrderNumber: [''],
    actsBookDate: [''],
    partnersBookOrderNumber: [''],
    partnersBookDate: [''],
    invCCABookOrderNumber: [''],
    invCCABookDate: [''],
    diaryBookOrderNumber: [''],
    diaryBookDate: ['']
  });



  constructor(
    private fb: FormBuilder,
  ) { }
  

  async ngOnInit() {
    if(this.data.society) {
      this.data.society.clientSociety.startDate = this.data.society.clientSociety.startDate ? new Date(this.data.society.clientSociety.startDate) : null;
      this.data.society.clientSociety.endDate = this.data.society.clientSociety.endDate ? new Date(this.data.society.clientSociety.endDate) : null;
      this.data.society.clientSociety.startDateISOObligation = this.data.society.clientSociety.startDateISOObligation ? new Date(this.data.society.clientSociety.startDateISOObligation) : null;
      this.data.society.clientSociety.socialExerciseClosingDate = this.data.society.clientSociety.socialExerciseClosingDate ? new Date(this.data.society.clientSociety.socialExerciseClosingDate) : null;
      this.data.society.clientSociety.socialDomicileStartDate = this.data.society.clientSociety.socialDomicileStartDate ? new Date(this.data.society.clientSociety.socialDomicileStartDate) : null;
      this.data.society.clientSociety.socialDomicileEndDate = this.data.society.clientSociety.socialDomicileEndDate ? new Date(this.data.society.clientSociety.socialDomicileEndDate) : null;
      this.societyDataForm.patchValue(this.data.society.clientSociety);

      let constitutionsArray = this.constitutionForm.get('constitutions') as FormArray;
      this.data.society.constitution.forEach(constitution => {
        constitution.documentDate = constitution.documentDate ? new Date(constitution.documentDate) : null;
        constitution.dealDate = constitution.dealDate ? new Date(constitution.dealDate) : null;
        constitution.inscriptionDate = constitution.inscriptionDate ? new Date(constitution.inscriptionDate) : null;        
        constitutionsArray.push(this.fb.group(constitution));
      });

      let socialObjectsArray = this.socialObjectForm.get('socialObjects') as FormArray;
      this.data.society.socialObject.forEach(socialObject => {
        socialObject.documentDate = socialObject.documentDate ? new Date(socialObject.documentDate) : null;
        socialObject.dealDate = socialObject.dealDate ? new Date(socialObject.dealDate) : null;
        socialObject.inscriptionDate = socialObject.inscriptionDate ? new Date(socialObject.inscriptionDate) : null;
        socialObject.startDate = socialObject.startDate ? new Date(socialObject.startDate) : null;
        socialObject.endDate = socialObject.endDate ? new Date(socialObject.endDate) : null;
        socialObjectsArray.push(this.fb.group(socialObject));
      });

      let socialCapitalsArray = this.socialCapitalForm.get('socialCapitals') as FormArray;
      this.data.society.socialCapital.forEach(socialCapital => {
        socialCapitalsArray.push(this.fb.group(socialCapital));
      });

      let capitalIncreasesArray = this.capitalIncreaseForm.get('capitalIncreases') as FormArray;
      this.data.society.capitalIncrease.forEach(capitalIncrease => {
          capitalIncrease.increaseDate = capitalIncrease.increaseDate ? new Date(capitalIncrease.increaseDate) : null;
          capitalIncrease.dealDate = capitalIncrease.dealDate ? new Date(capitalIncrease.dealDate) : null;
          capitalIncrease.inscriptionDate = capitalIncrease.inscriptionDate ? new Date(capitalIncrease.inscriptionDate) : null;
          capitalIncreasesArray.push(this.fb.group(capitalIncrease));
      });
      
      let capitalReductionsArray = this.capitalReductionForm.get('capitalReductions') as FormArray;
      this.data.society.capitalReduction.forEach(capitalReduction => {
          capitalReduction.reductionDate = capitalReduction.reductionDate ? new Date(capitalReduction.reductionDate) : null;
          capitalReduction.dealDate = capitalReduction.dealDate ? new Date(capitalReduction.dealDate) : null;
          capitalReduction.inscriptionDate = capitalReduction.inscriptionDate ? new Date(capitalReduction.inscriptionDate) : null;
          capitalReductionsArray.push(this.fb.group(capitalReduction));
      });

      let buySellsArray = this.buySellForm.get('buySells') as FormArray;
      this.data.society.buySell.forEach(buySell => {
        buySell.documentDate = buySell.documentDate ? new Date(buySell.documentDate) : null;
        buySell.dealDate = buySell.dealDate ? new Date(buySell.dealDate) : null;
        buySell.inscriptionDate = buySell.inscriptionDate ? new Date(buySell.inscriptionDate) : null
        buySellsArray.push(this.fb.group(buySell));
      });

      let shareholdersPartnersArray = this.shareholdersPartnersForm.get('shareholdersPartners') as FormArray;
      this.data.society.shareholderPartner.forEach(shareholderPartner => {
        shareholderPartner.startDate = shareholderPartner.startDate ? new Date(shareholderPartner.startDate) : null;
        shareholderPartner.endDate = shareholderPartner.endDate ? new Date(shareholderPartner.endDate) : null;
        shareholdersPartnersArray.push(this.fb.group(shareholderPartner));
      });

      let administrationsArray = this.administrationForm.get('administrations') as FormArray;
      this.data.society.administration.forEach(administration => {
        administration.startDocumentDate = administration.startDocumentDate ? new Date(administration.startDocumentDate) : null;
        administration.startDealDate = administration.startDealDate ? new Date(administration.startDealDate) : null;
        administration.startInscriptionDate = administration.startInscriptionDate ? new Date(administration.startInscriptionDate) : null;
        administration.endDocumentDate = administration.endDocumentDate ? new Date(administration.endDocumentDate) : null;
        administration.endDealDate = administration.endDealDate ? new Date(administration.endDealDate) : null;
        administration.endInscriptionDate = administration.endInscriptionDate ? new Date(administration.endInscriptionDate) : null;
        administration.personalDigitalCertificateCaducityDate = administration.personalDigitalCertificateCaducityDate ? new Date(administration.personalDigitalCertificateCaducityDate) : null;
        administrationsArray.push(this.fb.group(administration));
      });

      this.data.society.book.actsBookDate = this.data.society.book.actsBookDate ? new Date(this.data.society.book.actsBookDate) : null;
      this.data.society.book.partnersBookDate = this.data.society.book.partnersBookDate ? new Date(this.data.society.book.partnersBookDate) : null;
      this.data.society.book.invCCABookDate = this.data.society.book.invCCABookDate ? new Date(this.data.society.book.invCCABookDate) : null;
      this.data.society.book.diaryBookDate = this.data.society.book.diaryBookDate ? new Date(this.data.society.book.diaryBookDate) : null;
      this.booksForm.patchValue(this.data.society.book);

    }
  }

  get constitutions(): FormArray {
    return this.constitutionForm.get('constitutions') as FormArray;
  }

  get socialObjects(): FormArray {
    return this.socialObjectForm.get('socialObjects') as FormArray;
  }

  get socialCapitals(): FormArray {
    return this.socialCapitalForm.get('socialCapitals') as FormArray;
  }

  get capitalIncreases(): FormArray {
    return this.capitalIncreaseForm.get('capitalIncreases') as FormArray;
  }

  get capitalReductions(): FormArray {
    return this.capitalReductionForm.get('capitalReductions') as FormArray;
  }

  get buySells(): FormArray {
    return this.buySellForm.get('buySells') as FormArray;
  }

  get shareholdersPartners(): FormArray {
    return this.shareholdersPartnersForm.get('shareholdersPartners') as FormArray;
  }

  get administrations(): FormArray {
    return this.administrationForm.get('administrations') as FormArray;
  }


}
