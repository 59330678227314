@if (configTable){
    <table class="table is-striped is-hoverable ">
        <thead>
          <tr>
            @for (header of configTable.headers; track header.key) {
                <th>{{ header.name }}</th>
            }
            <th></th>
          </tr> 
        </thead>
        <tbody>
          @if (configTable.data){
            @for (item of configTable.data; track item) {
                <tr>
                @for (header of configTable.headers; track header.key) {
                    @if (header.key === 'date'){
                        <td>
                            {{ item[header.key] | date: 'dd/MM/yyyy'}}
                        </td>
                    } @else if (header.key === 'state'){
                        <td>
                            @if (item[header.key] === true){
                                <span class="tag is-check">Realizar tarea</span>
                            } @else if (item[header.key] === false){
                                <span class="tag is-uncheck">Reiniciar declaración</span>
                            } @else if (item['task'] === 'STATUS') {
                                <span class="tag is-changed">Cambiar a:  {{(item[header.key] === 'FINISHED' ? 'Finalizada' : item[header.key] === 'NOT_APPLICABLE' ? 'No procede' : item[header.key] === 'PROGRESS' ? 'En curso' : '')}}</span>
                            } 
                            @else if (item['task'] === 'PRESENTATION') {
                                <span class="tag is-changed">Cambiar a: {{item[header.key]}}</span>
                            }
                            @else if (item.type === 'EXECUTOR') {
                                <span class="tag is-changed">Cambiar a: {{getUserName(item[header.key])}}</span>
                            }
                            @else {
                                <span class="tag is-changed">Cambiar a: {{ item[header.key] }}</span>
                            }
                        </td>
                    } @else if (header.key === 'phase'){
                        <td>
                            {{ item[header.key] }}
                        </td>
                    }
                    @else if (header.key === 'task'){
                        <td>
                            @if (item[header.key] === 'STATUS'){
                                Estado de la declaración
                            } @else if ((item[header.key] === 'PRESENTATION')) {
                                Formato de presentación
                            } @else {
                            {{ item[header.key] }}
                            }
                        </td>
                    }@else {
                        <td>
                            {{ item[header.key] }}
                        </td>
                    }
                }
                <td [ngStyle]="{'width':'30%'}"></td>
                </tr>
            }
          } @else {
            <tr class="skeleton-block">
              <td></td>
              @for (header of configTable.headers; track header.key) {
                <td></td>
              }
            </tr>
  
          }
        </tbody>
      </table>
  }
  