import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

export const isLogged: CanActivateFn =
    async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const authService = inject(AuthService);

      if (!authService.isAuthenticated()) {
        const router = inject(Router);
        router.navigate(['/login']);
        return false;
      }
      return true;
    };

