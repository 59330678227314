<div class="container-fluid content-page background">
    <div class="columns is-multiline">
        <div class="column is-full">
            <div class="tabs is-fullwidth is-primary">
                <ul >
                    @for (tab of tabs; track tab.title) {
                    <li (click)="changeTab(tab)" class="is-active" [ngClass]="{'is-active' : tab.isActive}"><a>{{tab.title}}</a></li>
                    }
                </ul>
            </div>
        </div>
        <!-- <div class="column is-12">
             @if (historicCampaigns) {
                <h2 class="subtitle">Histórico campañas</h2>
            } @else {
                <h2 class="subtitle">Declaraciones en curso</h2>
            } 
             <div class="columns is-multiline filters-container">
                <div class="column is-2">
                    <p-multiSelect
                    [options]="clients" 
                    [(ngModel)]="selectedClient" 
                    optionLabel="name"
                    [filter]="true" 
                    filterBy="name" 
                    placeholder="Seleccionar clientes"
                    (onChange)="onClientSelected($event)"/>

                </div>
                <div class="column is-2">
                    @if(historicCampaigns === true){
                        <p-dropdown class="is-fullwidth"
                        [options]="years"
                        [filter]="false"
                        [(ngModel)]="selectedYear" 
                        [showClear]="true"
                        placeholder="Seleccionar año"
                        (onChange)="onYearSelected($event)" />
                    } @else {
                        <p-dropdown 
                        [options]="fases"
                        [filter]="false"
                        optionLabel="name"
                        [(ngModel)]="selectedFase"
                        [showClear]="true"
                        placeholder="Seleccionar fases"
                        (onChange)="onPhaseChange($event)"/>
                    }
                </div>
                <div class="column is-2">
                    <p-multiSelect 
                    [options]="models"
                    [filter]="false"
                    [group]="true"
                    optionLabel="name"
                    optionValue="id"
                    optionGroupLabel="department"
                    optionGroupChildren="models"
                    [(ngModel)]="selectedModels"
                    placeholder="Seleccionar modelos"
                    (onChange)="onModelChange($event)"
                    />

                </div>
                <div class="column is-2">
                    <p-multiSelect
                        [options]="presentationTypes"
                        [filter]="false"
                        [(ngModel)]="selectedPresentationTypes"
                        placeholder="Seleccionar tipos de presentación"
                        (onChange)="onPresentationTypesSelected($event)"
                    />

                </div>
                <div class="column is-2" [ngStyle]="{'padding-top':'18px','text-align':'center', 'display': 'flex', 'align-items': 'middle', 'justify-content': 'center'}">
                    <label>Solo con comentarios</label>
                    <p-inputSwitch  [ngStyle]="{'margin-left': '10px'}"
                        [(ngModel)]="onlyWithComments"
                        (onChange)="onHasCommentsChange($event)" />
                </div>
                <div class="column is-2">
                    <p-dropdown 
                        [options]="campaigns" 
                        [(ngModel)]="onlyExecutorOrResponsible" 
                        optionLabel="label"
                        optionValue="value"
                        (onChange)="onOnlyExecutorOrResponsibleChange($event)">
                    </p-dropdown>

                </div>

            </div> 
        </div> -->

        @if(tabs[0].isActive){
            <div class="column is-12">
            </div>
        }
        @else if(tabs[1].isActive){
            <div class="column is-12">
            </div>
        }
        @else if(tabs[2].isActive){
            <div class="column is-12">
            </div>
        }
    </div>
    @if(tabs[0].isActive) {
        @if(!isLoading) {
            <app-prime-table [configTable]="configPrimeTable" [filtersData]="{models: models, clients: clients, fases: fases, presentationTypes: presentationTypes, users: users}" (declarationChange)="onDeclarationChange($event)"></app-prime-table>
        } @else {
            <div class="column is-12" style="display: flex; justify-content: center; margin-top: 100px;">
                <p-progressSpinner ariaLabel="loading"
                styleClass="custom-spinner"/>
            </div>
        }
    }
    @if(tabs[1].isActive) {
        @if(!isLoading) {
            <app-table [configTable]="configTable" (declarationChange)="onDeclarationChange($event)" (createAsociedDeclaration)="createRelatedDeclaration($event)"></app-table>
            <div class="pagination-container">
                <app-pagination
                    [currentPage]="currentPage" 
                    [totalPages]= "maxPages"
                    (pageChange)="currentPage = $event; getData()">
                </app-pagination>
            </div>
        } @else {
            <div class="column is-12" style="display: flex; justify-content: center; margin-top: 100px;">
                <p-progressSpinner ariaLabel="loading"
                styleClass="custom-spinner"/>            </div>
        }

    }
</div>
@if (selectedDeclarations.length > 1){
    <button class="button fab-button" (click)="goToMultipleGestion()">
        <fa-icon class="icon" [icon]="faSquareCheck"></fa-icon>
        Gestión Múltiple
    </button>
}