import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCloudUpload, faFileImport, faFilePlus, faFile } from '@fortawesome/pro-light-svg-icons';
import { FileUploadModule } from 'primeng/fileupload';
import { FilesService } from '../../core/services/files.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-xml-transform',
  standalone: true,
  imports: [CommonModule, FormsModule, FileUploadModule, FontAwesomeModule, ButtonModule],
  templateUrl: './xml-transform.component.html',
  styleUrl: './xml-transform.component.scss'
})
export class XmlTransformComponent {

  files: any = [];
  faFile = faFile;
  faCloudUpload = faCloudUpload;
  faFilePlus = faFilePlus;
  faFileImport = faFileImport;

  constructor(
    private filesService: FilesService,
  ) {}

  ngOnInit() {}

  onSelectFile(event) {
    if (event.files.length > 0) {
      this.files[0] = event.files[0];
    }
  }

  confirmUpload() {
    this.filesService.uploadAndDownloadXML(this.files[0]);
  }

  async onUploadFile(callback, form) {
    if (this.files.length > 0) {
      await this.filesService.uploadAndDownloadXML(this.files[0]);
      form.clear();
      callback();
    }
  }

  removeFile(callback) {
    this.files = [];
    callback();
  }

  choose(event, callback) {
    callback();
  }

}
