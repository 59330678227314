import { CommonModule } from '@angular/common';
import { Component, ViewChild, viewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { GeneralDataComponent } from '../../../components/clients-master/general-data/general-data.component';
import { FiscalDataComponent } from '../../../components/clients-master/fiscal-data/fiscal-data.component';
import { LaborDataComponent } from '../../../components/clients-master/labor-data/labor-data.component';
import { MercantilDataComponent } from '../../../components/clients-master/mercantil-data/mercantil-data.component';
import { InternDataComponent } from '../../../components/clients-master/intern-data/intern-data.component';
import { DeclarationsDataComponent } from '../../../components/clients-master/declarations-data/declarations-data.component';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-clients-master',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterModule, DropdownModule, FormsModule, ReactiveFormsModule, GeneralDataComponent, FiscalDataComponent, LaborDataComponent, MercantilDataComponent, InternDataComponent, DeclarationsDataComponent, CalendarModule, CommonModule],
  templateUrl: './clients-master.component.html',
  styleUrl: './clients-master.component.scss'
})
export class ClientsMasterComponent {
  @ViewChild(GeneralDataComponent) generalData: GeneralDataComponent;
  clients: any[] = [];
  selectedClient = null;
  selectedDate = null;

  tabs = [
    { title: 'Datos Generales', key:'general', isActive: true  },
    { title: 'Fiscal', key:'fiscal', isActive: false },
    { title: 'Laboral', key:'labor', isActive: false},
    { title: 'Mercantil', key:'mercantil', isActive: false},
    { title: 'Interno', key:'intern', isActive: false},
    { title: 'Declaraciones', key:'declarations', isActive: false},
  ];
  
  clientData = {
    identification: {},
    contact: [],
    address: [],
    activities: [],
    labor: {},
    workCenters: [],
    society: {},
    invoicing: {},
    other: {},
    bankAccounts: {},
    declarations: [],
  }
  
  isLoading = false;

  constructor(
    private clientsService: ClientsService,
  ) {}

  async ngOnInit() { 
    this.clients = await this.clientsService.getClientsNames();
  }

  async onClientSelected(event) {
    this.selectedClient = event.value;
    this.isLoading = true;
    await this.getData();
  }

  async onDateSelected(event) {
    this.selectedDate = event;
    this.isLoading = true;
    await this.getData(this.selectedDate);
  }

  async getData(date?){
    if (date) {
      date = date.toISOString();
    }
    this.clientData.identification = await this.clientsService.getClientIdentification(this.selectedClient.id);
    this.clientData.contact = await this.clientsService.getClientContacts(this.selectedClient.id);
    this.clientData.address = await this.clientsService.getClientAddresses(this.selectedClient.id, date);
    this.clientData.activities = await this.clientsService.getClientActivities(this.selectedClient.id, date);
    this.clientData.labor = await this.clientsService.getClientLabor(this.selectedClient.id);
    this.clientData.workCenters = await this.clientsService.getClientWorkCenters(this.selectedClient.id, date);
    this.clientData.society = await this.clientsService.getClientSociety(this.selectedClient.id, date);
    this.clientData.invoicing = await this.clientsService.getClientInvoicing(this.selectedClient.id);
    this.clientData.other = await this.clientsService.getClientOtherData(this.selectedClient.id);
    this.clientData.bankAccounts = await this.clientsService.getClientBankAccounts(this.selectedClient.id, date);
    this.clientData.declarations = await this.clientsService.getContractsByClient(this.selectedClient.id, date);
    this.isLoading = false;

  }

  changeTab(tab) {
    this.tabs.map(t => t.isActive = false);
    tab.isActive = true;
  }
}
