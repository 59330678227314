@if (configTable){
    <table class="table is-striped is-hoverable ">
        <thead>
            <tr>
                @for (header of configTable.headers; track header.key) {
                    @if(header.key === 'actions') {
                        <th [ngStyle]="{'text-align':'center'}">{{header.name}}</th>
                    } @else {
                        <th>{{header.name}}</th>
                    }
                }
            </tr>
        </thead>
        <tbody>
            @for (item of configTable.data; track item.id; let index = $index) {
                <tr>
                    @for (header of configTable.headers; track header.key) {
                        @if (header.key === 'state') {
                            <td (click)="goToDetail(item.id)">
                                @if(item[header.key] === 'ACTIVE'){
                                    <span class="tag active"><fa-icon [icon]="faCircle"></fa-icon>Activo</span>                            

                                }@else if (item[header.key] === 'INACTIVE'){
                                    <span class="tag inactive"><fa-icon [icon]="faCircle"></fa-icon>Inactivo</span>                            

                                }
                            </td>
                        } @else if(header.key === 'model') {
                            <td (click)="openItem(index)">{{item[header.key].name}}</td>
                        
                        } @else if(header.key === 'startDate' || header.key === 'endDate'){
                            <td (click)="openItem(index)">{{item[header.key] | date:'dd/MM/yyyy'}}</td>

                        } @else if(header.key === 'actions'){   
                            <td [ngStyle]="{'text-align':'center'}">
                                @if(item.endDate) {
                                    <a class="reopen-button" (click)="openModal(index, true)"><fa-icon [icon]="faArrowRotateLeft"></fa-icon></a>

                                }@else {
                                    <a class="close-button" (click)="openModal(index, false)"><fa-icon [icon]="faBan"></fa-icon></a>
                                }
                            </td>

                        }
                        @else {
                            <td (click)="goToDetail(item.id)" (click)="openItem(index)">{{item[header.key]}}</td>
                        }
                    }
                </tr>
            } @empty {
                <tr>
                    <td colspan="12" class="empty-row" [ngStyle]="{'text-align':'center'}">No hay datos</td>
                </tr>
                
            }
        </tbody>
    </table>
}
@if (showModal) {
    <app-contract-actions-modal [i]="i" [isReopen]="isReopen" (cancelEvent)="onCloseModal()" (confirmEvent)="onModalConfirm($event)"></app-contract-actions-modal>
}