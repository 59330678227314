import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsService } from '../../../core/services/clients/clients.service';


@Component({
  selector: 'app-addresses-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule],
  templateUrl: './addresses-form.component.html',
  styleUrl: './addresses-form.component.scss'
})
export class AddressesFormComponent {

  public clientConstants = ClientConstants;

  addressesForm = this.fb.group({
    addresses: this.fb.array([])
  });

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService
    ) { 
      this.addressesForm.valueChanges.subscribe(() => {
        if (this.addressesForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
    }

  async ngOnInit() {
    await this.getData();
  }

  async getData() {
    if (this.clientId) {
      let data = await this.clientsService.getClientAddresses(this.clientId);
      let addressesArray = this.addressesForm.get('addresses') as FormArray;
      data.forEach(address => {
        address.startDate = address.startDate ? new Date(address.startDate) : null;
        address.endDate = address.endDate ? new Date(address.endDate) : null;
        addressesArray.push(this.fb.group(address));
      });
    }
  }

  get addresses(): FormArray {
    return this.addressesForm.get('addresses') as FormArray;
  }

  addAddress() {
    const address = this.fb.group({
      type: ['', Validators.required],
      startDate: [, Validators.required],
      acronym: [''],
      street: [''],
      number: [''],
      stairs: [''],
      floor: [''],
      door: [''],
      town: [''],
      postalCode: [''],
      province: [''],
      codeCountry: [''],
      country: [''],
      cadastralReference: [''],
      endDate: [],
      id: [],
      client: [],
    });

    this.addresses.push(address);
  }

  async save() {
    for (let i = 0; i < this.addresses.length; i++) {
      if (this.addresses.at(i).dirty) {
        if(!this.addresses.at(i).value.client) {
          this.addresses.at(i).value.client = Number(this.clientId);
  
        }
        if (!this.addresses.at(i).value.id) {
          !this.addresses.at(i)['id'];
        }
        await this.clientsService.saveClientAddress(this.addresses.at(i).value);
      }
    }
    this.addresses.clear();
    await this.getData();
  }
}
