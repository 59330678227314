import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, input } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { FiltersService } from '../../../core/services/declarations/filters.service';

@Component({
  selector: 'app-general-data',
  standalone: true,
  imports: [CommonModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, FormsModule, ReactiveFormsModule, InputNumberModule, AccordionModule],
  templateUrl: './general-data.component.html',
  styleUrl: './general-data.component.scss'
})
export class GeneralDataComponent{

  @Input() data: any;

  public clientConstants = ClientConstants;

  identificationForm = this.fb.group({
    id: [],
    code: [''],
    name: [''],
    group: [''],
    type: [''],
    documentType: [''],
    documentNumber: [''],
    constitutionDate: [],
    responsible: [],
    startDate: [],
    endDate: [],
  });

  contactsForm = this.fb.group({
    contacts: this.fb.array([]),
  });

  addressesForm = this.fb.group({
    addresses: this.fb.array([]),
  });

  activitiesForm = this.fb.group({
    activities: this.fb.array([]),
  });

  constructor(
    private fb: FormBuilder,
    private filtersService: FiltersService,
  ) { }

  responsibleUsers;

  async ngOnInit() {
    this.responsibleUsers = await this.filtersService.getUsers();

    await this.getData();
  }

  async getData() {
    if (this.data) {
      this.data.identification.startDate = this.data.identification.startDate ? new Date(this.data.identification.startDate) : null;
      this.data.identification.endDate = this.data.identification.endDate ? new Date(this.data.identification.endDate) : null;
      this.data.identification.constitutionDate = this.data.identification.constitutionDate ? new Date(this.data.identification.constitutionDate) : null;
      this.data.identification.responsible = this.data.identification.responsible ? this.data.identification.responsible.id : null;

      this.identificationForm.patchValue(this.data.identification);

      let contactsArray = this.contactsForm.get('contacts') as FormArray;
      this.data.contact.forEach(contact => {
        contactsArray.push(this.fb.group(contact));
      });

      let addressesArray = this.addressesForm.get('addresses') as FormArray;
      this.data.address.forEach(address => {
        address.startDate = address.startDate ? new Date(address.startDate) : null;
        address.endDate = address.endDate ? new Date(address.endDate) : null;
        addressesArray.push(this.fb.group(address));
      });

      let activitiesArray = this.activitiesForm.get('activities') as FormArray;
      this.data.activities.forEach(activity => {
        activity.startDate = activity.startDate ? new Date(activity.startDate) : null;
        activity.endDate = activity.endDate ? new Date(activity.endDate) : null;
        activity.startUpExemptionStartDate = activity.startUpExemptionStartDate ? new Date(activity.startUpExemptionStartDate) : null;
        activity.startUpExemptionEndDate = activity.startUpExemptionEndDate ? new Date(activity.startUpExemptionEndDate) : null;
        activity.turnoverExemptionStartDate = activity.turnoverExemptionStartDate ? new Date(activity.turnoverExemptionStartDate) : null;
        activity.turnoverExemptionEndDate = activity.turnoverExemptionEndDate ? new Date(activity.turnoverExemptionEndDate) : null;
        activity.activityVariationDate = activity.activityVariationDate ? new Date(activity.activityVariationDate) : null;
        activitiesArray.push(this.fb.group(activity));
      });
    }
  }

  get contacts(): FormArray {
    return this.contactsForm.get('contacts') as FormArray;
  }

  get addresses(): FormArray {
    return this.addressesForm.get('addresses') as FormArray;
  }

  get activities(): FormArray {
    return this.activitiesForm.get('activities') as FormArray;
  }
}
