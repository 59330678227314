import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  async login(formData: Record<string, string>): Promise<any>{
    return await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/auth`, formData));
  }

  isAuthenticated() {
    const userData: any = localStorage.getItem('user');
    const user = JSON.parse(userData);
    if (user) {
      return !this.jwtHelper.isTokenExpired(user.accessToken);
    }
    return false;
  }

  getUser() {
    const userData: any = localStorage.getItem('user');
    const user = JSON.parse(userData);
    return user;
  }

  logout() {
    localStorage.removeItem('user');
  }
}
