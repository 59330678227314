import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FiltersService } from '../../core/services/declarations/filters.service';
import { DEPARTMENTS_TYPES } from '../../core/constants/client.constants';

@Component({
  selector: 'app-executor-modal',
  standalone: true,
  imports: [],
  templateUrl: './executor-modal.component.html',
  styleUrl: './executor-modal.component.scss'
})
export class ExecutorModalComponent {

  @Input() executor: string = '';
  @Output() closeEvent = new EventEmitter<void>();
  @Output() selectEvent = new EventEmitter<string>();
  executors = [];
  isOpen = false;

  departmentsTypes = DEPARTMENTS_TYPES;


  constructor(
    private filtersService: FiltersService,
  ) { }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.executors = (await this.filtersService.getUsers()).filter(u => user.user.department !== 'GENERAL' ? u.department === user.user.department : u.department);;
  }

  onCloseModal() {
    this.closeEvent.emit();
  }

  selectExecutor(executor): void {
    this.selectEvent.emit(executor);
    this.onCloseModal();
  }

  getDepartmentLabel(departmentValue: string): string {
    const department = this.departmentsTypes.find(dep => dep.value === departmentValue);
    return department ? department.label : '';
  }

}
