import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ModelsService } from '../../../core/services/models/models.service';
import { ClientsTableComponent } from '../../clients-table/clients-table.component';
import { ClientsService } from '../../../core/services/clients/clients.service';

@Component({
  selector: 'app-declarations-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule, ClientsTableComponent],
  templateUrl: './declarations-form.component.html',
  styleUrl: './declarations-form.component.scss'
})
export class DeclarationsFormComponent {

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  public clientConstants = ClientConstants;

  declarationsForm = this.fb.group({
    declarations: this.fb.array([]),
  });

  departmentsTypes = ClientConstants.DEPARTMENTS_TYPES;


  headers = [ 
    {
      name: 'Modelo',
      key: 'model'
    },
    {
      name: 'Fecha alta',
      key: 'startDate'
    },
    {
      name: 'Fecha baja',
      key: 'endDate'
    },
    {
      name: 'Acciones',
      key: 'actions'
    }];

  configTable = {
    headers: this.headers,
    data: undefined
  }

  modalForm: FormGroup = this.fb.group({
    id: [],
    client: [],
    model: ['', Validators.required],
    presentationType: [''],
    bankAccount: [''],
    format: [''],
    startDate: [],
    endDate: [],
  });

  declarationsNumber = 0;

  presentations: any[] = [];

  models: any[];
  bankAccounts: any;
  isDecModalActive: boolean;
  isCreating: boolean;
  availablePresentationsTypes: any;

  constructor(
    private fb: FormBuilder,
    private modelsService: ModelsService,
    private clientsService: ClientsService,
    ) { 
      this.declarationsForm.valueChanges.subscribe(() => {
        if (this.declarationsForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
    }

  async ngOnInit() {
    this.models = await this.modelsService.getModelsGroupedByDepartment();
    if(this.models) {
      this.models.forEach(model => {
        model.department = this.getDepartmentLabel(model.department);
      });
    }
    if (this.clientId) {
      this.bankAccounts = await this.clientsService.getClientBankAccounts(this.clientId);
      await this.getData();
    }
  }

  async getData() {
    let declarations = await this.clientsService.getContractsByClient(this.clientId);
    let declarationsArray = this.declarationsForm.get('declarations') as FormArray;
    declarations.forEach(declaration => {
      declaration.startDate = declaration.startDate ? new Date(declaration.startDate) : null;
      declaration.endDate = declaration.endDate ? new Date(declaration.endDate) : null;
      declarationsArray.push(this.fb.group(declaration));
    });
    this.declarationsNumber = declarations.length;
    this.configTable.data = declarations;
  }

  get declarations(): FormArray {
    return this.declarationsForm.get('declarations') as FormArray;
  }

  addDeclaration() {
    const declaration = this.fb.group({
      id: [],
      client: [],
      model: ['', Validators.required],
      // responsible: [''],
      // ambit: [''],
      // specialRegime: [''],
      // specialRegimeDescription: [''],
      // periodicity: [''],
      presentationType: ['', Validators.required],
      bankAccount: [''],
      format: [''],
      startDate: [],
      endDate: [],
      // contact: [''],
      // internPrograms: [''],
    });
    this.declarations.push(declaration);
  }

  availablePresentations(event) {
    this.availablePresentationsTypes = event.value.availableTypePresentations;
  }

  async reopen(event) {    
      this.configTable.data[event].endDate = null;  
      let declarationsArray = this.declarationsForm.get('declarations') as FormArray;
      declarationsArray.at(event).get('endDate').setValue(null);
      await this.clientsService.saveClientContract(declarationsArray.at(event).value);
    }

  async close(event) {
    this.configTable.data[event].endDate = new Date();
    let declarationsArray = this.declarationsForm.get('declarations') as FormArray;
    declarationsArray.at(event).get('endDate').setValue(new Date());
    await this.clientsService.saveClientContract(declarationsArray.at(event).value);
  }

  openFormModal(index: number | null = null) {
    this.isDecModalActive = true;

    if (index === null) {
      this.isCreating = true;
      this.modalForm.reset();
    } else {
      this.isCreating = false;
      const contract = this.declarations.at(index).value;
      this.availablePresentationsTypes = contract.model.availableTypePresentations;
      this.modalForm.patchValue(contract);
      this.modalForm.value.model = contract.model;
    }
  }

  async save() {
    const index = this.declarations.controls.findIndex(
      (control) => control.value.id === this.modalForm.value.id
    );
    if (index > -1) {
      this.declarations.at(index).patchValue(this.modalForm.value);
      this.declarations.at(index).value.client = Number(this.clientId);
      await this.clientsService.saveClientContract(this.declarations.at(index).value);
    } else {
      this.modalForm.value.client = Number(this.clientId);
      this.declarations.push(this.fb.group(this.modalForm.value));
      await this.clientsService.saveClientContract(this.declarations.at(this.declarations.length - 1).value);
    }
    await this.getData();
    this.isDecModalActive = false;
  }

  getDepartmentLabel(departmentValue: string): string {
    const department = this.departmentsTypes.find(dep => dep.value === departmentValue);
    return department ? department.label : '';
  }
}
