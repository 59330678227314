<div class="fases-container">
    @if(phases){
        @for(p of phases; track p; let index = $index){
            <div class="fase" [ngClass]="(isAllPhaseDisabled(index) ? 'phase-hide' : '')">
                <div class="fase-title-container" (click)="showFase(index)" >
                    <div>
                        <span class="fase-title">Fase {{p.phase}}</span>
                        <span class="tag" [ngStyle]="{'color': phaseState(index)[1], 'background-color': phaseState(index)[1] + '1a'}"><fa-icon [icon]="faCircle"></fa-icon> {{phaseState(index)[0]}} </span>
                    </div>
                        <span class="flecha-desplegar" ><fa-icon [icon]="p.show ? faChevronUp : faChevronDown"></fa-icon></span>                    
                </div>
                <hr class="">
                @if (phases[index].show) {
                    <div class="tasks columns" [ngStyle]="getNgStylePointer(index)">
                        @for(task of p.tasks; track task;  let lastIndex = $last; let j = $index){
                            <div class="card"  [ngStyle]="{'width': 100/getActiveTasks(p.tasks) + '%'}" [ngClass]="{'is-disabled': task.isDisabled, 'is-finished': task.value, 'is-active': !task.isDisabled && !task.value }" >   
                                <div class="card-content">
                                    @if (task.type === 'CHECK'){
                                        <label class="checkbox">
                                            <p-checkbox class="check"
                                            [(ngModel)]="task.value"
                                            [binary]="true"
                                            (ngModelChange)="selectDeselectPhases(task, p)"
                                            [disabled]="task.isDisabled"
                                            [readonly]="isBlocked || isTaskBlocked(index,j)"/>
                                            <span>{{task.name}}</span>
                                        </label>
                                    } @else if (task.type === 'NUMBER') {
                                        <div class="input-container">
                                            <span style="padding-bottom: 5px;">{{task.name}}</span>
                                            <div style="width: 200px;">
                                                <input type="number" class="input" [ngClass]="(task.value || task.value === 0 ? 'filled' : '')"
                                                    [(ngModel)]="task.value" 
                                                    [disabled]="task.isDisabled"
                                                    [readonly]="isBlocked || isTaskBlocked(index,j)"
                                                    (change)="selectDeselectPhases(task, p)">
                                            </div>
                                        </div>
                                            
                                    } @else if (task.type === 'OPTIONS') {
                                        <div class="input-container">
                                            <span style="padding-bottom: 5px;">{{task.name}}</span>
                                            <div style="width: 200px;">
                                                <p-dropdown
                                                    [options]="task.options"
                                                    [(ngModel)]="task.value"
                                                    [disabled]="task.isDisabled"
                                                    [readonly]="isBlocked || isTaskBlocked(index,j)"
                                                    [showClear]="task.value"
                                                    [variant]="task.value ? 'filled' : 'outlined'"
                                                    (onChange)="selectDeselectPhases(task, p)">
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    }
                                    @if (task.executor) {
                                         @if (isString(task.executor)) {
                                            <div class="executor"  (mouseover)="hoveringExecutor = task.executor; hoverTask = j; hoverPhase = index" (mouseout)="hoveringExecutor = null; hoverTask = null; hoverPhase = null" [ngStyle]="{'color': (task.executor && !task.isDisabled && task.value) ? '#5cbc9c' : '#cecece'}"   (click)="openExecutorModal(task)">
                                                <span>
                                                    <fa-icon class="user-icon" [icon]="faUser"></fa-icon>
                                                    {{getInitials(task.executor)}}
                                                </span>
                                                @if (hoveringExecutor === task.executor && hoverPhase === index && hoverTask === j) {
                                                    <div class="tooltip-container"  [ngStyle]="{'background-color': task.value ? '#5cbc9c': '#cecece'}">
                                                        {{task.executor}}
                                                    </div>
                                                    
                                                }
                                            </div>
                                         } @else {
                                             <div class="executor"  (mouseover)="hoveringExecutor = task.executor; hoverTask = j; hoverPhase = index" (mouseout)="hoveringExecutor = null; hoverTask = null; hoverPhase = null" [ngStyle]="{'color': (task.executor && !task.isDisabled && task.value) ? '#5cbc9c' : '#cecece'}"   (click)="openExecutorModal(task)">
                                                 <span>
                                                     <fa-icon class="user-icon" [icon]="faUser"></fa-icon>
                                                     {{getInitials(task.executor[0])}}
                                                 </span>
                                                 @if (hoveringExecutor === task.executor && hoverPhase === index && hoverTask === j) {
                                                     <div class="tooltip-container"  [ngStyle]="{'background-color': task.value ? '#5cbc9c': '#cecece'}">
                                                         {{task.executor[0]}}
                                                     </div>
                                                     
                                                 }
                                             </div>
                                         }
                                        
                                    } @else {
                                        <div class="executor" (click)="openExecutorModal(task)">
                                            <span>
                                                <fa-icon class="user-icon" [icon]="faUser"></fa-icon>
                                                N/A
                                            </span>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                            @if(!lastIndex){
                                <fa-icon class="next-icon" [ngStyle]="{'display':(task.isDisabled ? 'none': '')}" [icon]="faPlay"></fa-icon>
                            }
                            
                            
                        }
                    </div>
                }
            </div>
        }
    } @else {
        <div class="skeleton-block">
            <div></div>
        </div>
        <div class="skeleton-block">
            <div></div>
        </div>
        <div class="skeleton-block">
            <div></div>
        </div>
    }
    
</div>

@if (showExecutorModal){
    <app-executor-modal (closeEvent)="closeExecutorModal()" (selectEvent)="onExecutorSelect($event)" #modal></app-executor-modal>                                          
}
@if (showCommentModal){
    <app-comment-modal [tasks]="this.modalData" (closeEvent)="closeCommentModal()" (commentEvent)="comment($event)" #commentModal></app-comment-modal>                                          
}
