<div class="form-title-container">
    <h3>Datos societarios</h3>
</div>
<div [formGroup]="societyDataForm">
    <div>
        <h4>Identificación</h4>
    </div>
    <div class="columns is-multiline">
        
        <div class="column is-6 form-container">
            <span class="label">Fecha cierre ejercicio social</span>
            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="socialExerciseClosingDate"/>
        </div>
</div>
    <div>
        <h4>Operaciones</h4>
    </div>
    <div>
        <p-accordion [multiple]="true" >
                <p-accordionTab header="Constitución" [formGroup]="constitutionForm" >
                    <div formArrayName="constitutions">
                        <div class="form-title-container">
                        </div>
                        @for (c of constitutions.controls; track c; let i = $index) {
                            <div [formGroupName]="i">
                                <div>
                                    <h4>Constitución</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="documentDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber" [readonly]="true"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Objeto social" [formGroup]="socialObjectForm">
                    <div formArrayName="socialObjects">
                        <div class="form-title-container">
                        </div>
                        @for (object of socialObjects.controls; track object; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Objeto social {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="documentDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Copia objeto social</span>
                                        <input class="search-input" type="text" pInputText formControlName="socialObject" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">CNAE Actividad Principal</span>
                                        <input class="search-input" type="text" pInputText formControlName="principalActivityCNAE" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Detalle actividad</span>
                                        <input class="search-input" type="text" pInputText formControlName="activityDetail" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Epígrafe IAE actividad principal</span>
                                        <input class="search-input" type="text" pInputText formControlName="principalActivityEpigraphIAE" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha alta actividad</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha baja actividad</span>
                                        <p-calendar  [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Capital social" [formGroup]="socialCapitalForm">
                    <div formArrayName="socialCapitals">
                        <div class="form-title-container">
                        </div>
                        @for (capital of socialCapitals.controls; track capital; let i = $index) {
                            <div [formGroupName]="i" >
                                <div class="operation-title">
                                    <h4>Capital social {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Tipo / Serie</span>
                                        <input class="search-input" type="text" pInputText formControlName="type" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Número de acciones total</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalActionsNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Valor nominal por acción</span>
                                        <input class="search-input" type="text" pInputText formControlName="nominalValueByAction" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Capital social total</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalSocialCapital" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Numeración acciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumeration" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Prima emisión</span>
                                        <input class="search-input" type="text" pInputText formControlName="bonusEmission" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Observaciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="observations" [readonly]="true"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Ampliación capital" [formGroup]="capitalIncreaseForm">
                    <div formArrayName="capitalIncreases">
                        <div class="form-title-container">
                        </div>
                        @for (increase of capitalIncreases.controls; track increase; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Ampliación capital {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha ampliación</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="increaseDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tipo de ampliación</span>
                                        <input class="search-input" type="text" pInputText formControlName="increaseType" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Observaciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="observations" [readonly]="true"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Reducción capital" [formGroup]="capitalReductionForm" >
                    <div formArrayName="capitalReductions">
                        <div class="form-title-container">
                        </div>
                        @for (reduction of capitalReductions.controls; track reduction; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Reducción capital {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="commercialRegisterProvince" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="inscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="tome" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="sheet" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="leaf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Seccción</span>
                                        <input class="search-input" type="text" pInputText formControlName="section" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="book" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="inscriptionNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha reducción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="reductionDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Tipo de reducción</span>
                                        <input class="search-input" type="text" pInputText formControlName="reductionType" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Observaciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="observations" [readonly]="true"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Compra-Venta" [formGroup]="buySellForm">
                    <div formArrayName="buySells">
                        <div class="form-title-container">
                        </div>
                        @for (bs of buySells.controls; track bs; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Compra-Venta {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Concepto escritura</span>
                                        <input class="search-input" type="text" pInputText formControlName="documentConcept"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="notary"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="protocol"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha Escritura</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="documentDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha Acuerdo</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="dealDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha Contrato</span>
                                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="contractDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre Apellidos / Razón social comprador</span>
                                        <input class="search-input" type="text" pInputText formControlName="buyerName"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Número Acc/Part adquiridas</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumber"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nominal total adquirido</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalNominal"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Numeración acciones adquiridas</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumeration"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Prima emisión</span>
                                        <input class="search-input" type="text" pInputText formControlName="emissionPrime"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Precio de la compraventa</span>
                                        <input class="search-input" type="text" pInputText formControlName="buySellPrice"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre Apellidos / Razón social vendedor</span>
                                        <input class="search-input" type="text" pInputText formControlName="sellerName"/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Accionistas / socios" [formGroup]="shareholdersPartnersForm">
                    <div  formArrayName="shareholdersPartners">
                        <div class="form-title-container">
                        </div>
                        @for (bs of shareholdersPartners.controls; track bs; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Accionista / socio {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre</span>
                                        <input class="search-input" type="text" pInputText formControlName="name" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF</span>
                                        <input class="search-input" type="text" pInputText formControlName="nif" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="resident" binary="true" inputId="resident"  [readonly]="true"/>
                                        <label for="resident">Residente</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Domicilio</span>
                                        <input class="search-input" type="text" pInputText formControlName="domicile" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Código Postal</span>
                                        <input class="search-input" type="text" pInputText formControlName="postalCode" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Municipio</span>
                                        <input class="search-input" type="text" pInputText formControlName="town" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia</span>
                                        <input class="search-input" type="text" pInputText formControlName="province" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha alta</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeName" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeNif" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Número acciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Numeración acciones</span>
                                        <input class="search-input" type="text" pInputText formControlName="actionsNumeration" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Porcentaje participación</span>
                                        <input class="search-input" type="text" pInputText formControlName="percentage" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Control directo</span>
                                        <input class="search-input" type="text" pInputText formControlName="directControl" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Control indirecto</span>
                                        <input class="search-input" type="text" pInputText formControlName="indirectControl" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="familiar" binary="true" inputId="familiar" [readonly]="true" />
                                        <label for="familiar">Socio familiar (Conviviente)</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="worker" binary="true" inputId="worker"  [readonly]="true"/>
                                        <label for="worker">Socio trabajador</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="administrationMember" binary="true" inputId="administrationMember"  [readonly]="true"/>
                                        <label for="administrationMember">Miembro órgano administración</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Régimen de la seguridad social</span>
                                        <input class="search-input" type="text" pInputText formControlName="socialSecurityRegime" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Encuadramiento</span>
                                        <p-dropdown [options]="clientConstants.FRAMING_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="framing"  [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Autónomos a cargo de</span>
                                        <p-dropdown [options]="clientConstants.AUTONOMOUS_IN_CHARGE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="autonomousInChargeOf"  [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">IBAN (En seguridad social)</span>
                                        <input class="search-input" type="text" pInputText formControlName="iban"  [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Rendimiento neto mensual</span>
                                        <input class="search-input" type="text" pInputText formControlName="monthlyNetReturn"  [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Mutua ACC. Trabajo / EP</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaACC" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Mutua ACC. / IT</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaCC" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Valor nominal total</span>
                                        <input class="search-input" type="text" pInputText formControlName="totalNominalValue" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="generalMeetingPresident" binary="true" inputId="generalMeetingPresident"  [readonly]="true"/>
                                        <label for="generalMeetingPresident">Presidente junta general</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="generalMeetingSecretary" binary="true" inputId="generalMeetingSecretary"  [readonly]="true" />
                                        <label for="generalMeetingSecretary">Secretario junta general</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha baja</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate" />
                                    </div>
                                </div>    
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Órgano administración" [formGroup]="administrationForm" >
                    <div formArrayName="administrations">
                        <div class="form-title-container">
                        </div>
                        @for (bs of administrations.controls; track bs; let i = $index) {
                            <div [formGroupName]="i">
                                <div class="operation-title">
                                    <h4>Órgano administración {{i+1}}</h4>
                                </div>
                                <div class="columns is-multiline">
                                    <div class="column is-6 form-container">
                                        <span class="label">Sistema de administración</span>
                                        <p-dropdown [options]="clientConstants.ADMINISTRATION_SYSTEMS_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="administrationSystem"  [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre</span>
                                        <input class="search-input" type="text" pInputText formControlName="name" [readonly]="true"/>
                                    </div>
    
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF</span>
                                        <input class="search-input" type="text" pInputText formControlName="nif" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Domicilio</span>
                                        <input class="search-input" type="text" pInputText formControlName="address" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Código Postal</span>
                                        <input class="search-input" type="text" pInputText formControlName="postalCode" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Municipio</span>
                                        <input class="search-input" type="text" pInputText formControlName="town" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Provincia</span>
                                        <input class="search-input" type="text" pInputText formControlName="province" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Cargo</span>
                                        <input class="search-input" type="text" pInputText formControlName="position" [readonly]="true"/>
                                    </div>
                                    <div class="column is-12">
                                        <h4>Alta cargo</h4>
                                    </div>  
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="startNotary" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="startProtocol" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDocumentDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="startMercantileRegisterProvince" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDealDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startInscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="startTome" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="startSheet" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="startLeaf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Sección</span>
                                        <input class="search-input" type="text" pInputText formControlName="startSection" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="startBook" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="startInscriptionNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-12">
                                        <h4>Fin cargo</h4>
                                    </div>  
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Notario</span>
                                        <input class="search-input" type="text" pInputText formControlName="endNotary" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Protocolo</span>
                                        <input class="search-input" type="text" pInputText formControlName="endProtocol" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha escritura</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDocumentDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Provincia registro mercantil</span>
                                        <input class="search-input" type="text" pInputText formControlName="endMercantileRegisterProvince" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha acuerdo</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date"[showIcon]="true" inputId="icondisplay" formControlName="endDealDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Fecha inscripción</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endInscriptionDate"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Tomo</span>
                                        <input class="search-input" type="text" pInputText formControlName="endTome" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Folio</span>
                                        <input class="search-input" type="text" pInputText formControlName="endSheet" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Hoja</span>
                                        <input class="search-input" type="text" pInputText formControlName="endLeaf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Sección</span>
                                        <input class="search-input" type="text" pInputText formControlName="endSection" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Libro</span>
                                        <input class="search-input" type="text" pInputText formControlName="endBook" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Nº inscripción</span>
                                        <input class="search-input" type="text" pInputText formControlName="endInscriptionNumber" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Nombre representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeName" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">NIF representante</span>
                                        <input class="search-input" type="text" pInputText formControlName="representativeNif" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retribution" binary="true" inputId="retribution"  [readonly]="true"/>
                                        <label for="retribution">Retribución</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Clave retribución</span>
                                        <p-dropdown [options]="clientConstants.RETRIBUTION_KEYS" optionLabel="label" optionValue="value" placeholder="Seleccionar" formControlName="retributionKey" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Importe retribución</span>
                                        <input class="search-input" type="text" pInputText formControlName="retributionImport" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retributionInStatutes" binary="true" inputId="retributionInStatutes"  [readonly]="true"/>
                                        <label for="retributionInStatutes">Consta en estatutos</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Ejercicio inicio retribución</span>
                                        <input class="search-input" type="text" pInputText formControlName="retributionStartRetributionExercise" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retributionContract" binary="true" inputId="retributionContract" [readonly]="true" />
                                        <label for="retributionContract">Contrato</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="retributionAct" binary="true" inputId="retributionAct"  [readonly]="true"/>
                                        <label for="retributionAct">Acta</label>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Régimen de la seguridad social</span>
                                        <input class="search-input" type="text" pInputText formControlName="regime" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Encuadramiento</span>
                                        <p-dropdown [options]="clientConstants.FRAMING_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="framing" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Autónomos a cargo de</span>
                                        <p-dropdown [options]="clientConstants.AUTONOMOUS_IN_CHARGE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar forma de pago" formControlName="autonomousInChargeOf" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">IBAN (En seguridad social)</span>
                                        <input class="search-input" type="text" pInputText formControlName="iban" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Rendimiento neto mensual</span>
                                        <input class="search-input" type="text" pInputText formControlName="monthlyNetReturn" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Mutua ACC. Trabajo / EP</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaACC" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container" >
                                        <span class="label">Mutua ACC. / IT</span>
                                        <input class="search-input" type="text" pInputText formControlName="mutuaCC" [readonly]="true"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="personalDigitalCertificate" binary="true" inputId="personalDigitalCertificate"  [readonly]="true"/>
                                        <label for="personalDigitalCertificate">Certificado digital personal</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <span class="label">Fecha caducidad certificado</span>
                                        <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="personalDigitalCertificateCaducityDate"/>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsCCAA" binary="true" inputId="signsCCAA"  [readonly]="true"/>
                                        <label for="signsCCAA">Firma las CCAA</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="certifiesCCAA" binary="true" inputId="certifiesCCAA"  [readonly]="true"/>
                                        <label for="certifiesCCAA">Certifica las CCAA</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsIS" binary="true" inputId="signsIS" [readonly]="true" />
                                        <label for="signsIS">Firma el IS</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="certifiesIS" binary="true" inputId="certifiesIS" [readonly]="true" />
                                        <label for="certifiesIS">Certifica el IS</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsLaborContracts" binary="true" inputId="signsLaborContracts" [readonly]="true" />
                                        <label for="signsLaborContracts">Firma contratos laborales</label>
                                    </div>
                                    <div class="column is-6 form-container">
                                        <p-checkbox formControlName="signsLaborDocuments" binary="true" inputId="signsLaborDocuments" [readonly]="true" />
                                        <label for="signsLaborDocuments">Firma documentos laborales</label>
                                    </div>
    
                                </div>
                            </div>
                        }
                    </div>
                    
                </p-accordionTab>
                <p-accordionTab header="Libros" [formGroup]="booksForm">
                    <div class="columns is-multiline" >
                        <div class="column is-12">
                            <h5>Libro Actas</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="actsBookOrderNumber" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha</span>
                            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="actsBookDate"/>
                        </div>
                        <div class="column is-12">
                            <h5>Libro Socios</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="partnersBookOrderNumber" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha</span>
                            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="partnersBookDate"/>
                        </div>
                        <div class="column is-12">
                            <h5>Libro INV/CCAA</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="invCCABookOrderNumber" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha</span>
                            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="invCCABookDate"/>
                        </div>
                        <div class="column is-12">
                            <h5>Libro Diario</h5>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº Orden</span>
                            <input class="search-input" type="text" pInputText formControlName="diaryBookOrderNumber" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container" >
                            <span class="label">Fecha</span>
                            <p-calendar [readonlyInput]="true" showOnFocus="false" [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="diaryBookDate"/>
                        </div>
                    </div>
                </p-accordionTab>
        </p-accordion>
    </div>


