import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ExecutorModalComponent } from '../../../components/executor-modal/executor-modal.component';
import { faBan, faChevronLeft, faCircle, faPenLine, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { faUser } from '@fortawesome/pro-light-svg-icons';
import { TableComponent } from '../../../components/table/table.component';
import { DeclarationsService } from '../../../core/services/declarations/declarations.service';
import { CommentModalComponent } from '../../../components/comment-modal/comment-modal.component';
import { TypeChangeEnum } from '../../../core/enums/type-change.enum';
import { DeclarationPhasesComponent } from '../../../components/declaration-phases/declaration-phases.component';
import { FiltersService } from '../../../core/services/declarations/filters.service';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-multiple-gestion',
  standalone: true,
  imports: [FontAwesomeModule, CommonModule, RouterModule, FormsModule, ReactiveFormsModule, ExecutorModalComponent, TableComponent, CommentModalComponent, DeclarationPhasesComponent, DropdownModule],
  templateUrl: './multiple-gestion.component.html',
  styleUrl: './multiple-gestion.component.scss'
})
export class MultipleGestionComponent {

  @ViewChild('modal') modal: ExecutorModalComponent;
  @ViewChild('commentModal') commentModal: CommentModalComponent;
  
  data: any;
  faBan = faBan;
  faPenLine = faPenLine;
  faPlay = faPlay;
  faCircle = faCircle;
  faChevronLeft = faChevronLeft;
  faUser = faUser;

  hoveringExecutor = null;
  hoverTask = null;
  hoverPhase = null;

  decsTable = {
    headers: [{
      name: 'Modelo',
      key: 'model'
    },
    {
      name: 'Cliente',
      key: 'clientName'
    },
    {
      name: 'Tipo presentación',
      key: 'presentationType'
    },
    {
      name: 'Fase',
      key: 'tasks'
    },
    {
      name: 'Responsable',
      key: 'responsible'  
    },
    {
      name: 'Cuenta bancaria',
      key: 'bankAccount'
    }],
    data: undefined,
    disableLink: true,
    historic: false
  };  
  comments = [];

  declaration: any;

  configModal: any = undefined;
  showModal: boolean = false;

  selectedTask = null;

  user = undefined;

  commentForm = new FormGroup({
    comment: new FormControl('', Validators.required),
  });

  configCommentModal: any = undefined;
  showCommentModal: boolean = false;
  
  decFinished = false;

  phases = [];
  changes = [];

  allExpanded = true;

  responsibles;
  

  constructor(
    private route: ActivatedRoute,
    private declarationService: DeclarationsService,
    private router: Router,
    private filtersService: FiltersService
  ) { }


  async ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')).user;
    let ids = this.route.snapshot.queryParamMap.get('ids').split(',').map(id => Number(id));
    await this.getData(ids);
    this.responsibles = await this.filtersService.getUsers();
    this.disableTasks(this.data.declarations[0].presentationType);
  } 
  async getData(ids) {
    this.data = await this.declarationService.getDeclarationsInMass(ids);
    this.decsTable = {...this.decsTable, headers: this.decsTable.headers, data: this.data, historic: false, disableLink: true};
    this.data.phases.forEach(p => {
      p.show = true;
      p.tasks.forEach(t => {
        if(t.value === null || t.value === "false") {
          t.value = false;
        }
        if(t.value === "true") {
          t.value = true;
        }
      });
    });
  }

  commentAdd(comment?): void {
    let observations = [];
    for (let declaration of this.data.declarations) {
      let observation = {
        declaration: declaration.id,
        comment: comment ? comment : this.commentForm.value.comment
      }
      observations.push(observation);
    }
    this.declarationService.createObservationInMass(observations);
    this.commentForm.reset();
  }

  showAllFases() {
    this.allExpanded = !this.allExpanded;
    this.data.phases.forEach(p => {
      p.show = this.allExpanded;
    });
  }

  handleChanges(changes) {
    this.changes = changes;
  }

  async sendChanges() {
    await this.declarationService.massTaskStatusUpdate(this.changes);
    this.router.navigate(['/declarations']);
  }
  
  async finishDeclarations() {
    let ids = this.data.declarations.map(d => d.id);
    await this.sendChanges();
    await this.declarationService.changeStatusDeclarationInMass(ids, 'FINISHED');
  }

  allPhasesSelected() {
    return this.data.phases.every(phase => phase.tasks.every(task => task.value === true));
  }

  async setResponsible(event) {
    const id = event.value;
    this.data.forEach(d => {
      this.changes.push( d.id, {type: TypeChangeEnum.Responsible, change: id})
    });
  }

  disableTasks(presentation) {
    for (let phase of this.data.phases) {
      for (let task of phase.tasks) {
        if (task.isDisabledByTypePresentation) {
          if (task.isDisabledByTypePresentation.includes(presentation)) {
            task.isDisabled = true;
          }
          else {
            task.isDisabled = false;
          }
        }
      }
    }
  }

}
