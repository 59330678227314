<div class="modal is-active">
    <div class="modal-background" (click)="onCloseModal()"></div>
        <div class="modal-content">
            <div class="box">
                @if(isReopen) {
                    <h1 class="title">Reabrir</h1>
                }@else {
                    <h1 class="title">Dar de baja</h1>
                }
                <div class="select-container columns">
                    <div class="column is-12">
                        @if(isReopen) {
                            <span>
                                ¿Está seguro/a que desea reabrirlo?
                            </span>
                        }@else {
                            <span>
                                ¿Está seguro/a que desea darlo de baja?
                            </span>
                        }
                    </div>
                    <div class="buttons-container">
                        <button class="button is-outlined cancel-button" (click)="onCloseModal()">Cancelar</button>
                        <button class="comment-button button is-pulled-right" (click)="onConfirm()" >Aceptar</button>      
                    </div>
                </div>             
            </div>
        </div>
    </div>
