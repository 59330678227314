  @if (configTable){
  <table class="table is-striped is-hoverable ">
      <thead>
        <tr>
          @if(configTable.historic === true){
            <th></th>
          }
          @for (header of configTable.headers; track header.key) {
            @if(header.key === 'checkbox'){
              <th></th>
            } @else if(header.key === 'client' || header.key === 'observations' || header.key === 'presentationType' || header.key === 'newDeclaration'){
              <th [ngStyle]="{'text-align':'center'}">{{ header.name }}</th>
            } @else{
              <th>{{ header.name }}</th>
            }
          }
        </tr> 
      </thead>
      <tbody>
        @if (configTable.data && configTable.data.declarations && configTable.data.declarations.length > 0){
          @for (item of configTable.data.declarations; track configTable.data.declarations.declarationId) {
            @if (item.type === 'NORMAL' || configTable.historic === false ) {
              <tr [ngClass]="{'expanded-row': item.isExpanded}">
                @if(configTable.historic === true){
                    @if(item['declarations'].length > 0){
                      <td (click)="item.isExpanded = !item.isExpanded">
                        <span><fa-icon class="expansion-icon" [icon]="faChevronDown"></fa-icon></span>
                      </td>
                    } @else {
                      <td></td>
                    }
                }
                  @for (header of configTable.headers; track header.key) {
                    @if (header.key === 'checkbox'){
                      <td>
                        <p-checkbox 
                        [(ngModel)]="item.isChecked" 
                        [binary]="true" 
                        inputId="binary" 
                        (ngModelChange)="onCheckboxChange(item)"
                        [disabled]="shouldDisableCheckbox(item)"/>
          
                      </td>
                    } @else if (header.key === 'newDeclaration') {
                      <td [ngStyle]="{'text-align':'center'}"><button class="button add-button" (click)="openRelatedDecModal(item.id)"><fa-icon [icon]="faFilePlus"></fa-icon></button></td>
                    }@else if (header.key === 'log') {
                      <td><button (click)="goToLog(item['id'])"><fa-icon class="log-icon" [icon]="faClockRotateLeft"></fa-icon></button></td>                        
                    } @else{
                        @if (header.key === 'tasks') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'width':'150px'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                          @for (task of item.tasks; track task) {
                            @if(task === "FINISHED") {
                              <fa-icon class="check-icon" [icon]="faCircleCheck"></fa-icon>
  
                            } @else if (task === "STARTED"){
                              <fa-icon class="progress-circle-icon" [icon]="faCircle"></fa-icon>
  
                            } @else if (task === "PENDING"){
                              <fa-icon class="circle-icon" [icon]="faCircle"></fa-icon>
                            }
                          }
                          </td>
                        } @else if(header.key === 'observations') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            <span>
                              @if(item[header.key] === true) {
                                Sí
                              } @else {
                                No
                              }
                            </span>
                          </td>
                        }
                        @else if(header.key === 'state') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            @if(item['status'] === "FINISHED") {
                              <span class="tag finalized"><fa-icon [icon]="faCircle"></fa-icon>Realizada</span>
                            } @else if(item['status'] === "PROGRESS") {
                              <span class="tag pending"><fa-icon [icon]="faCircle"></fa-icon>En progreso</span>
                            }
                            @else {
                              <span class="tag not-finalized"><fa-icon [icon]="faCircle"></fa-icon>No procede</span>
                            }
                          </td>
  
                        } @else if (header.key === 'client') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ item[header.key].code }}
                          </td>
                        } @else if (header.key === 'presentationType') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ item[header.key]  }}
                          </td>
                        } @else if (header.key === 'clientName') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ item.client.name }}
                          </td>
                        } @else if (header.key === 'model') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            @if (item.type) {
                              {{ item[header.key].name }} {{ (item.type === 'NORMAL' ? '' : item.type[0])}} {{ item.createdAt | date: 'MM'}}{{item[header.key].periodicity[0]}} {{ item.createdAt | date: 'yyyy '}}
                            }@else {
                              {{ item[header.key].name }}
                            }
                          </td>
                        } @else if (header.key === 'responsible') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                          @if (item[header.key] !== null) {
                            {{ item[header.key].name }}
                          } @else {
                           
                          }
                          </td>
                        } @else if (header.key === 'bankAccount') {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ item[header.key] }}
                          </td>
                        }@else {
                          <td (click)="goToDetail(item['id'], configTable.historic)" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ item[header.key] }}
                          </td>
                        }
                    }
                  }
              </tr>
            }
              
              @if (item.isExpanded && item.declarations) {

                @for (relatedItem of item.declarations; track relatedItem.id) {
                <tr [ngClass]="{'expanded-row': item.isExpanded}">
                  <td> </td>
                    @for (header of configTable.headers; track header.key) {
                      @if (header.key === 'checkbox'){
                        <td></td>
                      } @else if (header.key === 'newDeclaration') {
                        <td [ngStyle]="{'text-align':'center'}">
                          {{TypeDeclarationEnum[relatedItem.type]}}
                        </td>
                      }@else if (header.key === 'log') {
                        <td><button (click)="goToLog(relatedItem['id'])"><fa-icon class="log-icon" [icon]="faClockRotateLeft"></fa-icon></button></td>                        
                      } @else if(header.key === 'observations') {
                            <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">

                            @if(relatedItem[header.key] === true) {
                              Sí
                            } @else{
                              No
                            }
                            </td>
                      } @else if(header.key === 'state') {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">

                          @if(relatedItem['status'] === "FINISHED") {
                            <span class="tag finalized"><fa-icon [icon]="faCircle"></fa-icon>Realizada</span>
                          } @else if(relatedItem['status'] === "PROGRESS") {
                            <span class="tag pending"><fa-icon [icon]="faCircle"></fa-icon>En Progreso</span>
                          }@else {
                            <span class="tag not-finalized"><fa-icon [icon]="faCircle"></fa-icon>No procede</span>
                          }
                          </td>
                        } @else if (header.key === 'client') {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                          {{ relatedItem[header.key].code }}
                          </td>
                        } @else if (header.key === 'clientName') {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                          {{ relatedItem.client.name }}
                          </td>
                        } @else if (header.key === 'presentationType') {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer', 'text-align':'center'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ PresentationTypeEnum[relatedItem[header.key]]  }}
                          </td>
                        } @else if (header.key === 'model') {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                            {{ relatedItem[header.key].name }} {{ (relatedItem.type === 'NORMAL' ? '' : relatedItem.type[0])}} - {{ item.createdAt | date: 'MM/yyyy'}} 
                          </td>
                        } @else if ( (header.key === 'responsible')) {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                          @if (relatedItem[header.key] !== null) {
                            {{ relatedItem[header.key].name }}
                          } @else {
                           
                          }
                          </td>
                        } @else if (header.key === 'log') {
                          <td>
                            <button (click)="goToLog(relatedItem['id'])"><fa-icon class="log-icon" [icon]="faClockRotateLeft"></fa-icon></button>
                          </td>                        
                        } @else if (relatedItem[header.key] !== null) {
                          <td (click)="goToDetail(relatedItem['id'])" [ngStyle]="{'cursor':'pointer'}" [style.pointer-events]="configTable.disableLink ? 'none' : 'auto'">
                          {{ relatedItem[header.key] }}
                          </td>
                        }@else {
                        }
                    }
                  
                </tr>
                }
              }
          }
        } @else if (configTable.data.declarations.length === 0) {
          <tr>
            <td colspan="11" class="empty-row">No hay declaraciones</td>
          </tr>
        } @else {
          <tr class="skeleton-block">
            <td></td>
            @for (header of configTable.headers; track header.key) {
              <td></td>
            }
          </tr>

        }
      </tbody>
    </table>
    @if (showRelatedDecModal){
      <app-related-declaration-modal (cancelEvent)="closeRelatedDecModal()" (createEvent)="createRelatedDeclaration($event)" #newDecModal></app-related-declaration-modal>                                          
  }
}
