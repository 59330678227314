import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableComponent } from '../../../components/table/table.component';
import { DeclarationsService } from '../../../core/services/declarations/declarations.service';
import { faAngleDown, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilePlus, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { Router } from '@angular/router';
import { FiltersService } from '../../../core/services/declarations/filters.service';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PresentationTypeEnum } from '../../../core/enums/presentation-type.enum';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PrimeTableComponent } from "../../../components/prime-table/prime-table.component";
import { ProgressSpinnerModule } from 'primeng/progressspinner';


@Component({
  selector: 'app-declarations',
  standalone: true,
  imports: [CommonModule, FormsModule, TableComponent, FontAwesomeModule, PaginationComponent, MultiSelectModule, DropdownModule, InputTextModule, InputSwitchModule, PrimeTableComponent, ProgressSpinnerModule],
  templateUrl: './declarations.component.html',
  styleUrl: './declarations.component.scss'
})
export class DeclarationsComponent {

  faAngleDown = faAngleDown;
  faFilePlus = faFilePlus;
  faSquareCheck = faSquareCheck;
  faMagnifyingGlass = faMagnifyingGlass;

  dropdown1IsActive = false;
  dropdown2IsActive = false;

  isLoading = false;

  campaigns = [
    {
      label: 'Todas',
      value: 'all'
    },
    {
      label: 'Responsable',
      value: 'responsible'
    },
    {
      label: 'Ejecutor',
      value: 'executor'
    }
  ];
  historicCampaigns: boolean = false;
  tabs = [
    {
      title: 'En curso',
      isActive: true
    },
    {
      title: 'Histórico',
      isActive: false
    }
  ];

  selectedClient = [];
  clients = [];

  users = [];
  selectedExecutor = [];
  selectedResponsible = [];

  selectedModels = [];
  models: any = undefined;

  selectedFase: any = undefined;
  fases = [];

  selectedYear: any = undefined;
  years = [];

  presentationTypesEnum = PresentationTypeEnum;
  presentationTypes = Object.keys(this.presentationTypesEnum).map(type => {
    return { label: this.presentationTypesEnum[type], value: type };
  });

  selectedPresentationTypes = [];

  onlyWithComments = false;

  onlyExecutorOrResponsible = 'all';

  userDepartment;
  
  headers = [
  {
    name: 'Checkbox',
    key: 'checkbox',
    type: 'checkbox'
  },
  {
    name: 'Modelo',
    key: 'model',
    type: 'list'
  },
  {
    name: 'Cod. Cliente',
    key: 'client',
    type: 'text'
  },
  {
    name: 'Cliente',
    key: 'clientName',
    type: 'list'
  },
  {
    name: 'Presentación',
    key: 'presentationType',
    type: 'list'
  },
  {
    name: 'Fase',
    key: 'tasks',
    type: 'list'
  },
  {
    name: 'Responsable',
    key: 'responsible',
    type: 'list'
  },
  {
    name: 'Ejecutor',
    key: 'executor',
    type: 'list'
  },
  {
    name: 'Comentarios',
    key: 'observations',
    type: 'list'
  },
  {
    name: 'Cuenta bancaria',
    key: 'bankAccount',
    type: 'text'
  }
];

  historicHeaders = [{
    name: 'Modelo',
    key: 'model'
  },
  {
    name: 'Cod. Cliente',
    key: 'client'
  },
  {
    name: 'Cliente',
    key: 'clientName'
  },
  {
    name: 'Tipo presentación',
    key: 'presentationType'
  },
  {
    name: 'Estado',
    key: 'state'
  },
  {
    name: 'Responsable',
    key: 'responsible'
  },
  {
    name: 'Ejecutor',
    key: 'executor'
  },
  {
    name: 'Fecha de presentación',
    key: 'presentationDate'
  },
  {
    name: 'Comentarios',
    key: 'observations'
  },
  {
    name: 'Nueva declaración',
    key: 'newDeclaration'
  },
  {
    name: 'Log',
    key: 'log'
  },
];
  currentPage = 0;
  itemsForPage = 10;
  maxPages = undefined;

  configTable: any = {
    headers: this.headers,
    data: undefined,
    historic: false,

  };

  configPrimeTable: any = {
    headers: this.headers,
    data: undefined,
  };

  selectedDeclarations = [];

  constructor(
    private declarationsService: DeclarationsService,
    private filtersService: FiltersService,
    private router: Router,

  ) { 
    let filters = JSON.parse(localStorage.getItem('filters'));
    if (filters) {
      if (filters.historicCampaigns) {
        this.historicCampaigns = filters.historicCampaigns;
      }
    }
  }

  async ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'));
    this.clients = (await this.filtersService.getClients())
    this.users = (await this.filtersService.getUsers()).filter(u => user.user.department !== 'GENERAL' ? u.department === user.user.department : u.department); 
    const maxPhases = await this.filtersService.getMaxPhases();
    this.models = (await this.filtersService.getModels()).filter(model =>user.user.department !== 'GENERAL' ? model.department === user.user.department : model.department);
    const minYear = await this.filtersService.getMinimumYear();
    const minYearNumber = Number(minYear.minYear);
    const currentYear = new Date().getFullYear();

    for (let i = 0; i < maxPhases.maxPhases; i++) {
      let phase = {name: 'Fase ' + (i+1), id:(i+1), selected: false};
      this.fases.push(phase);
    }
    for (let i = minYearNumber; i <= currentYear; i++) {
      this.years.push(i);
    }

    let tab = JSON.parse(localStorage.getItem('activeTab'));
    if(tab) {
      this.changeTab(tab);
    }
    this.userDepartment = JSON.parse(localStorage.getItem('user')).user.department;
    await this.loadPage();
  }

  async changeTab(tab: any) {
    this.tabs = this.tabs.map((t: any) => {
      t.isActive = t.title === tab.title;
      localStorage.setItem('activeTab', JSON.stringify(tab));
      return t;
    });
    if (this.tabs[1].isActive) {
      this.historicCampaigns = true;
    } else {
      this.historicCampaigns = false;
    }

    await this.loadPage();

  }

  async getData() {
    let data;
    const activeTab = this.tabs.find(tab => tab.isActive);
    if(this.userDepartment) {
    if(this.historicCampaigns === true) {
      this.isLoading = true;
      data =  await this.declarationsService.getDeclarations(this.userDepartment, this.currentPage, this.itemsForPage, this.selectedClient, this.selectedFase, this.selectedModels, this.selectedPresentationTypes, this.onlyExecutorOrResponsible, this.historicCampaigns, this.onlyWithComments, this.selectedYear);
      this.maxPages = data.totalPages+1;
      this.configTable = {...this.configTable, headers: this.historicHeaders, data: data, historic: true};
      this.isLoading = false;
    }
    else {
        this.isLoading = true;
        data =  await this.declarationsService.getDeclarations(this.userDepartment);
        data.declarations = data.declarations.map(declaration => {
          return {...declaration, isChecked: false};
        });
        this.configPrimeTable = {...this.configPrimeTable, headers: this.headers, data: data};
        this.isLoading = false;
      }
      
    }
  }

  async loadPage() {
    await this.getData();
  }
  
  async onClientSelected(event) {
    this.selectedClient = event.value;
    this.getData();
  }

  onPhaseChange(event) {
    this.selectedFase = event.value;
    this.getData();
  }

  onModelChange(event) {
    this.selectedModels = event.value;
    this.getData();
  }

  onHistoricCampaigns() {
    this.getData();
  }

  onYearSelected(event) {
    this.selectedYear = event.value;
    this.getData();
  }

  onPresentationTypesSelected(event) {
    this.selectedPresentationTypes = event.value;
    this.getData();
  }

  onHasCommentsChange(event) {
    this.onlyWithComments = event.checked;
    this.getData();
  }

  onDeclarationChange(declaration: any) {
    if (declaration.isChecked && !this.selectedDeclarations.includes(declaration.id)) {
      this.selectedDeclarations.push(declaration.id);
    } else if (!declaration.isChecked) {
      this.selectedDeclarations = this.selectedDeclarations.filter(d => d !== declaration.id);
    }
  }

  goToMultipleGestion() {
    this.router.navigate(['/declarations/multiple-gestion'], { queryParams: { ids: this.selectedDeclarations.join(',') } });

  }

  onOnlyExecutorOrResponsibleChange(event) {
    this.onlyExecutorOrResponsible = event.value;
    this.getData();
  }

  async createRelatedDeclaration(event) {
    await this.declarationsService.createAsociedDeclaration(event[0], event[1]);
    this.getData();
  }

}
