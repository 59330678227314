<div class="modal is-active">
    <div class="modal-background" (click)="onCloseModal()"></div>
    <div class="modal-content">
      <div class="box">
        <h1 class="title">Asignar ejecutor</h1>
        <table class="table is-striped is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Ejecutor</th>
              <th>Departamento</th>
            </tr>
          </thead>
          <tbody>
            @for(e of executors; track e.id){
                <tr (click)="selectExecutor(e)">
                  <td>{{ e.name }}</td>
                  <td>{{ getDepartmentLabel(e.department) }}</td>
                </tr>
            }
          </tbody>
        </table>
        <button class="button is-outlined cancel-button" (click)="onCloseModal()">Cancelar</button>
      </div>
    </div>
  </div>