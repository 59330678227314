<div [formGroup]="laborForm">
    <div class="form-title-container">
        <h3>Datos Laborales</h3>
    </div>
    <div class="columns is-multiline ">
        <div class="column is-6 form-container">
            <span class="label">Código cuenta cotización pral en seguridad social</span>
            <input class="search-input" type="text" pInputText formControlName="socialSecurityCode" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número empresa TGSS (NET)</span>
            <input class="search-input" type="text" pInputText formControlName="companyNumberTGSS" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Nº de centros de trabajo</span>
            <input class="search-input" type="text" pInputText formControlName="workCentersNumber" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Mútua de accidentes/enfermedades profesionales</span>
            <input class="search-input" type="text" pInputText formControlName="mutualInsuranceCompany" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Convenio colectivo aplicable</span>
            <input class="search-input" type="text" pInputText formControlName="collectiveAgreement" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">SPRL (Servicio prevencion riesgos laborales)</span>
            <input class="search-input" type="text" pInputText formControlName="sprl" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Seguro de convenio</span>
            <input class="search-input" type="text" pInputText formControlName="agreementInsurance" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="representation" binary="true" inputId="representation" [readonly]="true"/>
            <label for="representation">Representación legal de los trabajadores</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="personnelDelegates" binary="true" inputId="personnelDelegates" [readonly]="true"/>
            <label for="personnelDelegates">Delegados de personal</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="worksCouncil" binary="true" inputId="worksCouncil" [readonly]="true"/>
            <label for="worksCouncil">Comité de empresa</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="safetyHealthCommittee" binary="true" inputId="safetyHealthCommittee" [readonly]="true"/>
            <label for="safetyHealthCommittee">Comité de seguridad y salud</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="equalityPlan" binary="true" inputId="equalityPlan" [readonly]="true"/>
            <label for="equalityPlan">Plan de igualdad</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="obligationsLISMI" binary="true" inputId="obligationsLISMI" [readonly]="true"/>
            <label for="obligationsLISMI">Obligaciones LISMI</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="protocolLGTBI" binary="true" inputId="protocolLGTBI" [readonly]="true"/>
            <label for="protocolLGTBI">Protocolo prevención acoso personas LGTBI</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="reportingChannel" binary="true" inputId="reportingChannel" [readonly]="true"/>
            <label for="reportingChannel">Canal denuncias empresa</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="sexualHarassmentProtocol" binary="true" inputId="sexualHarassmentProtocol" [readonly]="true"/>
            <label for="sexualHarassmentProtocol">Protocolo prevención acoso sexual y laboral</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número autorización sistema RED</span>
            <input class="search-input" type="text" pInputText formControlName="systemAuthorizationNumberRED" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Régimen Seguridad Social</span>
            <input class="search-input" type="text" pInputText formControlName="socialSecurityRegime" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Número total trabajadores de la empresa</span>
            <input class="search-input" type="text" pInputText formControlName="totalCompanyWorkers" [readonly]="true"/>
        </div>
    </div>
    <div class="form-title-container">
        <h3>Centros de trabajo</h3>
    </div>
    <div [formGroup]="workCentersForm">
        <p-accordion [multiple]="true" formArrayName="workCenters">
            @for(workCenter of workCenters.controls; let i = $index; track workCenter) {
                <p-accordionTab header="Centro de trabajo {{ i + 1 }}" [formGroupName]="i">
                    <div class="columns is-multiline">
                        <div class="column is-6 form-container">
                            <span class="label">Fecha de alta</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate" showOnFocus="false" [readonlyInput]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="principal" binary="true" inputId="principal" [readonly]="true"/>
                            <label for="principal">Actividad principal</label>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código cuenta cotización del centro de trabajo</span>
                            <input class="search-input" type="text" pInputText formControlName="workCenterQuoteAccountCode" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código cuenta cotización becarios no remunerados</span>
                            <input class="search-input" type="text" pInputText formControlName="unpaidInternshipQuoteAccountCode" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Nº registro centro de trabajo autoridad laboral</span>
                            <input class="search-input" type="text" pInputText formControlName="laborAuthorityRegistrationNumber" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Mutua de accidentes / enfermedades profesionales</span>
                            <input class="search-input" type="text" pInputText formControlName="mutualInsuranceCompany" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">SPRL (Servicio prevencion riesgos laborales)</span>
                            <input class="search-input" type="text" pInputText formControlName="sprl" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Seguro de convenio</span>
                            <input class="search-input" type="text" pInputText formControlName="agreementInsurance" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="representation" binary="true" inputId="representation" [readonly]="true"/>
                            <label for="representation">Representación legal de los trabajadores</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="personnelDelegates" binary="true" inputId="personnelDelegates" [readonly]="true"/>
                            <label for="personnelDelegates">Delegados de personal</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="worksCouncil" binary="true" inputId="worksCouncil" [readonly]="true"/>
                            <label for="worksCouncil">Comité de empresa</label>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Datos bancarios</span>
                            <input class="search-input" type="text" pInputText formControlName="bankAccount" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Dom. Nóminas</span>
                            <input class="search-input" type="text" pInputText formControlName="payroll" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Seguros sociales</span>
                            <input class="search-input" type="text" pInputText formControlName="socialInsurance" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">IRPF</span>
                            <input class="search-input" type="text" pInputText formControlName="irpf" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Siglas</span>
                            <input class="search-input" type="text" pInputText formControlName="acronym" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Vía pública</span>
                            <input class="search-input" type="text" pInputText formControlName="street" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Número de vía</span>
                            <input class="search-input" type="text" pInputText formControlName="number" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Escalera</span>
                            <input class="search-input" type="text" pInputText formControlName="stairs" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Piso</span>
                            <input class="search-input" type="text" pInputText formControlName="floor" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Puerta</span>
                            <input class="search-input" type="text" pInputText formControlName="door" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Municipio</span>
                            <input class="search-input" type="text" pInputText formControlName="town" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">CP</span>
                            <input class="search-input" type="text" pInputText formControlName="postalCode" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Provincia</span>
                            <input class="search-input" type="text" pInputText formControlName="province" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código país</span>
                            <input class="search-input" type="text" pInputText formControlName="codeCountry" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">País</span>
                            <input class="search-input" type="text" pInputText formControlName="country" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Referencia catastral</span>
                            <input class="search-input" type="text" pInputText formControlName="cadastralReference" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Personal del centro</span>
                            <input class="search-input" type="text" pInputText formControlName="personnel" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código CNAE</span>
                            <input class="search-input" type="text" pInputText formControlName="codeCNAE" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Código IAE</span>
                            <input class="search-input" type="text" pInputText formControlName="codeIAE" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Tipo de actividad</span>
                            <p-dropdown [options]="clientConstants.ACTIVITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actvidad" formControlName="activityType" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Actividad del centro de trabajo</span>
                            <p-dropdown [options]="" optionLabel="label" optionValue="value"  placeholder="Seleccionar tipo de cliente" formControlName="activity" [readonly]="true"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Fecha baja actividad</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate" showOnFocus="false" [readonlyInput]="true"/>
                        </div>
                    </div>
                </p-accordionTab>
            }@empty {
                <span>No hay centros de trabajo</span>
            }
        </p-accordion>
    </div>
</div>
