import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormArray, FormGroup } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { PresentationTypeEnum } from '../../../core/enums/presentation-type.enum';
import { TaskTypeEnum } from '../../../core/enums/task-type.enum';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { ModelsService } from '../../../core/services/models/models.service';
import { faMinus, faPlus, faSquare, faSquarePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DragDropModule } from 'primeng/dragdrop';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-model-detail',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, MultiSelectModule, DropdownModule, DragDropModule, FontAwesomeModule],
  templateUrl: './model-detail.component.html',
  styleUrls: ['./model-detail.component.scss']
})
export class ModelDetailComponent {

  faPlus = faPlus;
  faMinus = faMinus;
  faTrash = faTrashCan;
  faSquarePlus = faSquarePlus;

  initialValue = [];

  months = [
    {label: 'Enero', value: 1},
    {label: 'Febrero', value: 2},
    {label: 'Marzo', value: 3},
    {label: 'Abril', value: 4},
    {label: 'Mayo', value: 5},
    {label: 'Junio', value: 6},
    {label: 'Julio', value: 7},
    {label: 'Agosto', value: 8},
    {label: 'Septiembre', value: 9},
    {label: 'Octubre', value: 10},
    {label: 'Noviembre', value: 11},
    {label: 'Diciembre', value: 12}
  ];

  periodicities = [
    {label: 'Anual', value: 'ANNUAL'},
    {label: 'Mensual', value: 'MONTLY'},
  ];

  booleanOptions = [
    {label: 'Sí', value: true},
    {label: 'No', value: false}
  ];

  modelForm = this.fb.group({
    name: [''],
    department: [''],
    periodicity: [''],
    startMonth:[0],
    isDaysTime: false,
    numberOfTime: [''],
    availablePresentations: [''],
    phases: this.fb.array([])
  });
  
  presentations =  Object.values(PresentationTypeEnum)
  taskTypes = Object.values(TaskTypeEnum);


  constructor(
    private fb: FormBuilder,
    private modelsService: ModelsService) { }

  get phases(): FormArray {
    return this.modelForm.get('phases') as FormArray;
  }

  getTasks(phaseIndex: number): FormArray {
    return this.phases.at(phaseIndex).get('tasks') as FormArray;
  }

  addPhase() {
    const phase = this.fb.group({
      id: [this.phases.length + 1],
      tasks: this.fb.array([])
    });
    this.phases.push(phase);
  }

  removeLastPhase() {
    if (this.phases.length > 0) {
      this.phases.removeAt(this.phases.length - 1);
    }
  }

  removePhase(phaseIndex: number) {
    this.phases.removeAt(phaseIndex);
    this.phases.controls.forEach((phase, index) => {
      this.getTasks(index).controls.forEach((task) => {
        task.get('phase').setValue(index + 1);
      });
      phase.get('id').setValue(index + 1);
    });
  }

  addTask(phaseIndex: number) {
    const task = this.fb.group({
      name: [''],
      phase: [phaseIndex + 1],
      type: [''],
      options: this.fb.array([
        this.fb.control(''),
      ]),
      isDisabledByTypePresentation: [''],
      order: [this.getTasks(phaseIndex).length + 1],
      
    });
    this.getTasks(phaseIndex).push(task);
  }

  removeLastTask(phaseIndex: number) {
    const tasks = this.getTasks(phaseIndex);
    if (tasks.length > 0) {
      tasks.removeAt(tasks.length - 1);
    }
  }

  removeTask(phaseIndex: number, taskIndex: number) {
    this.getTasks(phaseIndex).removeAt(taskIndex);
    this.getTasks(phaseIndex).controls.forEach((task, index) => {
      task.get('order').setValue(index + 1);
      
    });
  }

  getOptions(i: number, j: number) {
    return this.getTasks(i).get('options') as FormArray;
}

  getTaskType(taskIndex: number, phaseIndex: number) {
    return this.getTasks(phaseIndex).at(taskIndex).get('type').value;
  }

  addOption(taskIndex: number, phaseIndex: number) {
    const options = this.getTasks(phaseIndex).at(taskIndex).get('options') as FormArray;
    options.push(this.fb.control(''));
  }

  submitModel() {
    //this.modelsService.createModel(this.modelForm.value);
  }
}
