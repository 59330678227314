import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private http: HttpClient) { }

  async uploadAndDownloadXML(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const newFile = await lastValueFrom<any>(this.http.post(`${environment.apiUrl}/files/upload`, formData, { responseType: 'blob' }));
    saveAs(newFile, `${file.name.split('.xml')[0]}_modified_${new Date().getTime()}.xml`);
  }
}
