<div class="container-fluid header-container">
    <div class="header">
        <div [routerLink] = "['/declarations']">
            <h1 class="title"> <fa-icon class="back-icon"  [icon]="faChevronLeft"></fa-icon>
                @if(declaration) {
                    {{declaration.model.name}} - {{declaration.client.name}}
                }
            </h1>
        </div>
    </div>
</div>
<div class="container-fluid content-container">
    <app-log-table [configTable]="configLogTable"></app-log-table>
    <div class="pagination-container">
        <app-pagination
            [currentPage]="currentPage" 
            [totalPages]= "maxPages"
            (pageChange)="currentPage = $event; getData()">
        </app-pagination>
    </div>
</div>