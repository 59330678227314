import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown, faCircle, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { Router } from '@angular/router';
import { PaginationComponent } from '../pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { faClockRotateLeft, faFilePlus } from '@fortawesome/pro-light-svg-icons';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { DeclarationsService } from '../../core/services/declarations/declarations.service';
import { RelatedDeclarationModalComponent } from '../related-declaration-modal/related-declaration-modal.component';
import { PresentationTypeEnum } from '../../core/enums/presentation-type.enum';
import { TypeDeclarationEnum } from '../../core/enums/type-declaration.enum';


@Component({
  selector: 'app-table',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, PaginationComponent, FormsModule, CheckboxModule, RelatedDeclarationModalComponent],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent {

  @Input() configTable: any;
  @Output() declarationChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() createAsociedDeclaration: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('newDecModal') newDecModal: RelatedDeclarationModalComponent;

  faCircleCheck = faCircleCheck;
  faCircle = faCircle;
  faChevronDown = faChevronDown;
  faFilePlus = faFilePlus;
  faClockRotateLeft = faClockRotateLeft;

  selectedHash: any = undefined;
  showRelatedDecModal: boolean = false;

  selectedId: any = undefined;
  constructor(
    private router: Router,
    private declarationsService: DeclarationsService,
  ) { }

  PresentationTypeEnum = PresentationTypeEnum;

  TypeDeclarationEnum = TypeDeclarationEnum;
  declarationTypes = Object.keys(this.TypeDeclarationEnum).map(type => {
    return { label: this.TypeDeclarationEnum[type], value: type };
  });

  async ngOnInit() {
    if (this.configTable && this.configTable.data) {
      this.configTable.data.declarations.forEach(item => {
        item.isExpanded = false;
      });
    }
  }

  goToDetail(id: string, block?: boolean) {
    this.router.navigate(['/declarations/detail', id],  { state: { block: block } });
  }

  goToLog(id: string) {
    this.router.navigate(['/declarations/log', id]);
  }

  onCheckboxChange(declaration: any) {
    if (declaration.isChecked) {
      this.selectedHash = declaration.hash;
    } else {
      this.selectedHash = undefined;
    }
    this.declarationChange.emit(declaration);
  }

  shouldDisableCheckbox(item: any) {
    if (!this.selectedHash) {
      return false;
    } else {
      return item.hash !== this.selectedHash;
    }
  }

  createRelatedDeclaration(event) {
    const type = this.declarationTypes.find(type => type.label === event).value;
    this.createAsociedDeclaration.emit([this.selectedId, type]);
    this.showRelatedDecModal = false;
  }

  openRelatedDecModal(id: any) {
    this.selectedId = id;
    this.showRelatedDecModal = true;
  }

  closeRelatedDecModal() {
    this.showRelatedDecModal = false;
  }


}
