import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { HOME_BOXES } from '../../constants/general.constants';
import { faHome } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, RouterModule]
})
export class SidebarComponent {
  @Input() isMenuOpen: boolean = false;

  itemsMenu: any = [];

  constructor() {
    this.itemsMenu = [
      {
        boxes: [{
          name: 'Inicio',
          routerLink: '/home',
          icon: faHome }]
      },
      {
        boxes: HOME_BOXES
      }];
  }

  isActiveLink(item: any) {
    return window.location.pathname.includes(item.routerLink);
  }
}
