import { faFileCirclePlus, faFileXml, faFolderOpen, faUserMagnifyingGlass, faUsers, faUsersMedical } from "@fortawesome/pro-duotone-svg-icons";

export const HOME_BOXES: any = [
     {
      name: 'Gestor Campañas',
      routerLink: '/declarations',
      icon: faFolderOpen
    },
    {   
      name: 'Maestro Clientes',
      routerLink: '/clients',
      icon: faUsersMedical
    },
    // {
    //   name: 'Visualización Clientes',
    //   routerLink: '/clients-master',
    //   icon: faUserMagnifyingGlass
    // },
    // {
    //   name: 'Modificar archivo XML',
    //   routerLink: '/xml-transform',
    //   icon: faFileXml
    // }
    // {
    //   name: 'Añadir modelo',
    //   routerLink: '/new-model',
    //   icon: faFileCirclePlus
    // }
  ];