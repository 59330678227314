import { Routes } from '@angular/router';
import { DeclarationsComponent } from './modules/campaigns/declarations/declarations.component';
import { DeclarationDetailComponent } from './modules/campaigns/declaration-detail/declaration-detail.component';
import { LoginComponent } from './modules/login/login.component';
import { MultipleGestionComponent } from './modules/campaigns/multiple-gestion/multiple-gestion.component';
import { DeclarationLogComponent } from './modules/campaigns/declaration-log/declaration-log.component';
import { isLogged } from './core/guards/auth.guard';
import { HomeComponent } from './modules/home/home.component';
import { ClientsListComponent } from './modules/clients/clients-list/clients-list.component';
import { ClientDetailComponent } from './modules/clients/client-detail/client-detail.component';
import { ModelDetailComponent } from './modules/models/model-detail/model-detail.component';
import { ClientsMasterComponent } from './modules/clients/clients-master/clients-master.component';
import { XmlTransformComponent } from './modules/xml-transform/xml-transform.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'home',
        canActivate: [isLogged],
        component: HomeComponent,
    },
    {
        path: 'declarations',
        canActivate: [isLogged],
        component: DeclarationsComponent,
    },
    {
        path: 'declarations/detail/:id',
        canActivate: [isLogged],
        component: DeclarationDetailComponent,
    },
    {
        path: 'declarations/multiple-gestion',
        canActivate: [isLogged],
        component: MultipleGestionComponent,
    },
    {
        path: 'declarations/log/:id',
        canActivate: [isLogged],
        component: DeclarationLogComponent,
    },
    {
        path: 'new-model',
        canActivate: [isLogged],
        component: ModelDetailComponent,
    },
    {
        path: 'clients',
        canActivate: [isLogged],
        component: ClientsListComponent,
    },
    {
        path: 'clients/detail/:id',
        canActivate: [isLogged],
        component: ClientDetailComponent,
    },
    {
        path: 'clients-master',
        canActivate: [isLogged],
        component: ClientsMasterComponent,
    },
    {
        path: 'xml-transform',
        canActivate: [isLogged],
        component: XmlTransformComponent,
    },
    {
        path: '**',
        redirectTo: 'home',
        pathMatch: 'full',
    },
];