<div [formGroup]="activitiesForm">
    <div class="form-title-container">
        <h3 class="subtitle">Actividades</h3>
    </div>
    <p-accordion [multiple]="true" formArrayName="activities">
        @for (activity of activities.controls; let i = $index; track activity) {
            <p-accordionTab header="{{activities.at(i).value.acronym ? activities.at(i).value.acronym : 'Nueva actividad' }}" [formGroupName]="i">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <p-checkbox formControlName="principal" binary="true" inputId="principal" [readonly]="true" />
                        <label for="principal">Actividad principal</label>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de alta</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDate" [readonlyInput]="true" showOnFocus="false"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Siglas</span>
                        <input class="search-input" type="text" pInputText formControlName="acronym" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Vía pública</span>
                        <input class="search-input" type="text"  pInputText formControlName="street" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Número de vía</span>
                        <input class="search-input" type="text" pInputText formControlName="number" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Escalera</span>
                        <input class="search-input" type="text" pInputText formControlName="stairs" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Piso</span>
                        <input class="search-input" type="text" pInputText formControlName="floor" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Puerta</span>
                        <input class="search-input" type="text" pInputText formControlName="door" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Municipio</span>
                        <input class="search-input" type="text" pInputText formControlName="town" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">CP</span>
                        <input class="search-input" type="text" pInputText formControlName="postalCode" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Provincia</span>
                        <input class="search-input" type="text" pInputText formControlName="province" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código país</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCountry" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">País</span>
                        <input class="search-input" type="text" pInputText formControlName="country" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Referencia catastral</span>
                        <input class="search-input" type="text" pInputText formControlName="cadastralReference" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código CNAE</span>
                        <input class="search-input" type="text" pInputText formControlName="codeCNAE" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Código IAE</span>
                        <input class="search-input" type="text" pInputText formControlName="codeIAE" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Tipo de actividad</span>
                        <p-dropdown [options]=" clientConstants.ACTIVITY_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actividad" formControlName="type" [readonly]="true"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Centro de trabajo</span>
                        <p-dropdown [options]="" optionLabel="label" placeholder="Seleccionar centro de trabajo" formControlName="workCenter" [readonly]="true"/>
                    </div>
                    <div class="column is-12">
                        <h4>Parámetros IAE</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="exemptionIAE" binary="true" inputId="exemptionIAE" [readonly]="true" />
                                <label for="exemptionIAE">Exención IAE</label>
                            </div>
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="startUpExemption" binary="true" inputId="startUpExemption" [readonly]="true"/>
                                <label for="startUpExemption">Exención por inicio</label>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha inicio exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startUpExemptionStartDate" [readonlyInput]="true" showOnFocus="false"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha baja exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startUpExemptionEndDate" [readonlyInput]="true" showOnFocus="false"/>
                            </div>
                            <div class="column is-6 form-container">
                                <p-checkbox formControlName="turnoverExemption" binary="true" inputId="turnoverExemption" [readonly]="true"/>
                                <label for="turnoverExemption">Exención por cifra negocios</label>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha inicio exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="turnoverExemptionStartDate" [readonlyInput]="true" showOnFocus="false" />
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Fecha baja exención</span>
                                <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="turnoverExemptionEndDate" [readonlyInput]="true" showOnFocus="false"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Clase cuota</span>
                                <p-dropdown [options]="clientConstants.QUOTE_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de actividad" formControlName="quoteType" [readonly]="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <h4>Elementos</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <span class="label">Nº Obreros</span>
                                <input class="search-input" type="text" pInputText formControlName="workers" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Potencia (KW)</span>
                                <input class="search-input" type="text" pInputText formControlName="power" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Nº Vehículos</span>
                                <input class="search-input" type="text" pInputText formControlName="vehicles" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Nº Máquinas</span>
                                <input class="search-input" type="text" pInputText formControlName="machines" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Tipo de maquinaria</span>
                                <input class="search-input" type="text" pInputText formControlName="machinesType" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">Escaparates</span>
                                <input class="search-input" type="text" pInputText formControlName="showCases" [readonly]="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <h4>Superfície</h4>
                        <div class="columns is-multiline">
                            <div class="column is-6 form-container">
                                <span class="label">M2 totales construidos</span>
                                <input class="search-input" type="text" pInputText formControlName="totalBuilt" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 totales útiles</span>
                                <input class="search-input" type="text" pInputText formControlName="totalUseful" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 sin reducción</span>
                                <input class="search-input" type="text" pInputText formControlName="m2WithoutReduction" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 descubiertos</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Uncovered" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 enseñanza</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Teaching" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 almacén</span>
                                <input class="search-input" type="text" pInputText formControlName="m2Store" [readonly]="true"/>
                            </div>
                            <div class="column is-6 form-container">
                                <span class="label">M2 aparcamiento cubierto</span>
                                <input class="search-input" type="text" pInputText formControlName="m2CoveredParking" [readonly]="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6 form-container">
                            <span class="label">Fecha variación actividad</span>
                            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="activityVariationDate" [readonlyInput]="true" showOnFocus="false"/>
                        </div>
                        <div class="column is-6 form-container">
                            <span class="label">Motivo variación </span>
                            <input class="search-input" type="text" pInputText formControlName="activityVariationReason" [readonly]="true"/>
                        </div>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Fecha de baja</span>
                        <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="endDate" [readonlyInput]="true" showOnFocus="false"/>
                    </div>
                </div>
            </p-accordionTab>
        }@empty {
            <span>No hay actividades</span>
        }
    </p-accordion>
</div>
<div [formGroup]="societyForm" [ngStyle]="{'margin-top':'40px', 'margin-bottom':'10px'}">
    <div class="columns is-multiline">
        <div class="column is-6 form-container">
            <p-checkbox formControlName="isBigCompany" binary="true" inputId="isBigCompany" [readonly]="true"/>
            <label for="isBigCompany">Gran empresa</label>
        </div>
        
        <div class="column is-6 form-container">
            <span class="label">Fecha alta obligación IS</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="startDateISOObligation" [readonlyInput]="true"/>
        </div>
        
        <div class="column is-6 form-container">
            <span class="label">Opción pago fraccionado</span>
            <p-dropdown [options]=" clientConstants.FRACTIONATED_PAYMENT_TYPES" optionLabel="label" optionValue="value" placeholder="Seleccionar tipo de cliente" formControlName="fractionatedPayment" [readonly]="true"/>
        </div>
    </div>
</div>
<div [formGroup]="otherForm">

    <div class="columns is-multiline ">
        <div class="column is-6 form-container">
            <span class="label">Nombre administración AEAT</span>
            <input class="search-input" type="text" pInputText formControlName="administrationNameAEAT" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Código administración AEAT</span>
            <input class="search-input" type="text" pInputText formControlName="administrationCodeAEAT" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Delegación AEAT</span>
            <input class="search-input" type="text" pInputText formControlName="delegationAEAT" [readonly]="true"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="intraCommunityOperatorObligation" binary="true" inputId="intraCommunityOperatorObligation" [readonly]="true"/>
            <label for="intraCommunityOperatorObligation">Operador intracomunitario</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha alta ROI</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="intraCommunityOperatorStartDate" [readonlyInput]="true" showOnFocus="false"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha baja ROI</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="intraCommunityOperatorEndDate" [readonlyInput]="true" showOnFocus="false"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="presentationSIIObligation" binary="true" inputId="presentationSIIObligation" [readonly]="true"/>
            <label for="presentationSIIObligation">Obligación presentación SII</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha alta SII</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="presentationSIIStartDate" [readonlyInput]="true" showOnFocus="false"/>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha baja SII</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="presentationSIIEndDate" [readonlyInput]="true" showOnFocus="false"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="codeLEI" binary="true" inputId="codeLEI" [readonly]="true"/>
            <label for="codeLEI">Código LEI</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha caducidad LEI</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="caducityDateLEI" [readonlyInput]="true" showOnFocus="false"/>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="codeEORI" binary="true" inputId="codeEORI" [readonly]="true"/>
            <label for="codeEORI">Código EORI</label>
        </div>
        <div class="column is-6 form-container">
            <p-checkbox formControlName="digitalCertificate" binary="true" inputId="digitalCertificate" [readonly]="true"/>
            <label for="digitalCertificate">Certificado digital</label>
        </div>
        <div class="column is-6 form-container">
            <span class="label">Fecha caducidad Certificado Digital</span>
            <p-calendar [iconDisplay]="'input'" dateFormat="dd/mm/yy" dataType="date" [showIcon]="true" inputId="icondisplay" formControlName="digitalCertificateCaducityDate" [readonlyInput]="true" showOnFocus="false"/>
        </div>
    </div>
</div>
