import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { faArrowRightFromBracket, faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MenuModule } from 'primeng/menu';
import { CommonModule } from '@angular/common';
import { DEPARTMENTS_TYPES } from '../../constants/client.constants';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [FontAwesomeModule, MenuModule, CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
[x: string]: any;
  @Output() menuToggled = new EventEmitter<boolean>();

  faArrowRightFromBracket = faArrowRightFromBracket;
  headerTitle = '';
  items = [
    {label: 'Salir', command: () => this.logout()}
  ];
  isMenuOpen = false;
  faBars = faBars;
  departmentsTypes = DEPARTMENTS_TYPES;

  department: any;
  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateHeader(event.urlAfterRedirects);
      }
    });
  }

  updateHeader(url: string) {
    if (url.includes('/declarations')) {
      this.headerTitle = 'Gestor de campañas: Declaraciones';
    } else if (url.includes('/declarations/detail/')) {
      this.headerTitle = 'Gestor de campañas: Campañas';
    } else {
      this.headerTitle = '';
    }
    const userDepartment = this.authService.getUser()?.user.department;
    this.department = this.departmentsTypes.find(dep => dep.value === userDepartment).label;
  }


  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuToggled.emit(this.isMenuOpen);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
