import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsService } from '../../../core/services/clients/clients.service';


@Component({
  selector: 'app-invoicing-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, InputTextModule, CheckboxModule, CalendarModule, AccordionModule],
  templateUrl: './invoicing-form.component.html',
  styleUrl: './invoicing-form.component.scss'
})
export class InvoicingFormComponent {

  public clientConstants = ClientConstants;

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  invoicingForm = this.fb.group({
    paymentType: ['', ],
    paymentDays: [],
    invoicingStartDate: [],
    expiration: [''],
    billFormat: [''],
    exemptionIVA: [''],
    exemptionTypeIVA: [''],
    includeReceipts: [''],
    iban1: [''],
    bank1: [''],
    iban2: [''],
    bank2: [''],
    sepa: [''],
    totalImport: [''],
    periodicity: [''],
    nextEmissionDate: [],
    expedient: [''],
    emittingCompany: [''],
    receiptFormat: [''],
    remittanceDomiciliation: [''],
    accountingSeries: [''],
    independentBilling: [''],
    feeProposalAccepted: [''],
    acceptationDate: [],
    total: [''],
    fiscal: [''],
    accounting: [''],
    labor: [''],
    laborBase: [''],
    laborWorker: [''],
    laborProfessional: [''],
    notifications: [''],
    irpf: [''],
    societary: [''],
    laborContractsAndAnnexes: [''],
    socialSecurity: [''],
    laborRequirements: [''],
    laborResources: [''],
    fiscalRequirements: [''],
    fiscalResources: [''],
    sacichDomiciliation: [''],
    anualAccounts: [''],
    id: [],
    client: []
  });

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService
    ) {
      this.invoicingForm.valueChanges.subscribe(() => {
        if (this.invoicingForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
     }
    
  async ngOnInit() {
    if (this.clientId) {
      let data = await this.clientsService.getClientInvoicing(this.clientId);
      if(data) {
          data.paymentDays = data.paymentDays ? new Date(data.paymentDays) : null;       
          data.invoicingStartDate = data.invoicingStartDate ? new Date(data.invoicingStartDate) : null;        
          data.expiration = data.expiration ? new Date(data.expiration) : null;       
          data.nextEmissionDate = data.nextEmissionDate ? new Date(data.nextEmissionDate) : null;        
          data.acceptationDate = data.acceptationDate ? new Date(data.acceptationDate) : null;
        this.invoicingForm.patchValue(data);
      }
     
    }
  }

  async save() {
    this.invoicingForm.value.client = Number(this.clientId);
    if (!this.invoicingForm.value.id) {
      delete this.invoicingForm.value['id'];
    }
    await this.clientsService.saveClientInvoicing(this.invoicingForm.value);
  }
}
