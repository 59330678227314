<div class="container-fluid title">
    <div class="columns is-multiline">
        <div class="column is-9">
            <h1 class="title">Nuevo Modelo</h1>
        </div>

    </div>
</div>
<div class="container-fluid content-page background">
    <h2>Modelo</h2>
    <form [formGroup]="modelForm">
        <div class="model-name-container columns is-multiline">
            <div class="column is-4">
                <label for="name">Nombre </label><br>
                <input class="text-input" pInputText id="name" type="text" formControlName="department">
            </div>
            <div class="column is-4">
                <label for="department">Departamento </label><br>
                <input class="text-input" pInputText id="department" type="text" formControlName="department">
            </div>
            <div class="column is-4">
                <label for="periodicity">Periodicidad </label><br>
                <p-dropdown id="periodicity" formControlName="periodicity" [options]="periodicities"></p-dropdown>            
            </div>
            <div class="column is-4">
                <label for="startMonth">Mes de inicio </label><br>
                <p-dropdown id="startMonth" formControlName="startMonth" [options]="months"></p-dropdown>            
            </div>
            <div class="column is-4">
                <label for="isDaysTime">Tiempo en días </label><br>
                <p-dropdown id="isDaysTime" formControlName="isDaysTime" [options]="booleanOptions"></p-dropdown>            
            </div>
            <div class="column is-4">
                <label for="numberOfTime">Período de tiempo </label><br>
                <input class="text-input" pInputText id="numberOfTime" type="text" formControlName="numberOfTime">
            </div>
            <div class="column is-4">
                <label for="availablePresentations">Presentaciones disponibles</label><br>
                <p-multiSelect id="availablePresentations" formControlName="availablePresentations" [options]="presentations"></p-multiSelect>
            </div>
        </div>
        <div style="display: flex; align-items: center;">

            @if(phases.length === 0) {
                <h3 class="phases-title">Fases <fa-icon (click)="addPhase()" class="add-icon" [icon]="faPlus"></fa-icon></h3>
            }@else {
                <h3 class="phases-title">Fases</h3>

            }
            <!-- <h3 class="phases-title">Fases</h3>
            @if(phases.length === 0) {
                <button class="button add-button" (click)="addPhase()"><fa-icon class="add-icon" [icon]="faPlus"></fa-icon></button>
            } -->
        </div>
        <div formArrayName="phases" class="phases-container" >
            @for (phase of phases.controls; let i = $index; track phase) {
                <div  [formGroupName]="i" class="phase">
                    @if (phase) {
                        <div style="display: flex; align-items: center;">
                            <h4>Fase {{i + 1}} <fa-icon class="delete-phase" (click)="removePhase(i)" [icon]="faTrash"></fa-icon></h4>
                        </div>
                        <hr>
                        <div style="display: flex; align-items: center;">
                            @if(getTasks(i).length === 0) {
                                <h4 class="tasks-title">Tareas <fa-icon (click)="addTask(i)" class="add-icon" [icon]="faPlus"></fa-icon></h4>
                            }@else {
                                <h4 class="tasks-title">Tareas</h4>
                            }
                        </div>
                        <div formArrayName="tasks" class="tasks columns is-multiline">
                            @for (task of getTasks(i).controls; let j = $index; track task) {
                                @if (task) {
                                    <div class="task column is-4">
                                        <div [formGroupName]="j" class="card">
                                            <button class="delete-button" (click)="removeTask(i,j)">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                            <div>
                                                <label for="task-name">Nombre de la tarea </label><br>
                                                <input class="text-input" pInputText id="task-name" type="text" formControlName="name">
                                            </div>
                                            <div>
                                                <label for="task-type">Tipo de tarea</label>
                                                <p-dropdown id="task-type" formControlName="type" [options]="taskTypes"></p-dropdown>
                                            </div>

                                            <!-- @if(getTaskType(i,j) === 'OPTIONS') {
                                                <label for="task-options">Añadir opciones</label>

                                                <div>
                                                    <button class="button" (click)="addOption(i,j)"><fa-icon class="add-icon" [icon]="faPlus"></fa-icon></button>
                                                </div>

                                                    <div>
                                                        <input class="text-input" pInputText id="option" type="text" formControlName="option">
                                                    </div>
                                            } -->
                                            <div>
                                                <label for="task-disabled">Desactivar en estos tipo de presentación</label>
                                                <p-multiSelect id="task-disabled"
                                                formControlName="isDisabledByTypePresentation" 
                                                [options]="presentations" ></p-multiSelect>
                                            </div>
                                        </div>
                                    </div>
                                    
                                }
                            }
                            @if (getTasks(i).length > 0) {
                                <div class="task buttons column is-4">
                                    <div>
                                        <button class="button add-button" (click)="addTask(i)"><fa-icon class="add-icon" [icon]="faPlus"></fa-icon></button>
                                    </div>
                                </div>                            
                            }
                            
                        </div>

                    }
                </div>
            }
            <hr>
            @if (phases.length > 0) {
                <button class="button add-button" (click)="addPhase()">Nueva Fase</button>
                <!-- <button class="button" (click)="removeLastPhase()">Borrar Fase</button> -->
            }
        </div>
        <button class="button submit-button" type="submit" (click)="submitModel()">Submit</button>
    </form>
</div>
