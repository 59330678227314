<div [formGroup]="contactsForm">
    <div class="form-title-container">
        <h3>Datos de contacto</h3>
        <a class="button add-button" (click)="addContactPerson()">Añadir persona</a>
    </div>
    <p-accordion [multiple]="true" formArrayName="contacts">
        @for (contact of contacts.controls; let i = $index; track contact) {
            <p-accordionTab header="{{contacts.at(i).value.name ? contacts.at(i).value.name : 'Nuevo contacto' }}" [formGroupName]="i" class="">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <span class="label">Nombre</span>
                        <input class="search-input" type="text" pInputText formControlName="name"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">DNI</span>
                        <input class="search-input" type="text" pInputText formControlName="dni"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Cargo en la empresa</span>
                        <input class="search-input" type="text" pInputText formControlName="position"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Departamento</span>
                        <input class="search-input" type="text" pInputText formControlName="department"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Mail 1</span>
                        <input class="search-input" type="text" pInputText formControlName="mail1"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Mail 2</span>
                        <input class="search-input" type="text" pInputText formControlName="mail2"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Idioma de contacto</span>
                        <input class="search-input" type="text" pInputText formControlName="language"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Teléfono principal</span>
                        <input class="search-input" type="text" pInputText formControlName="phone"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Teléfono móvil</span>
                        <input class="search-input" type="text" pInputText formControlName="mobilePhone"/>
                    </div>
                    <div class="column is-6 form-container">
                        <p-checkbox formControlName="accessUser" binary="true" inputId="accessUser" />
                        <label for="accessUser">Usuario con acceso</label>
                    </div>
                </div>

            </p-accordionTab>
        }
    </p-accordion>
    <!-- <div class="form-title-container">
        <h3>Usuarios con acceso a documentación empresa</h3>
        <a class="button add-button" (click)="addUserWithAccess()">Añadir usuario</a>
    </div>
    <p-accordion [multiple]="true" formArrayName="usersWithAccess">
        @for (user of usersWithAccess.controls; let i = $index; track user) {
            <p-accordionTab header="Usuario {{i + 1}}" [formGroupName]="i" class="">
                <div class="columns is-multiline">
                    <div class="column is-6 form-container">
                        <span class="label">Nombre usuario</span>
                        <input class="search-input" type="text" pInputText formControlName="username"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">Contraseña usuario</span>
                        <input class="search-input" type="text" pInputText formControlName="password"/>
                    </div>
                    <div class="column is-6 form-container">
                        <span class="label">E-mail usuario</span>
                        <input class="search-input" type="text" pInputText formControlName="email"/>
                    </div>
                    <div class="column is-12" formGroupName="accessTo">
                        <h4>Acceso a</h4>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="laborTaxes" binary="true" inputId="laborTaxes" />
                            <label for="laborTaxes">Impuestos laboral</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="fiscalTaxes" binary="true" inputId="fiscalTaxes" />
                            <label for="fiscalTaxes">Impuestos fiscal</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="bills" binary="true" inputId="bills" />
                            <label for="bills">Facturas</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="contracts" binary="true" inputId="contracts" />
                            <label for="contracts">Contratos</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="payrolls" binary="true" inputId="payrolls" />
                            <label for="payrolls">Nóminas</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="socialInsurances" binary="true" inputId="socialInsurances" />
                            <label for="socialInsurances">Seguros sociales</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="laborCertificates" binary="true" inputId="laborCertificates" />
                            <label for="laborCertificates">Certificados laborales</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="retentionCertificates" binary="true" inputId="retentionCertificates" />
                            <label for="retentionCertificates">Certificados de retención</label>
                        </div>
                    </div>
                    <div class="column is-12" formGroupName="newsletters">
                        <h4>Newsletters</h4>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="generals" binary="true" inputId="generals" />
                            <label for="generals">Generales</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="clientsQuotes" binary="true" inputId="clientsQuotes" />
                            <label for="clientsQuotes">Cuota clientes</label>
                        </div>
                        <div class="column is-6 form-container">
                            <p-checkbox formControlName="clientsRent" binary="true" inputId="clientsRent" />
                            <label for="clientsRent">Renta clientes</label>
                        </div>

                    </div>

                </div>

            </p-accordionTab>
        }
    </p-accordion> -->
</div>

