import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import * as ClientConstants from '../../../core/constants/client.constants';
import { ClientsService } from '../../../core/services/clients/clients.service';

@Component({
  selector: 'app-activities-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule, InputTextModule, DropdownModule, AccordionModule, CheckboxModule],
  templateUrl: './activities-form.component.html',
  styleUrl: './activities-form.component.scss'
})
export class ActivitiesFormComponent {

  public clientConstants = ClientConstants;

  activitiesForm = this.fb.group({
    activities: this.fb.array([])
  });

  @Input() clientId: any;
  @Output() formIsDirty = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService
    ) { 
      this.activitiesForm.valueChanges.subscribe(() => {
        if (this.activitiesForm.dirty) {
          this.formIsDirty.emit(true);
        }
      });
    }

    async ngOnInit() {
      await this.getData();
    }

    async getData() {
      if (this.clientId) {
        let data = await this.clientsService.getClientActivities(this.clientId);
        let activitiesArray = this.activitiesForm.get('activities') as FormArray;
        data.forEach(activity => {
          activity.startDate = activity.startDate ? new Date(activity.startDate) : null;
          activity.endDate = activity.endDate ? new Date(activity.endDate) : null;
          activity.startUpExemptionStartDate = activity.startUpExemptionStartDate ? new Date(activity.startUpExemptionStartDate) : null;
          activity.startUpExemptionEndDate = activity.startUpExemptionEndDate ? new Date(activity.startUpExemptionEndDate) : null;
          activity.turnoverExemptionStartDate = activity.turnoverExemptionStartDate ? new Date(activity.turnoverExemptionStartDate) : null;
          activity.turnoverExemptionEndDate = activity.turnoverExemptionEndDate ? new Date(activity.turnoverExemptionEndDate) : null;
          activity.activityVariationDate = activity.activityVariationDate ? new Date(activity.activityVariationDate) : null;
          activitiesArray.push(this.fb.group(activity));
        });
      }
    }

  get activities(): FormArray {
    return this.activitiesForm.get('activities') as FormArray;
  }

  addActivity() {
    const activity = this.fb.group({
      principal: [''],
      startDate: [, Validators.required],
      acronym: [''],
      street: [''],
      number: [],
      stairs: [''],
      floor: [],
      door: [],
      town: [''],
      postalCode: [],
      province: [''],
      codeCountry: [''],
      country: [''],
      cadastralReference: [''],
      codeCNAE: [''],
      codeIAE: [''],
      type: [''],
      workCenter: [''],
      exemptionIAE: [''],
      startUpExemption: [''],
      startUpExemptionStartDate: [],
      startUpExemptionEndDate: [],
      turnoverExemption: [''],
      turnoverExemptionStartDate: [],
      turnoverExemptionEndDate: [],
      quoteType: [''],
      workers: [],
      power: [],
      vehicles: [],
      machines: [],
      machinesType: [''],
      showCases: [''],
      totalBuilt: [],
      totalUseful: [],
      m2WithoutReduction: [],
      m2Uncovered: [],
      m2Teaching: [],
      m2Store: [],
      m2CoveredParking: [],
      activityVariationDate: [],
      activityVariationReason: [''],
      endDate: [],
      id: [],
      client: [],
    });

    this.activities.push(activity);
  }

  async save() {
    for (let i = 0; i < this.activities.length; i++) {
      if (this.activities.at(i).dirty) {
        if(!this.activities.at(i).value.client) {
          this.activities.at(i).value.client = Number(this.clientId);
        }
        if (!this.activities.at(i).value.id) {
          delete this.activitiesForm.value['id'];
        }
        await this.clientsService.saveClientActivity(this.activities.at(i).value);
      }
    }
    this.activities.clear();
    await this.getData();
  }
}
